import React from 'react'
import PersonalDetails from './components/PersonalDetails';
import UploadOrgPhoto from './components/UploadOrgPhoto';
import SelectCountry from './components/SelectLocation';
import SelectStateCity from './components/SelectStateCity';
import PreferredCategory from './components/PreferredCategory';
import PreferredStages from './components/PreferredStages';
import InvestmentRange from './components/InvestmentRange';
import LinkedInProfileDetails from './components/ProfileURLDetails';
import WhatsAppNotifications from './components/WhatsAppNotifications';
import StartupInvestments from './components/StartupInvestments';
import OrganizationDetails from './components/OrganizationDetails';
import SelectLocation from './components/SelectLocation';
import PartnersDetail from './components/PartnersDetail';

const InvestorMicroVC = ({
    currentStep,
    setCurrentStep,
    investor_type,
    isLoading,
    isSectorAgnostic, setIsSectorAgnostic, isBusinessModelSelectAll, setIsBusinessModelSelectAll
    ,handleVerifyOTP,
    resend,
    refetchProfileDetails,
    setError,
    error
}) => {
    switch (currentStep) {
        case 2: return <PersonalDetails investor_type={investor_type} />;
        case 3: return <OrganizationDetails investor_type={investor_type} />;
        case 4: return <UploadOrgPhoto label={"Upload Logo"} />;
        case 5: return <></>;
        case 6: return <></>;
        case 7: return <PreferredCategory isLoading={isLoading} isSectorAgnostic={isSectorAgnostic}
            setIsSectorAgnostic={setIsSectorAgnostic}
            isBusinessModelSelectAll={isBusinessModelSelectAll}
            setIsBusinessModelSelectAll={setIsBusinessModelSelectAll} />;
        case 8: return <InvestmentRange isLoading={isLoading} />;
        case 9: return <PreferredStages />;
        case 10: return <StartupInvestments />;
        case 11: return <WhatsAppNotifications refetchProfileDetails={refetchProfileDetails} isLoading={isLoading} resend={resend} handleVerifyOTP={handleVerifyOTP} setError={setError} error={error} />;
        case 12: return <LinkedInProfileDetails />;
        case 13: return <PartnersDetail />;
    }
}

export default InvestorMicroVC