import React from 'react'
import AsyncTableListing from '../../ui-components/AsyncTableListing'
import BreadcrumbHeader from '../../layout/BreadcrumbsHeader'
import ThemeBGWrapper from '../../ui-components/ThemeBGWrapper'
import { useTheme } from '@emotion/react'
import { ReactComponent as RoundDetailsIcon } from "assets/rounds_icons/round_details_icon.svg"
import { Autocomplete, Box, Grid, IconButton, Menu, MenuItem, Stack, Tooltip, useMediaQuery } from '@mui/material'
import CustomTabs from '../../ui-components/CustomTabs'
import { TabContext } from '@mui/lab'
import { Add, CancelRounded, Description, DoneRounded, FiberNew, FiberNewRounded, FilterAltOutlined, FilterAltRounded, LanguageOutlined, LinkedIn, More, NewReleasesOutlined, Notes, PublishedWithChanges, RateReview, RocketLaunch, Search, ThumbDown, ThumbUp } from '@mui/icons-material'
import { makeStyles } from '@mui/styles'
import { amountInWords, getFileIcon } from '../../utils'
import { Link, useNavigate } from 'react-router-dom'
import CustomInputField from 'ui-components/CustomInputField'
import { B300, B400 } from 'ui-components/CustomButton'
import { ReactComponent as MoreOptionsIcon } from "assets/rounds_icons/more_solid_icon.svg";
import NeedHelpButton from 'ui-components/NeedHelpButton'
// import FilterPopup from "./popups/FilterPopup"
// import ModalComponent from 'ui-components/ModalComponent'
// import FeedbackPopup from './popups/FeedbackPopup'
// import ShowInterestPopup from './popups/ShowInterestPopup'
import ShortlistConfirmationPopup from "ui-components/popups/ConfirmationPopup"
import RejectedConfirmationPopup from "ui-components/popups/ConfirmationPopup"
import RequestDocumentsPopup from "ui-components/popups/ConfirmationPopup"
import Cookies from 'js-cookie'
import { FetchAllCategories, FetchAllStages, FetchAllSubCategories } from 'api/utils'
import { UpdateStartupStatus } from 'api/backrr_startups'
// import InviteStartupPopup from './popups/InviteStartupPopup'
import { useSelector } from 'react-redux'
import { FetchAllStartupsListing, FetchInterestFeedbackStatus, InviteStartupPOST, SaveFeedback, SaveInterest } from 'api/backrr_startups'
// import PDFView from './popups/PDFView'
import CustomToast from 'ui-components/CustomToast'
import AlertDialog from 'ui-components/popups/AlertPopup'
import toast from 'react-hot-toast'
import { ReactComponent as AnalyticsIcon } from '../../assets/nav_icons/rocket_1.svg';
import { FetchAllInvestorList } from 'api/backrr_investor'
const useStyles = makeStyles((theme) => ({
  scrollTabs: {
    "&": {
      padding: "3rem"
    }
    // '& .MuiTabs-root > .MuiTabs-scroller > .MuiTabs-flexContainer' : {marginLeft: '2rem', gap: '5rem'}
  }
  //     '& .MuiTabs-root > .Mui-disabled.MuiTabs-scrollButtons': {
  //         display: 'none !important',
  //         transition: 'all 1s ease'
  //     },
  // }
}));

const AllInvestor = ({setCount}) => {
    

  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state?.investorDetails?.data);



  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const startupTabs = [
    { value: "all", label: 'All', count: 10, read: false, icon: RocketLaunch },
    { value: "backrr", label: 'Backrr', count: 10, read: false, icon: ThumbUp },
    { value: "registered", label: 'Registered', count: 10, read: false, icon: ThumbDown },
    { value: "duplicate", label: 'Duplicate', count: 10, read: false, icon: ThumbDown },
  ]
  const [page, setPage] = React.useState(1);
//   const [limit, setLimit] = React.useState(10);
//   const [sort, setSort] = React.useState(-1);
//   const [count , setCount] =React.useState(0);


  const parsedCookieForQueryParams = Cookies.get("backrr_startups_query") ? JSON.parse(Cookies.get("backrr_startups_query")) : {}
  const [queryParams, setQueryParams] = React.useState(parsedCookieForQueryParams);
  const [stage, setStage] = React.useState(null);
  const [category, setCategory] = React.useState(null);
  const [sector, setSector] = React.useState(null);
  const parsedCookieForStatus = Cookies.get("backrr_startups_status") || "all"
  const [value, setValue] = React.useState(parsedCookieForStatus || "all")
  const [search, setSearch] = React.useState("" || Cookies.get("search_backrr_startups"));
  const [reset, setReset] = React.useState(false);
  const [searchStatus, setSearchStatus] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [allInvestorData, setAllInvestorData] = React.useState(null);


  const refetchListing = () => {

  }
  // const { data: transformedData, isRefetching: isRefetchingStartupListing, isLoading: isLoadingStartupListing, refetch: refetchListing } = FetchAllStartupsListing({ page, limit, status: value, queryParams, searchText: search })
  const { mutateAsync: fetchAllInvestor, isLoading: isLoadingfetchAllInvestor, status: fetchAllInvestorStatus, error: fetchAllInvestorError } = FetchAllInvestorList();

  const { data: startup_stages, isRefetching: isRefetchingAllStages, isLoading: isLoadingAllStages, refetch: refetchAllStages } = FetchAllStages();
  const { data: startup_categories, isRefetching: isRefetchingAllCategories, isLoading: isLoadingAllCategories, refetch: refetchAllCategories } = FetchAllCategories();
  const { data: startup_sub_categories, isRefetching: isRefetchingAllSubcategories, isLoading: isLoadingAllSubcategories, refetch: refetchAllSubcategories } = FetchAllSubCategories({ category: queryParams?.category });
  const {
    mutateAsync: FetchStatusAsync,
    status: fetchStatusStatus,
    error: fetchStatusError,
    isLoading: isUpdatingFetchStatus,
    data: fetchStatusResponse,
  } = FetchInterestFeedbackStatus();

  React.useEffect(() =>{
    let payload = {
        "select_stages": ["Early Stage"],
        "category": ["Services"],
        "sub_sector": ["3D Tech"]
    };
    fetchAllInvestor({}, {
        onSuccess: (res) => {
          setAllInvestorData(res.data.data)
          setCount(res?.data?.totalCount)
        },
        onError: () => {

        }
        
    })
   },[])
  const [isEnabledInterest, setIsEnabledInterest] = React.useState(false);
  const [isEnabledFeedback, setIsEnabledFeedback] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false)
  const [alertMessage, setAlertMessage] = React.useState(null)
  const [alertHeading, setAlertHeading] = React.useState(null)

  // console.log(transformedData?.data, "startupListingData")
  const handleTabChange = (event, newValue) => {
    // console.log("value", newValue)
    setValue(newValue);
    Cookies.set("backrr_startups_status", newValue);
    setPage(1);
  };
  const RowActions = ({ row, iconsColor = theme.palette.customColors.indigo700, index }) => {
    // return <>

    // </>
    // console.log(row, "row")

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event, index) => {
      setAnchorEl(event.currentTarget);
      // setCurrentIndex(index)
      // console.log(event.currentTarget,"re")
    };
    const handleCloseMenu = (event) => {
      // console.log(event,"event")
      setAnchorEl(null);
      // setCurrentIndex(null);
    };

    // switch (value) {
    //     case "new": return <>
    return <>
      <IconButton
        sx={{ borderRadius: "4px" }}
        id={`more-details-button-${index}`}
        size="small"
        aria-controls={openMenu ? 'more-details-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={(e) => handleClickMenu(e)}
        centerRipple={false}
      >
        <MoreOptionsIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        aria-controls={openMenu ? 'fade-menu' : 'fade-menu'}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : 'false'}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            padding: "12px",
            borderRadius: "8px",
            "& > ul.MuiList-root": { padding: "0 !important" },
            bgcolor: theme.palette.customColors.g400,
            color: theme.palette.customColors.whiteBG,
            // '&::before': {
            //     content: '""',
            //     display: 'block',
            //     position: 'absolute',
            //     top: 1,
            //     right: 5,
            //     width: 10,
            //     height: 10,
            //     bgcolor: theme.palette.customColors.g900,
            //     transform: 'translateY(-50%) rotate(45deg)',
            //     zIndex: 0,
            // },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
      >
        <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => {
          if (row?.original?._id) {
            Cookies.set("startup_id", row?.original?._id); navigate(`view`)
          }
        }}>
          View Details
        </MenuItem>
        {row?.original?.backrr_base_url && row?.original?.backrr_base_url != null && (String(row?.original?.backrr_base_url)?.length > 0) && <MenuItem disableRipple sx={{ borderBottom: `1px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => window.open(row?.original?.backrr_base_url)}>
          View Public Profile
        </MenuItem>}
      </Menu>
    </>
  }

  const AllColumns = [
    { Header: 'Investor Name', accessor: 'name', minWidth: 200, align: 'start', fixed: true },
    { Header: 'Type', accessor: 'investor_type', minWidth: 70, align: 'center' },
    { Header: 'Location', accessor: 'location', minWidth: 150, align: 'center' },
    { Header: 'Categories', accessor: 'focused_categories', minWidth: 200, align: 'start', tooltip: "focused_categories" },
    { Header: 'Sectors', accessor: 'focused_sectors', minWidth: 200, align: 'start', tooltip: "focused_sectors" },
    { Header: 'Business Models', accessor: 'focused_business_models', minWidth: 200, align: 'start', tooltip: "focused_business_models" },
    { Header: 'Cheque Size', accessor: 'cheque_size', minWidth: 150, align: 'center' },
    // { Header: 'Actions', accessor: 'actions', minWidth: 100 },
  ];


  // Memoized Tooltip Icon Component
  const MemoizedTooltipIcon = React.memo(({ title, icon, onClick }) => (
    <Tooltip title={title}>
      <IconButton onClick={onClick}>{icon}</IconButton>
    </Tooltip>
  ));

  // Memoized Data Transformation
  const useFormattedData = (transformedData) => {
    return React.useMemo(() => {
      return transformedData && transformedData?.length > 0
        ? transformedData?.map((item) => {
          // console.log(item)
            
            return ({
            ...item,
            
            name: `${item?.personal_information?.first_name || ""} ${item?.personal_information?.last_name || ""}`.trim(),
            location: item?.city?.name && item?.country?.name ? `${item?.city?.name}, ${item?.country?.name}` : "-",
            focused_categories: Array.isArray(item?.category) && item?.category.length > 0 ? item.category.map((cat) => cat.name).join(", ") : "-",

            // formatted_interested_amount: Array.isArray(item?.startup_rounds_info) && item?.startup_rounds_info?.length > 0 ? <Tooltip title={amountInWords(item?.startup_rounds_info[0]?.interested_amount, item?.currency_info ? item?.currency_info[0]?.code : null)}> {item?.startup_rounds_info[0]?.formatted_interested_amount} </Tooltip> : null,
            // location: item?.city && item?.country ? `${item?.city}, ${item?.country}` : "-",
            // category_sub_sector: item?.category_info && item?.sector_info
            //   ? `${item?.category_info?.name} ${item?.sector_info ? "(" + item?.sector_info[0]?.sub_category + ")" : "-"}`
            //   : "-",
            // stage: item?.stage_info ? `${item?.stage_info?.name}` : "-",
            // image: item?.startup_logo_path,
            // pitch_deck: item?.pitch_deck_path ? (
            //   <MemoizedTooltipIcon
            //     title="Pitch Deck"
            //     onClick={() => handleOpenPDFViewer({ row: item })}
            //     icon={<Description sx={{ color: theme.palette.customColors.indigo600 }} />}
            //   // icon={<img style={{ width: 20, height: 20 }} src={getFileIcon("pdf")} />}
            //   />
            // ) : (
            //   <>-</>
            // ),
            // links: (
            //   (
            //     (item?.website_url && item?.website_url?.length > 0 || item?.linkedin_url && item?.linkedin_url?.length > 0 || item?.startup_rounds_info && Array.isArray(item?.startup_rounds_info) && item?.startup_rounds_info?.length > 0 && item?.startup_rounds_info[0]?._id)
            //       ? <Box display="flex" alignItems={"center"} justifyContent={"center"} >
            //         {item?.website_url && item?.website_url?.length > 0 && <MemoizedTooltipIcon
            //           title="Website"
            //           onClick={() => window.open(item?.website_url)}
            //           icon={<LanguageOutlined sx={{ color: theme.palette.customColors.indigo600 }} />}
            //         />
            //         }
            //         {
            //           item?.linkedin_url && item?.linkedin_url?.length > 0 && <MemoizedTooltipIcon
            //             title="LinkedIn"
            //             onClick={() => window.open(item?.linkedin_url)}
            //             icon={<LinkedIn sx={{ color: theme.palette.customColors.indigo600 }} />}
            //           />
            //         }
            //         {
            //           item?.startup_rounds_info && Array.isArray(item?.startup_rounds_info) && item?.startup_rounds_info?.length > 0 && item?.startup_rounds_info[0]?._id && <MemoizedTooltipIcon
            //             title="View Round Details"
            //             onClick={() => {
            //               if (item?.startup_rounds_info && Array.isArray(item?.startup_rounds_info) && item?.startup_rounds_info?.length > 0 && item?.startup_rounds_info[0]?._id) {
            //                 Cookies.set("startup_id", item?._id);
            //                 navigate("view?page=round_details");
            //               }
            //             }}
            //             icon={<RoundDetailsIcon fill={theme.palette.customColors.indigo600} style={{ width: 20, height: 20 }} />}
            //           />
            //         }
            //       </Box> : '-')
            // )
          })
        })
        : [];
    }, [transformedData]);
  };

  let location = window.location.pathname?.includes("startups");

  const StartupLink = React.memo(({ row, children }) => {

    // console.log(row?.original?.startup_rounds_info,"row")
    return (
      <Stack onClick={() => {
        // console.log(row,"row")
        if (row?.original?._id) {
          Cookies.set("startup_id", row?.original?._id); navigate(`view`)
        }
      }} style={{ textDecoration: 'none', color: '#616161', fontWeight: 700, cursor: 'pointer' }}>
        {/* <Link to={`view/${row?._id}`} > */}
        {children}
      </Stack>
    )
  })

  const {
    mutateAsync: saveInterestAsync,
    status: saveInterestStatus,
    error: saveInterestError,
    isLoading: isSavingInterest,
    data: saveInterestResponse,
  } = SaveInterest();

  const {
    mutateAsync: saveFeedbackAsync,
    status: saveFeedbackStatus,
    error: saveFeedbackError,
    isLoading: isSavingFeedback,
    data: saveFeedbackResponse,
  } = SaveFeedback();

  const [currentRow, setCurrentRow] = React.useState(null);
  const [selectedPDF, setSelectedPDF] = React.useState('');

  const handleFilterOpen = () => {
    setOpenFilter(true);
  }

  const handleFilterClose = () => {
    setOpenFilter(false)
  }

  const handleUpdateFilter = ({ query = {} }) => {
    // console.log(query,"query")
    setQueryParams(query);
    Cookies.set("backrr_startups_query", JSON.stringify(query));
    handleFilterClose();
  }

  const [openFeedbackPopup, setOpenFeedbackPopup] = React.useState(false)
  const [openShowInterestPopup, setOpenShowInterestPopup] = React.useState(false)


  const handleFeedbackPopupOpen = ({ row }) => {
    let request = {
      startup_id: row?.original?.startup_id,
      investor_id: userDetails?._id
    }

    // console.log(request, "row")
    FetchStatusAsync(request).then((response) => {
      // console.log(response,"resssss")
      // setIsEnabledInterest(response?.data?.investorInfo == null)
      setIsEnabledFeedback(response?.data?.feedback == null)
      if (response?.data?.feedback !== null) {
        setOpenFeedbackPopup(false);
        setAlertMessage(`You have already submitted feedback for ${row?.original?.startup_name}. Please wait to hear back from the startup.`)
        setAlertHeading("Feedback")
        setShowAlert(true);
      }
      else {
        setOpenFeedbackPopup(true);
        setAlertMessage(null)
        setAlertHeading(null)
        setShowAlert(false);
      }
    }).catch(err => {
      toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
        duration: 4000,
        position: "top-right",
        style: {
          padding: "15px 30px",
        },
      });
    });


    setCurrentRow(row?.original);
  }

  const handleFeedbackPopupClose = () => {
    !Boolean(selectedPDF != null && selectedPDF?.length > 0) && setCurrentRow(null)
    setOpenFeedbackPopup(false)
  }

  const handleFeedbackSubmit = ({ row, values }) => {
    // console.log(row, "row")
    let request = {
      startup_id: row?.startup_id,
      investor_id: userDetails?._id,
      ratings: {
        problem: values?.problem,
        market: values?.market,
        product: values?.product,
        team: values?.team,
        traction: values?.traction
      },
      investible: values?.invest,
      feedback: values?.feedback
    }
    // console.log(request, "request")
    saveFeedbackAsync(request).then(() => {
      refetchListing()
    })
    // setQueryParams(query);
    handleFeedbackPopupClose();
  }


  const handleShowInterestPopupOpen = ({ row }) => {
    let request = {
      startup_id: row?.original?.startup_id,
      investor_id: userDetails?._id
    }

    // console.log(request, "row")
    FetchStatusAsync(request).then((response) => {
      // console.log(response,"resssss")
      setIsEnabledInterest(response?.data?.investorInfo == null)
      // setIsEnabledFeedback(response?.data?.feedback == null)
      if (response?.data?.investorInfo !== null) {
        setOpenShowInterestPopup(false);
        setAlertMessage(`You have already shown investment interest in ${row?.original?.startup_name}. Please wait to hear back from the startup.`)
        setAlertHeading("Investment Interest")
        setShowAlert(true);
      }
      else {
        setOpenShowInterestPopup(true);
        setAlertMessage(null)
        setAlertHeading(null)
        setShowAlert(false);
      }
    }).catch(err => {
      toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
        duration: 4000,
        position: "top-right",
        style: {
          padding: "15px 30px",
        },
      });
    });


    setCurrentRow(row?.original);
  }

  const handleShowInterestPopupClose = () => {
    !Boolean(selectedPDF != null && selectedPDF?.length > 0) && setCurrentRow(null);
    setOpenShowInterestPopup(false)
  }

  const handleShowInterestPopupSubmit = ({ row, values }) => {
    // console.log(row, "row")
    let request = {
      startup_id: row?.startup_id,
      intrested_amount: values?.interested_amount
    }
    // console.log(request, "request");
    saveInterestAsync(request).then(() => {
      refetchListing()
    })
    handleShowInterestPopupClose();
  }

  const {
    mutateAsync: updateStartupStatusAsync,
    status: updateStartupStatusStatus,
    error: updateStartupStatusError,
    isLoading: isUpdatingStartupStatus,
    data: updateStartupStatusResponse,
  } = UpdateStartupStatus({ investor_id: userDetails?._id });

  const {
    mutateAsync: inviteStartupAsync,
    status: inviteStartupStatus,
    error: inviteStartupError,
    isLoading: isInvitingStartup,
    data: inviteStartupResponse,
  } = InviteStartupPOST({ investor_id: userDetails?._id });

  const [openShortlistConfirmationPopup, setOpenShortlistConfirmationPopup] = React.useState(false);

  const handleShortlistConfirmationPopupClose = () => {
    setOpenShortlistConfirmationPopup(false);
    setCurrentRow(null);
  }

  const handleShortlistConfirmationPopupOpen = ({ row }) => {
    setOpenShortlistConfirmationPopup(true);
    setCurrentRow(row?.original);
  }

  const handleSubmissionShortlist = ({ row }) => {
    // setCurrentRow(row);
    // console.log(row);
    // updateStartupStatusAsync({
    //     "startup_id": "66f285de9ce0f2c5615f20e5",
    //     "status_code": "shortlisted"
    // }).then((response) => {
    //     refetchListing();
    //     handleShortlistConfirmationPopupClose();
    // }).catch((error) => {

    // })
    updateStartupStatusAsync({
      "startup_id": row?.startup_id,
      "status_code": "shortlisted"
    }).then((response) => {
      refetchListing();
      handleShortlistConfirmationPopupClose();
    }).catch((error) => {

    })

  }

  const [openRejectConfirmationPopup, setOpenRejectConfirmationPopup] = React.useState(false);

  const handleRejectConfirmationPopupClose = () => {
    setOpenRejectConfirmationPopup(false);
    setCurrentRow(null);
  }

  const handleRejectConfirmationPopupOpen = ({ row }) => {
    setOpenRejectConfirmationPopup(true);
    setCurrentRow(row?.original);
  }

  const handleSubmissionReject = ({ row }) => {
    // setCurrentRow(row);
    // console.log(row);
    // updateStartupStatusAsync({
    //     "startup_id": "66f285de9ce0f2c5615f20e5",
    //     "status_code": "rejected"
    // }).then((response) => {
    //     refetchListing();
    //     handleRejectConfirmationPopupClose();
    // }).catch((error) => {

    // })

    updateStartupStatusAsync({
      "startup_id": row?.startup_id,
      "status_code": "rejected"
    }).then((response) => {
      refetchListing();
      handleRejectConfirmationPopupClose();
    }).catch((error) => {

    })
  }

  const [openRequestDocumentsPopup, setOpenRequestDocumentsPopup] = React.useState(false);

  const handleRequestDocumentsPopupClose = () => {
    setOpenRequestDocumentsPopup(false);
    setCurrentRow(null);
  }

  const handleRequestDocumentsPopupOpen = ({ row }) => {
    setOpenRequestDocumentsPopup(true);
    setCurrentRow(row?.original);
  }

  const handleRequestDocuments = ({ row }) => {
    // setCurrentRow(row);
    // console.log(row);
    handleShortlistConfirmationPopupClose();
  }

  const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const [openInviteStartupPopup, setOpenInviteStartupPopup] = React.useState(false);

  const handleInviteStartupPopupClose = () => {
    setOpenInviteStartupPopup(false);
  }

  const handleInviteStartupPopupOpen = () => {
    setOpenInviteStartupPopup(true);
  }

  const handleInviteStartup = async ({ payload }) => {
    // console.log(payload, "Please enter startup name");
    await inviteStartupAsync({
      payload, _id: userDetails?._id
    }).then((response) => {
      refetchListing();
      handleInviteStartupPopupClose();
    }).catch((error) => {
      console.log(error?.message)
    })
  }

  const Actions = () => {
    return <>
      <B400 sx={{ display: 'flex', marginLeft: 'auto', background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem", "& > .MuiButton-icon.MuiButton-startIcon": { marginRight: isMediumScreen ? 0 : '0.5rem', marginLeft: isMediumScreen ? 0 : '-0.25rem' } }} startIcon={<Add sx={{ width: 20, height: 20 }} />} onClick={() => handleInviteStartupPopupOpen()}>{isMediumScreen ? "" : "Import"}</B400>
    </>
  }

  React.useEffect(() => {
    refetchListing();
  }, [reset]);

  const isShortlisting = false;
  const isRejecting = false;
  const isRequestingDocuments = false;
  const stagesListing = startup_stages && startup_stages?.length > 0 ? startup_stages?.map(item => ({ label: item?.name, value: item?.name })) : []
  const categoriesListing = startup_categories && startup_categories?.length > 0 ? startup_categories?.map(item => ({ label: item?.name, value: item?.name })) : []
  const subCategoriesListing = startup_sub_categories && startup_sub_categories?.length > 0 ? startup_sub_categories?.map(item => ({ label: item?.sub_category, value: item?.sub_category })) : []
  // console.log(queryParams, subCategoriesListing, "queryParams")
  const transformedData = {
    data: [
      {
        name: 'IIMA Ventures',
        image: 'https://iimaventures.com/wp-content/uploads/2024/01/IIMA-Logo.png',
        type: 'Incubator',
        location: 'Ahemdabad, Gujrat, India',
        focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
        focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
        focused_business_models: 'D2C (Direct-to-Consumer), C2B (Consumer-to-Business)',
        cheque_size: '$500 to $1000k USD',
        public_profile_url: 'https://backrr.in/investor/incubator'
      },
      {
        name: 'Kalaari',
        image: 'https://kalaari.com/wp-content/uploads/2021/08/Kalaari-Logo.png',
        type: 'VC',
        location: 'Bengaluru, Karnataka, India',
        focused_categories: 'Technology, Marketplace',
        focused_sectors: 'Information Technology, Cosmetics Industry',
        focused_business_models: 'D2C (Direct-to-Consumer), C2B (Consumer-to-Business)',
        cheque_size: '$10 to $250k USD',
        public_profile_url: 'https://backrr.in/investor/incubator'
      },
      {
        name: 'Fluid Ventures',
        image: 'https://fluidvc.in/wp-content/uploads/2022/01/fluidventures.png',
        type: 'Micro VC',
        location: 'Gurugram, Haryana, India',
        focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
        focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
        focused_business_models: 'C2B (Consumer-to-Business)',
        cheque_size: '$20 to $50k USD',
        public_profile_url: 'https://backrr.in/investor/incubator'
      },
      {
        name: 'Vaibhav Dixit',
        type: 'VC',
        location: 'Mubmai, Maharashtra, India',
        focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
        focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry, Mobile Accessories',
        focused_business_models: 'C2B (Consumer-to-Business)',
        cheque_size: '$100 to $250k USD'
      },
      {
        name: 'Rahul Goel',
        type: 'Accelerator',
        location: 'Ahemdabad, Gujrat, India',
        focused_categories: 'Technology, Consumer Brand',
        focused_sectors: 'Information Technology, Cosmetics Industry',
        focused_business_models: 'B2B (Business-to-Business)',
        cheque_size: '$50 to $200k USD'
      },
      {
        name: 'Ajay Sharma',
        type: 'Accelerator',
        location: 'Ahemdabad, Gujrat, India',
        focused_categories: 'Marketplace, Services',
        focused_sectors: 'Cosmetics Industry',
        focused_business_models: 'C2B (Consumer-to-Business)',
        cheque_size: '$150 to $200k USD'
      },
      {
        name: 'Pranay Mathur',
        image: 'https://backrr.in/static/media/pranay.0b5bd68b15473e8149a3.png',
        type: 'Angel',
        location: 'Jaipur, Rajasthan, India',
        focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
        focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
        focused_business_models: 'B2B (Business-to-Business)',
        cheque_size: '$100 to $600k USD',
        public_profile_url: 'https://backrr.in/investor/incubator'
      },
      {
        name: 'Pepper Tree',
        image: 'https://static.wixstatic.com/media/fff1db_cd63bc88f8504963bbbd440f9ddfa89a~mv2.png/v1/fill/w_450,h_60,al_c,q_85,usm_0.66_1.00_0.01,enc_avif,quality_auto/Untitled-3.png',
        type: 'Investment Banker',
        location: 'Mumbai, Maharashtra, India',
        focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
        focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
        focused_business_models: 'C2B (Consumer-to-Business)',
        cheque_size: '$50 to $200k USD',
        public_profile_url: 'https://backrr.in/investor/incubator'
      },
      {
        name: 'Vipul Shah',
        type: 'Accelerator',
        location: 'Surat, Gujrat, India',
        focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
        focused_sectors: 'Information Technology, Cosmetics Industry',
        focused_business_models: 'B2B (Business-to-Business)',
        cheque_size: '$20 to $100k USD'
      },
      {
        name: 'GSF',
        type: 'Incubator',
        location: 'Jaipur, Rajasthan, India',
        focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
        focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
        focused_business_models: 'C2B (Consumer-to-Business)',
        cheque_size: '$5 to $20k USD'
      },
      {
        name: 'NOTO',
        type: 'Micro VC',
        location: 'Nasik, Maharashtra, India',
        focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
        focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
        focused_business_models: 'B2B (Business-to-Business), C2B (Consumer-to-Business)',
        cheque_size: '$10 to $250k USD'
      },
      {
        name: 'Redwing',
        type: 'Investment Banker',
        location: 'Kolkata, West Bengal, India',
        focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
        focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
        focused_business_models: 'D2C (Direct-to-Consumer)',
        cheque_size: '$100 to $500k USD'
      },
      {
        name: 'Eske',
        type: 'Accelerator',
        location: 'Chennai, Tamil Nadu, India',
        focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
        focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
        focused_business_models: 'B2G (Business-to-Government)',
        cheque_size: '$20 to $300k USD'
      },
      {
        name: 'Bummer',
        type: 'VC',
        location: 'Ranchi, Jharkhand, India',
        focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
        focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
        focused_business_models: 'P2P (Peer-to-Peer), C2B (Consumer-to-Business)',
        cheque_size: '$50 to $100k USD'
      },
      {
        name: 'Koparo',
        type: 'Accelerator',
        location: 'Delhi, New Delhi, India',
        focused_categories: 'Technology, Consumer Brand, Marketplace, Services',
        focused_sectors: 'Information Technology, Digital Technology, Cosmetics Industry',
        focused_business_models: 'B2B (Business-to-Business)',
        cheque_size: '$10 to $200k USD'
      }
    ],
    limit: 10,
    count: 2356
  }

  const FormattedData = useFormattedData(allInvestorData);



  const handleOpenPDFViewer = ({ row }) => {
    setCurrentRow(row);
    // console.log(currentRow,"currentRow")
    setSelectedPDF(row?.pitch_deck_path);
  }

  const handleClosePDFViewer = () => {
    setSelectedPDF(null);
    setCurrentRow(null);
  }

  const updateSector = (params) => {
    let removedSectorParamFromQuery = params;
    removedSectorParamFromQuery.sector && delete removedSectorParamFromQuery.sector;
    // Cookies.set("backrr_startups_query", JSON.stringify({ ...removedSectorParamFromQuery }));
    setQueryParams({ ...removedSectorParamFromQuery });
    setSector(null);
    setSearchStatus(false)
  }


  return (
    <>
      {isLoadingfetchAllInvestor ?
                <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingLeft: "0rem !important", paddingTop: "0rem !important", bgColor: theme.palette.customColors.yellow_bg }} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
                  <Box className='skeleton' minHeight={"60vh"} sx={{ borderRadius: "0 0 8px 8px", border: `1px solid ${theme.palette.customColors.b50}` }} marginTop={0}>

                  </Box>
                </Grid> :
                <AsyncTableListing
                  columns={AllColumns}
                  tabValue={value}
                  data={FormattedData}
                  CustomLink={StartupLink}
                //   page={page}
                //   setPage={setPage}
                //   limit={limit}
                //   setLimit={setLimit}
                  count={FormattedData?.totalCount}
                  RowActions={RowActions}
                  sx={{ borderRadius: '0 0 8px 8px', border: `1px solid ${theme.palette.customColors.b50}` }}
                  refetchListing={refetchListing}
                />
              }
    </>
  )
}

export default AllInvestor