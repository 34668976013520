import { useTheme } from '@emotion/react';
import { Grid, Typography } from '@mui/material';
import { useConfig } from 'dynamicConfiguration/configContext';
import { useFormikContext } from 'formik';
import React from 'react'
import CustomLabel from 'ui-components/CustomLabel';

const StartupInvestments = () => {
  const { config } = useConfig();
  const formik = useFormikContext();
  const theme = useTheme();
  const number_investment = [
    {
      value: "01",
      label: "01",
    },
    {
      value: "02",
      label: "02",
    },
    {
      value: "03",
      label: "03",
    },
    {
      value: "04",
      label: "04",
    },
    {
      value: "05",
      label: "05",
    },
    {
      value: "06",
      label: "06",
    },
    {
      value: "07",
      label: "07",
    },
    {
      value: "08",
      label: "08",
    },
    {
      value: "09",
      label: "09",
    },
    {
      value: "10",
      label: "10",
    },
    {
      value: "11",
      label: "Invested in more than 10 startups",
    },
    {
      value: "0",
      label: config?.dynamicText?.newToInvestment,
    },

  ];
  return (
    <>
      <CustomLabel sx={{ marginTop: "0rem" }}>{config?.validationFields?.investment_count?.label} </CustomLabel>
      <Grid container xs={12} gap={0} marginTop={1} display={"flex"} alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"space-between"}>
        <Grid item container xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"row"}>
          <Grid
            display={"flex"}
            flexWrap={"wrap"}
            justifyContent={"space-between"}
            item
            container
            xs={12}
            gap={{ xs: 0.5, sm: 1, md: 2 }}
            mb={2}
          >
            {number_investment?.map((num, index) => {
              const isSecondLast = index === number_investment.length - 2;
              const isLast = index === number_investment.length - 1;
              return (
                <Grid
                  role={"button"}
                  item
                  xs={isSecondLast || isLast ? 12 : 2}
                  sm={isSecondLast || isLast ? 12 : 2}
                  md={isSecondLast || isLast ? 12 : 2}
                  lg={isSecondLast || isLast ? 12 : 2.1}
                  onClick={() => {
                    formik.setFieldValue(
                      "investment_count",
                      num?.value
                    );
                  }}
                  sx={{
                    background:
                      formik.values.investment_count ==
                        num?.value
                        ? theme?.palette?.customColors?.y50
                        : theme?.palette?.customColors?.whiteBG,
                    padding: "12px 16px",
                    height: 55,
                    borderRadius: "8px",
                    display: "grid",
                    placeItems: "center",
                    border: `1px solid ${formik.values.investment_count ==
                      num?.value
                      ? theme?.palette?.customColors?.y300
                      : theme?.palette?.customColors?.g75
                      }`,
                    boxShadow: "0px 1px 2px 0px #1018280D",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.customColors.g200}
                    fontSize="0.875rem"
                  >
                    {num?.label}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>

        </Grid>

      </Grid>

    </>
  )
}

export default StartupInvestments