
import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { ListItemText } from '@mui/material';

const ICONBUTTON = styled(Button)(({ theme, isActive, open, useStroke, useFill }) => {
    if (useStroke && useFill) {
        return ({
            backgroundColor: isActive ? theme.palette.customColors.indigo600 : 'transparent',
            minWidth: '48px',
            height: '48px',
            padding: '12px',
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            justifyContent: 'center',
            '& svg': {
                transition: 'stroke 0.3s ease',
                stroke: useStroke ? (isActive ? theme.palette.customColors.whiteBG : '#A4BCFD') : 'transparent',
                fill: useFill ? (isActive ? theme.palette.customColors.whiteBG : '#A4BCFD') : "transparent",

                width: '24px',
                height: '24px'
            },
            '&:hover': {
                backgroundColor: theme.palette.customColors.indigo600,
                '& svg': {
                    stroke: useStroke ? theme.palette.customColors.whiteBG : "transparent",
                    fill: useFill ? theme.palette.customColors.whiteBG : "transparent",
                }
            },
        })
    }
    else if (useStroke) {
        return ({
            backgroundColor: isActive ? theme.palette.customColors.indigo600 : 'transparent',
            minWidth: '48px',
            height: '48px',
            padding: '12px',
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            justifyContent: 'center',
            '& svg': {
                transition: 'stroke 0.3s ease',
                stroke: isActive ? theme.palette.customColors.whiteBG : '#A4BCFD',
                fill: "transparent",

                width: '24px',
                height: '24px'
            },
            '&:hover': {
                backgroundColor: theme.palette.customColors.indigo600,
                '& svg': {
                    stroke: theme.palette.customColors.whiteBG,
                    fill: "transparent",
                }
            },
        })
    }
    else if (useFill) {
        return ({
            backgroundColor: isActive ? theme.palette.customColors.indigo600 : 'transparent',
            minWidth: '48px',
            height: '48px',
            padding: '12px',
            borderRadius: '6px',
            display: 'flex',
            alignItems: 'center',
            gap: 10,
            justifyContent: 'center',
            '& svg': {
                transition: 'stroke 0.3s ease',
                stroke: 'transparent',
                fill: isActive ? theme.palette.customColors.whiteBG : '#A4BCFD',

                width: '24px',
                height: '24px'
            },
            '&:hover': {
                backgroundColor: theme.palette.customColors.indigo600,
                '& svg': {
                    stroke: "transparent",
                    fill: theme.palette.customColors.whiteBG,
                }
            },
        })
    }
});

const NavIconButton = ({ item, isActive, open }) => (
    <>
        {!open ? <Tooltip title={item?.title} arrow placement='right'>
            <ICONBUTTON isActive={isActive ? 1 : 0} sx={{ display: 'flex', justifyContent: "flex-start", width: "100%" }} useFill={item?.useFill} useStroke={item?.useStroke} open={open}>
                <item.icon style={{ width: '24px', height: '24px' }} />
                {open && <ListItemText sx={{ maxWidth: "min-content" }}>{item?.title}</ListItemText>}
            </ICONBUTTON>
        </Tooltip> :
            <ICONBUTTON isActive={isActive ? 1 : 0} sx={{ display: 'flex', justifyContent: "flex-start", width: "100%" }} useFill={item?.useFill} useStroke={item?.useStroke} open={open}>
                <item.icon style={{ width: '24px', height: '24px' }} />
                {open && <ListItemText sx={{ maxWidth: "max-content" }}>{item?.title}</ListItemText>}
            </ICONBUTTON>}
    </>
);

export { NavIconButton };
