
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken";

export const FetchRegisteredList = () => {
    return useMutation((payload) => {
      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/investors/getAllInvestorList`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
          'Content-Type': 'application/json'
        },
        data:payload
      })
    }, {
      // onSuccess: (data)=>console.log("API successfull"),
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err)=>{
        return verifyToken(err)
      },
    }) 
  }
export const FetchBackrrList = () => {
    return useMutation((payload) => {
      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/investors/getBackrrInvestorList`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
          'Content-Type': 'application/json'
        },
        data:payload
      })
    }, {
      // onSuccess: (data)=>console.log("API successfull"),
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err)=>{
        return verifyToken(err)
      },
    }) 
  }
export const FetchAllInvestorList = () => {
    return useMutation((payload) => {
      return axios({
        method: 'post',
        url: `${process.env.REACT_APP_BASE_URL}/investors/getAllMergeInvestorList`,
        headers: { 
          'Authorization': `Bearer ${Cookies.get('jwt')}`,
          'Content-Type': 'application/json'
        },
        data:payload
      })
    }, {
      // onSuccess: (data)=>console.log("API successfull"),
      // retry: (failureCount, error)=>{
      //   return RetryOnError(failureCount, error)
      // },
      retry: 0,
      onError: (err)=>{
        return verifyToken(err)
      },
    }) 
  }

