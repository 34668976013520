import { Accordion, AccordionDetails, AccordionSummary, Box, capitalize, Checkbox, CircularProgress, Divider, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, LinearProgress, MenuItem, Modal, Paper, Select, Slider, Stack, Switch, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material'
import React, { useEffect, useState, useRef } from 'react'
import personalInformationImage from '../../assets/personal_information.png'
import selectCountryImage from '../../assets/select_country.png'
import AddIcon from "../../assets/add_button.png"
import whatsapp_icon from "../../assets/whatsapp_main_icon.png"

import selectCityImage from '../../assets/select_city.png'
import investmentSize from '../../assets/investment_size.png'
import startupDetailsIcon from "../../assets/startup_details.png"
import linkedinAddIcon from "../../assets/linkedinhead_icon.png"
import startupInformationIcon from "../../assets/startup_information.png"
import startupInvestment from "../../assets/startup_investment.png"
import whatsappNotification from "../../assets/whatsapp_notification.png"
import eye_icon from "../../assets/eye.png"
import reserch from "../../assets/research.png"
import { BLUEBORDER, B300 as CustomButton } from '../../ui-components/CustomButton'
import CustomField from '../../ui-components/CustomField'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import "react-perfect-scrollbar/dist/css/styles.css";
import { ReactComponent as gpsIcon } from "../../assets/gps.svg"
import { ReactComponent as ArrowDown } from "../../assets/table-chevron-down.svg"
import CustomInputField from '../../ui-components/formik/CustomInputField'
// import CustomInputFieldOTP from '../../ui-components/CustomInputField'
import CustomUrlField from '../../ui-components/CustomUrlField'
import CustomLabel from '../../ui-components/CustomLabel'
import CustomBackgroundComponent from '../../ui-components/CustomBackgroundComponent'
import { FieldArray, Form, FormikProvider, useFormik, useFormikContext } from 'formik'
import * as Yup from 'yup';
import CustomAutoComplete from '../../ui-components/CustomAutoComplete'
import { FetchAllAvatars, FetchAllCategories, FetchAllCities, FetchAllCountries, FetchAllCountriesWithPhoneCodes, FetchAllInvestmentSize, FetchAllPopularCountries, FetchAllSectors, FetchAllStages, FetchAllStates, FetchAllSubCategories, VerifyWhatsAppOTP, VerifyPublicURL, FetchAllBusinessModel } from '../../api'
import CustomPhone from '../../ui-components/CustomPhone'
import CustomLogoButton from '../../ui-components/formik/CustomLogoButton'
import placeHolderImage from "../../assets/user_profile.png"
import marketplaceIcon from '../../assets/marketplace.png'
import mobileShoppingIcon from '../../assets/mobile-shopping.png'
import digitalServiceIcon from '../../assets/digital-services.png'
import laptopScreenIcon from '../../assets/laptop-screen.png'

import Cookies from "js-cookie"
import toast, { LoaderIcon } from 'react-hot-toast'
import { useTheme } from '@emotion/react'
import AuthWrapper1 from '../pages/AuthWrapper1'
import CustomHeaderIcon from '../../ui-components/CustomHeaderIcon'
import { containerRef, convertCurrencyAmountRange, currencyInputProps, getMarks, handleFormSubmit, notEmpty } from '../../utils'
import CustomValidationText from '../../ui-components/CustomValidationText'
import { Check, Edit, Opacity, Refresh, RemoveRedEye, SkipNextRounded } from '@mui/icons-material'
import SelectField from '../../ui-components/CustomDropdown'
import { useConfig } from '../../dynamicConfiguration/configContext'
import CustomCheckBox from 'ui-components/CustomCheckBox'
import CustomToast from 'ui-components/CustomToast'
import InvestorTypes from './InvestorTypes'
import InvestorAngel from './InvestorAngel'
import InvestorSuperAngel from './InvestorSuperAngel'
import InvestorMicroVC from './InvestorMicroVC'
import InvestorVC from './InvestorVC'
import InvestorFamilyOffice from './InvestorFamilyOffice'
import InvestorAccelerator from './InvestorAccelerator'
import InvestorIncubator from './InvestorIncubator'
import InvestorInvestmentBanker from './InvestorInvestmentBanker'
import PersonalDetails from './components/PersonalDetails'
import WhatsAppSuccessScreen from 'views/pages/WhatsAppSuccessScreen'

// import RedirectionPopup from './popups/login/RedirectionPopup'
// import ModalComponent from '../../ui-components/ModalComponent'


const OtpInputProps = {
    maxLength: 1, // Restrict input to a single character
    inputMode: 'numeric', // Use numeric keyboard on mobile devices
    pattern: "[0-9]*", // Only allow digits 0-9
    // title: "Please enter a number between 0 and 9",
    style: {
        textAlign: 'center', // Center the text in the input
    },
    min: 0,
    onKeyDown: (event) => {
        // Prevent the minus sign (-) from being entered
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Get the input value
        const inputValue = event.key;

        // Allow only numeric characters (0-9) and certain control keys
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];

        // Check if the pressed key is in the allowedKeys array
        if (!allowedKeys.includes(inputValue)) {
            // If not, prevent the key from being entered
            event.preventDefault();
        }
    },
    onInput: (event) => {
        // Get the input value
        const inputValue = event.target.value;

        // Remove non-numeric characters using a regular expression
        const numericValue = inputValue.replace(/[^0-9]/g, '');

        // Update the input field value with the filtered numeric value
        event.target.value = numericValue;
    },
    onKeyDown: (event) => {
        // Prevent the input of minus sign (key code 189)
        if (event.keyCode === 189 || event.key === "-") {
            event.preventDefault();
        }
    },
}

const numberInputProps = {
    inputMode: 'numeric', // Use numeric keyboard on mobile devices
    pattern: "[0-9]*", // Only allow digits 0-9
    // title: "Please enter a number between 0 and 9",
    style: {
        textAlign: 'center', // Center the text in the input
    },
    min: 0,
    onKeyDown: (event) => {
        // Prevent the minus sign (-) from being entered
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Prevent any input when the minus key is pressed, as it was already prevented in the onKeyDown event
        if (event.key === '-') {
            event.preventDefault();
        }
    },
    onKeyPress: (event) => {
        // Get the input value
        const inputValue = event.key;

        // Allow only numeric characters (0-9) and certain control keys
        const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];

        // Check if the pressed key is in the allowedKeys array
        if (!allowedKeys.includes(inputValue)) {
            // If not, prevent the key from being entered
            event.preventDefault();
        }
    },
    onInput: (event) => {
        // Get the input value
        const inputValue = event.target.value;

        // Remove non-numeric characters using a regular expression
        const numericValue = inputValue.replace(/[^0-9]/g, '');

        // Update the input field value with the filtered numeric value
        event.target.value = numericValue;
    },
    onKeyDown: (event) => {
        // Prevent the input of minus sign (key code 189)
        if (event.keyCode === 189 || event.key === "-") {
            event.preventDefault();
        }
    },
    disableUnderline: true
}

const countryCodes = [
    { code: '+1', label: 'US' },
    { code: '+91', label: 'IN' },
]

// const inputProps = {
//     maxLength: 1,
//     inputMode: 'numeric',
//     pattern: "[0-9]*",
//     style: {
//         textAlign: 'center',
//     },
//     onKeyDown: (event) => {
//         if (event.key === '-' || event.keyCode === 189) {
//             event.preventDefault();
//         }
//     },
//     onKeyPress: (event) => {
//         const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
//         if (!allowedKeys.includes(event.key)) {
//             event.preventDefault();
//         }
//     },
//     onInput: (event) => {
//         const inputValue = event.target.value.replace(/[^0-9]/g, '');
//         event.target.value = inputValue;
//     }
// };

// const investor_types = [
//     { label: "Angel", value: "angel", image_url: AngelIcon },
//     { label: "Super Angel", value: "super_angel", image_url: AngelIcon },
//     { label: "Micro VC", value: "micro_vc", image_url: VCIcon },
//     { label: "VC", value: "vc", image_url: VCIcon },
//     { label: "Family Office", value: "family_office", image_url: FamilyOfficeIcon },
//     { label: "Accelerator", value: "accelerator", image_url: AcceleratorIcon },
//     { label: "Incubator", value: "incubator", image_url: IncubatorIcon },
//     { label: "IB", value: "ib", image_url: IBIcon },
// ];

const InvestorTypeWiseStepsSwitchFunction = (
    {
        currentStep, setCurrentStep, investor_type, isLoading, isSectorAgnostic, setIsSectorAgnostic, isBusinessModelSelectAll, setIsBusinessModelSelectAll, resend, handleVerifyOTP, refetchProfileDetails, setError, error
    }) => {
    if (currentStep === 1) {
        return <InvestorTypes />;
    }
    else {
        switch (investor_type) {
            case "angel":
            case "super_angel":
                return <InvestorAngel
                    currentStep={currentStep}
                    investor_type={investor_type}
                    setCurrentStep={setCurrentStep}
                    isLoading={isLoading}
                    isSectorAgnostic={isSectorAgnostic}
                    setIsSectorAgnostic={setIsSectorAgnostic}
                    isBusinessModelSelectAll={isBusinessModelSelectAll}
                    setIsBusinessModelSelectAll={setIsBusinessModelSelectAll}
                    resend={resend}
                    handleVerifyOTP={handleVerifyOTP}
                    refetchProfileDetails={refetchProfileDetails}
                    setError={setError} error={error}
                />;
                break;
            // case "super_angel":
            //     return <InvestorSuperAngel currentStep={currentStep} investor_type={investor_type} />;
            // case "micro_vc":
            // case "vc":
            // case "family_office":
            // case "accelerator":
            // case "incubator":
            // case "investment_banker":
            //     return <InvestorMicroVC currentStep={currentStep} investor_type={investor_type} />;
            // case "vc":
            //     return <InvestorVC currentStep={currentStep} investor_type={investor_type} />;
            // case "family_office":
            //     return <InvestorFamilyOffice currentStep={currentStep} investor_type={investor_type} />;
            // case "accelerator":
            //     return <InvestorAccelerator currentStep={currentStep} investor_type={investor_type} />;
            // case "incubator":
            //     return <InvestorIncubator currentStep={currentStep} investor_type={investor_type} />;
            // case "investment_banker":
            //     return <InvestorInvestmentBanker currentStep={currentStep} investor_type={investor_type} />;
            default:
                return <InvestorMicroVC
                    currentStep={currentStep}
                    investor_type={investor_type}
                    setCurrentStep={setCurrentStep}
                    isLoading={isLoading}
                    isSectorAgnostic={isSectorAgnostic}
                    setIsSectorAgnostic={setIsSectorAgnostic}
                    isBusinessModelSelectAll={isBusinessModelSelectAll}
                    setIsBusinessModelSelectAll={setIsBusinessModelSelectAll}
                    resend={resend}
                    handleVerifyOTP={handleVerifyOTP}
                    refetchProfileDetails={refetchProfileDetails}
                    setError={setError} error={error}
                />;
                break;
            // default:
            //     return <InvestorTypes />;
        }
    }

}

const CustomHeaderIconSwitch = ({ currentStep, setCurrentStep, investor_type }) => {
    let customIcon;
    const shiftSteps = String(investor_type)?.toLowerCase()?.includes("angel")
    // console.log("current Step", currentStep)

    if (shiftSteps) {
        switch (currentStep) {
            case 3:
                customIcon = personalInformationImage;
                break;
            case 4:
                customIcon = selectCountryImage;
                break;
            case 5:
                customIcon = selectCityImage;
                break;
            case 6:
            case 8:
                customIcon = startupDetailsIcon;
                break;
            case 7:
                customIcon = investmentSize;
                break;
            case 9:
                customIcon = startupInformationIcon;
                break;
            case 10:
                customIcon = whatsappNotification;
                break;
            case 11:
                customIcon = linkedinAddIcon;
                break;
            default:
                customIcon = personalInformationImage;
                break;
        }
    }
    else {
        switch (currentStep) {
            case 4:
                customIcon = personalInformationImage;
                break;
            case 5:
                customIcon = selectCountryImage;
                break;
            case 6:
                customIcon = selectCityImage;
                break;
            case 7:
            case 9:
                customIcon = startupDetailsIcon;
                break;
            case 8:
                customIcon = investmentSize;
                break;
            case 10:
                customIcon = startupInformationIcon;
                break;
            case 11:
                customIcon = whatsappNotification;
                break;
            case 12:
                customIcon = linkedinAddIcon;
                break;
            default:
                customIcon = personalInformationImage;
                break;
        }
    }

    return <CustomHeaderIcon customIcon={customIcon} />;
};

const HeadingInvestorTypeStepsWiseSwitchFunction = ({ currentStep, setCurrentStep, investor_type }) => {
    const [isPressed, setIsPressed] = useState(true)
    const { config } = useConfig();
    const theme = useTheme();

    const shiftSteps = investor_type ? String(investor_type)?.toLowerCase()?.includes("angel") : true;

    let value = '';

    if (shiftSteps) {

        switch (currentStep) {
            case 2:
                value = "Create Investor Profile";
                break;
            case 1:
                value = "Investor Type";
                break;
            case 3:
                value = config?.dynamicText?.profilePicHeading;
                break;
            case 4:
                value = config?.dynamicText?.countryHeading;
                break;
            case 5:
                value = "Select State and City";
                break;
            case 6:
                value = "Preferred Category";
                break;
            case 7:
                value = config?.dynamicText?.sizeHeading;
                break;
            case 8:
                value = config?.dynamicText?.stagesHeading;
                break;
            case 9:
                value = config?.dynamicText?.investmentCountHeading;
                break;
            case 10:
                value = config?.dynamicText?.whatsappSettingHeading;
                break;
            case 11:
                value = "Profile URL";
                // value = config?.dynamicText?.linkedInHeading;
                break;
            default:
                value = '';
                break;
        }
    }
    else {
        switch (currentStep) {
            case 2:
                value = "Create Investor Profile";
                break;
            case 1:
                value = "Investor Type";
                break;
            case 3:
                value = "Create Investor Profile";
                break;
            case 4:
                value = "Upload Organization Logo";
                break;
            case 5:
                value = config?.dynamicText?.countryHeading;
                break;
            case 6:
                value = "Select State and City";
                break;
            case 7:
                value = "Preferred Category";
                break;
            case 8:
                value = config?.dynamicText?.sizeHeading;
                break;
            case 9:
                value = config?.dynamicText?.stagesHeading;
                break;
            case 10:
                value = config?.dynamicText?.investmentCountHeading;
                break;
            case 11:
                value = config?.dynamicText?.whatsappSettingHeading;
                break;
            case 12:
                value = "Profile URL";
                // value = config?.dynamicText?.linkedInHeading;
                break;
            case 13:
                value = "Partners";
                break;
            default:
                value = '';
                break;
        }
    }
    return <Typography variant='title' sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" } }} marginTop={1} textAlign={{ xs: "center", md: "start" }} position={"relative"}>
        {value} {((shiftSteps && (currentStep >= 6 && currentStep < 11)) || (!shiftSteps && (currentStep >= 7 && currentStep < 13))) ? <Typography
            variant='span'
            onClick={() => {
                setCurrentStep(currentStep + 1);
                Cookies.set("step", currentStep + 1)
                // setSearchParams({ page: currentStep + 1 });
            }}
            sx={{
                cursor: "pointer",
                opacity: isPressed ? 1 : 0.2,
                position: 'absolute',
                right: 0,
                top: { xs: "-4rem", md: 0 },
                marginRight: "1rem",
                display: "flex",
                alignItems: 'center'
                // marginBottom: "1rem",
            }}
            onMouseDown={() => setIsPressed(false)}
            onMouseUp={() => setIsPressed(true)}
            color={theme?.palette.customColors.b300}
            fontSize="1rem"
        >
            <span>Skip</span><SkipNextRounded />
        </Typography> : <></>}
    </Typography>

}

const SubHeadingInvestorTypeStepsWiseSwitchFunction = ({ currentStep, setCurrentStep, investor_type }) => {
    const { config } = useConfig();
    const shiftSteps = investor_type ? String(investor_type)?.toLowerCase()?.includes("angel") : true;
    let value = '';

    if (shiftSteps) {
        switch (currentStep) {
            case 2:
                value = "Please enter your personal details";
                break;
            case 1:
                value = "Please select investor type";
                break;
            case 3:
                value = config?.dynamicText?.profilePicSubHeading || '';
                break;
            case 4:
                value = config?.dynamicText?.countrySubHeading || '';
                break;
            case 5:
                value = "Choose your state and city to customize your experience";
                break;
            case 6:
                value = "Choose your preferred categories & sectors in which you want to invest";
                break;
            case 7:
                value = config?.dynamicText?.sizeSubHeading || '';
                break;
            case 8:
                value = "Tell us at which stage have you invested in the companies";
                break;
            case 9:
                value = config?.dynamicText?.investmentCountSubheading || '';
                break;
            case 10:
                value = config?.dynamicText?.whatsappSettingSubHeading || '';
                break;
            case 11:
                value = "Create a unique URL for your profile.";
                // value = config?.dynamicText?.linkedInSubHeading || '';
                break;
            default:
                value = '';
                break;
        }
    }
    else {
        switch (currentStep) {
            case 2:
                value = "Please enter your personal details";
                break;
            case 1:
                value = "Please select investor type";
                break;
            case 3:
                value = "Please enter your organization details";
                break;
            case 4:
                value = "Enhance your profile with organization branding";
                break;
            case 5:
                value = config?.dynamicText?.countrySubHeading || '';
                break;
            case 6:
                value = "Choose your state and city to customize your experience";
                break;
            case 7:
                value = "Choose your preferred categories & sectors in which you want to invest";
                break;
            case 8:
                value = config?.dynamicText?.sizeSubHeading || '';
                break;
            case 9:
                value = "Tell us at which stage have you invested in the companies";
                break;
            case 10:
                value = config?.dynamicText?.investmentCountSubheading || '';
                break;
            case 11:
                value = config?.dynamicText?.whatsappSettingSubHeading || '';
                break;
            case 12:
                value = "Create a unique URL for your profile.";
                // value = config?.dynamicText?.linkedInSubHeading || '';
                break;
            case 13:
                value = "Tell us about your partners";
                break;
            default:
                value = '';
                break;
        }
    }

    return (
        <Typography
            color="gray"
            variant="bodyTextRegular"
            textAlign={{ xs: "center", md: "start" }}
            marginBlock={1}
            marginBottom={2}
            marginTop={1}
        >
            {value}
        </Typography>
    );
};

const ProfileInformation = ({ progress, setProgress, step, currentStep, setCurrentStep, isLoading, isUpdating, resend, refetchProfileDetails, isSectorAgnostic, setIsSectorAgnostic, isBusinessModelSelectAll, setIsBusinessModelSelectAll }) => {
    const formik = useFormikContext()
    // console.log(formik.errors, "errrrr")
    const { config } = useConfig()
    const theme = useTheme();
    const navigate = useNavigate();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const isTablet = useMediaQuery(theme.breakpoints.up('sm'))
    const [searchParams, setSearchParams] = useSearchParams();
    const [search, setSearch] = useState("")
    const [searchState, setSearchState] = useState("")
    const [searchCountry, setSearchCountry] = useState("")
    // const [selectedCategory, setSelectedCategory] = useState("");
    // const [currentSubCategory, setCurrentSubCategory] = React.useState([]);
    // // const [searchSubCategory, setSearchSubCategory] = useState('');
    // const [searchSubCategory, setSearchSubCategory] = React.useState("");

    const { data: countries, isRefetching: isRefetchingCountries, isLoading: isLoadingCountries, refetch: refetchCountries } = FetchAllCountries({ search: searchCountry })
    const { data: countriesWithPhoneCodes, isRefetching: isRefetchingCountriesPC, isLoading: isLoadingCountriesPC, refetch: refetchCountriesPC } = FetchAllCountriesWithPhoneCodes({})
    const { data: popular_countries, isRefetching: isRefetchingPopularCountries, isLoading: isLoadingPopularCountries, refetch: refetchPopularCountries } = FetchAllPopularCountries()
    const [currentCountry, setCurrentCountry] = React.useState(countries?.find(item => item?._id == formik?.values?.country_id))
    const { data: states, isRefetching: isRefetchingStates, isLoading: isLoadingStates, refetch: refetchStates } = FetchAllStates({ search: searchState, countryID: currentCountry !== null ? countries?.find(item => item?._id == formik.values?.country_id)?.value : currentCountry?.value })
    const [currentState, setCurrentState] = React.useState(states?.find(item => item?.value === formik?.values?.state_id)?.label || '')
    const { data: cities, isRefetching: isRefetchingCities, isLoading: isLoadingCities, refetch: refetchCities } = FetchAllCities({ search, stateID: currentState !== null ? states?.find(item => item?._id == formik.values?.state_id)?.value : currentState?.value, isPopular: 0 });
    const [currentCity, setCurrentCity] = React.useState(cities?.find(item => item?.value === formik?.values?.city_id)?.label || '');
    const { data: startup_stages, isRefetching: isRefetchingStages, isLoading: isLoadingStages, refetch: refetchStages } = FetchAllStages()
    // const { data: startup_sectors, isRefetching: isRefetchingSectors, isLoading: isLoadingSectors, refetch: refetchSectors } = FetchAllSectors()
    // const { data: avatarList, isRefetching: isRefetchingAvatarList, isLoading: isLoadingAvatarList, refetch: refetchAvatarList } = FetchAllAvatars()
    // const { data: investmentSizeList, isRefetching: isRefetchingInvestmentSizeList, isLoading: isLoadingInvestmentSizeList, refetch: refetchInvestmentSizeList } = FetchAllInvestmentSize()
    const { mutateAsync: verifyWhatsAppOTPAsync, isLoading: isOTPLoading, data: verifyWhatsAppOTPResponse } = VerifyWhatsAppOTP();
    const { mutateAsync: verifyPublicURLAsync, data: verifyPublicURLResponse, isIdle, error: verifyPublicURLError } = VerifyPublicURL();
    // console.log(isIdle, "isIdle")

    // const { data: startup_categories, isRefetching: isRefetchingCategories, isLoading: isLoadingCategories, refetch: refetchCategories } = FetchAllCategories()
    // const { data: startup_sub_categories, isRefetching: isRefetchingSubCategories, isLoading: isLoadingSubCategories, refetch: refetchSubCategories } = FetchAllSubCategories({ category: selectedCategory })
    // const [currency, setCurrency] = useState("INR");
    // const [currencyOptions, setCurrencyOptions] = useState([]);
    // const [selectedRange, setSelectedRange] = useState(null); // Track selected range
    // const [allAmountRanges, setAllAmountRanges] = useState([])
    // const [amountRanges, setAmountRanges] = useState([])
    // const [selectedStages, setSelectedStages] = useState([])
    const [error, setError] = useState(null)
    // const [countDown, setCountDown] = useState(30);
    // const [otpValues, setOtpValues] = useState(Array(4).fill(''));
    // const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
    // const [currentBusinessModel, setCurrentBusinessModel] = React.useState([]);
    // const [searchBusinessModel, setSearchBusinessModel] = React.useState([]);
    // const { data: startup_business_model, isRefetching: isRefetchingBusinessModel, isLoading: isLoadingBusinessModel, refetch: refetchBusinessModel } = FetchAllBusinessModel({})

    // React.useEffect(() => {
    //     if (!isLoading && !isLoadingBusinessModel && formik?.values && Array.isArray(startup_business_model) && Array.isArray(formik?.values.business_model_id)) {
    //         const businessModel = startup_business_model?.filter(item => formik?.values?.business_model_id?.includes(item?._id));
    //         if (businessModel.length) {
    //             setCurrentBusinessModel(
    //                 businessModel.map(item => ({
    //                     label: item?.name,
    //                     value: item?._id,
    //                     description: item?.description,
    //                 }))
    //             );
    //         }
    //     }

    // }, [isLoading, isLoadingBusinessModel, formik?.values, startup_business_model, formik?.values?.business_model_id]);

    // React.useEffect(() => {
    //     if (formik.values?.business_model_type === "All") {
    //         setIsBusinessModelSelectAll(true)
    //         setCurrentBusinessModel([])
    //     }
    // }, [startup_business_model, formik?.values?.business_model_type])

    // const handleSearchBusinessModelChange = (event, value) => {
    //     // console.log(event.target.value, value,"ca")
    //     setSearchBusinessModel(value);
    //     // refetchCities();
    // };
    // const handleSelectionBusinessModelChange = (event, businessModel) => {
    //     formik.setFieldValue("business_model_id", businessModel ? businessModel?.map(item => item?.value) : []);
    //     setCurrentBusinessModel(businessModel)
    //     event.stopPropagation();
    //     // console.log(businessModel ,"businessModelbusinessModelbusinessModel")
    // };
    // const handleSearchUpdateBusinessModel = () => {
    //     refetchBusinessModel();
    // }
    // const availableOptionsBusinessModel = startup_business_model?.filter(
    //     item =>
    //         !(currentBusinessModel || []).some(selected => selected.value === item._id)
    // ) || [];


    // useEffect(() => {
    //     if (countDown > 0) {
    //         const timer = setTimeout(() => setCountDown(prev => prev - 1), 1000);
    //         return () => clearTimeout(timer);
    //     }
    // }, [countDown]);

    // const number_investment = [
    //     {
    //         value: "01",
    //         label: "01",
    //     },
    //     {
    //         value: "02",
    //         label: "02",
    //     },
    //     {
    //         value: "03",
    //         label: "03",
    //     },
    //     {
    //         value: "04",
    //         label: "04",
    //     },
    //     {
    //         value: "05",
    //         label: "05",
    //     },
    //     {
    //         value: "06",
    //         label: "06",
    //     },
    //     {
    //         value: "07",
    //         label: "07",
    //     },
    //     {
    //         value: "08",
    //         label: "08",
    //     },
    //     {
    //         value: "09",
    //         label: "09",
    //     },
    //     {
    //         value: "10",
    //         label: "10",
    //     },
    //     {
    //         value: "11",
    //         label: "Invested in more than 10 startups",
    //     },
    //     {
    //         value: "0",
    //         label: config?.dynamicText?.newToInvestment,
    //     },

    // ];

    // useEffect(() => {
    //     // console.log("investmentSizeList", investmentSizeList)
    //     setCurrencyOptions(investmentSizeList?.currencies)
    //     setAllAmountRanges(investmentSizeList?.amount)
    // }, [investmentSizeList])

    // useEffect(() => {
    //     if (currentStep == 6) {
    //         if (formik?.values?.investment_amount_type) {
    //             setCurrency(formik?.values?.investment_amount_type)
    //             formik.setFieldValue("investment_amount_type", formik?.values?.investment_amount_type)
    //         } else {
    //             setCurrency(investmentSizeList?.currencies[0]?.name)
    //             formik.setFieldValue("investment_amount_type", investmentSizeList?.currencies[0]?.name)
    //         }
    //         if (formik?.values?.investment_size) {
    //             formik.setFieldValue("investment_size", formik?.values?.investment_size)
    //             formik.setFieldValue("min", formik?.values?.min)
    //             formik.setFieldValue("max", formik?.values?.max)
    //             setSelectedRange(formik?.values?.investment_size)
    //         } else {
    //             setSelectedRange(null)
    //         }
    //     } else if (currentStep == 9) {
    //         setIsWhatsAppEnabled(formik?.values?.enabled)
    //     } else if (currentStep == 10) {
    //         setIsPublicEnabled(formik?.values?.linkedin_public_profile)
    //     }
    // }, [currentStep])

    // useEffect(() => {
    //     if (currency != null) {
    //         let modifiedOptions = allAmountRanges.filter(option => { return option.currency == currency })
    //         setAmountRanges(modifiedOptions)
    //     }
    // }, [currency])

    // const handleCurrencyChange = (event) => {
    //     setCurrency(event.target.value);
    //     // console.log(event.target.value,"event.target.value")
    //     formik.setFieldValue("investment_amount_type", event.target.value)

    //     setValue1([0, 1]);
    //     formik.setFieldValue('min', getMarks(event.target.value)[0].selectedValue);
    //     formik.setFieldValue('max', getMarks(event.target.value)[1].selectedValue);
    // };

    // const handleRangeSelect = (range) => {
    //     setSelectedRange(range.title);
    //     formik.setFieldValue("investment_size", range.title)
    //     formik.setFieldValue("min", range.min)
    //     formik.setFieldValue("max", range?.max)
    // };

    // const getFormattedRange = (range) => {
    //     const symbol = currency === 'USD' ? '$' : '₹'; // USD or INR symbol

    //     // Format the range string based on currency
    //     if (currency === 'INR') {
    //         // Convert "k" (thousand) to "L" (lakh) for INR and prepend symbol for INR
    //         const formattedRange = range.replace(/k/g, 'L');
    //         return `${symbol}${formattedRange} ${currency}`;
    //     }

    //     // Default for USD with "k"
    //     return `${symbol}${range} ${currency}`;
    // };
    // const [isWhatsAppEnabled, setIsWhatsAppEnabled] = useState(false);
    // const [isPublicEnabled, setIsPublicEnabled] = useState(false);

    // const handleToggle = () => {
    //     setIsWhatsAppEnabled(!isWhatsAppEnabled);
    //     formik.setFieldValue("enabled", !isWhatsAppEnabled)
    // };

    // const handleTogglePublic = (event) => {
    //     setIsPublicEnabled(!isPublicEnabled);
    //     formik.setFieldValue("linkedin_public_profile", !isPublicEnabled)
    //     // setIsPublicEnabled(event.target.checked)
    // };
    // const [isVerify, setIsVerify] = useState(false);
    // const [isMobileEdited, setIsMobileEdited] = useState(true);

    // const handleVerify = () => {
    //     setIsVerify(true);
    //     setIsMobileEdited(false);
    //     formik?.handleSubmit();
    // };
    // const handleMobileChange = (event) => {
    //     const value = event.target.value;
    //     setIsMobileEdited(value.length > 0);
    //     setIsVerify(false);
    //     formik.handleChange(event);
    // };
    // const [isPublicURLVerify, setIsPublicURLVerify] = useState(false);
    const isWhatsAppEnabled = formik?.values?.isWhatsAppEnabled || false;
    const isPublicURLVerify = formik?.values?.isPublicURLVerify || false;
    // const handlePublicURLVerify = async (public_url_updated) => {
    //     const publicURL = public_url_updated ? public_url_updated : formik?.values?.public_url;


    //     if (!publicURL) {
    //         formik.setFieldTouched("public_url", true);
    //         formik.setFieldError("public_url", "Public URL cannot be empty.");
    //         return;
    //     }

    //     if (!isPublicURLVerify) {
    //         const payload = { slug: publicURL, investor_id: formik?.values?._id };
    //         try {
    //             await verifyPublicURLAsync(payload);
    //             formik.setFieldValue("isPublicURLVerify", true); // Set to verified if successful
    //             formik.setFieldError("public_url", null); // Clear any previous errors
    //             // console.log("success")
    //         } catch (err) {
    //             const errorMessage = err?.response?.data?.message || "Verification failed. Please try again.";
    //             formik.setFieldTouched("public_url", true);
    //             formik.setFieldError("public_url", errorMessage);
    //             formik.setFieldValue("isPublicURLVerify", false);
    //         }
    //     } else {
    //         formik.setFieldValue("isPublicURLVerify", false); // Allow editing if already verified
    //     }
    // };

    const initialState = formik?.values?.first_name + formik?.values?.last_name;
    const isUpdated = formik?.values?.isUpdated || false;

    React.useEffect(() => {

        // console.log(formik?.values?.public_url ,'formik?.values?.public_url')
        const verifyWithRetries = async (initialPublicURL) => {

            // Exit if public_url is invalid
            if (!initialPublicURL || initialPublicURL.trim() === "" || (currentStep !== 10 && (!isUpdated)) || !formik?.values?.first_name || formik?.values?.first_name === "" || !formik?.values?.last_name || formik?.values?.last_name === "") {
                return;
            }

            let count = 0;
            let isVerified = false;
            let publicURL = initialPublicURL;

            while (!isVerified && count <= 10) {
                const payload = { slug: publicURL, investor_id: formik?.values?._id };
                try {
                    await verifyPublicURLAsync(payload);
                    // formik.registerField("isPublicURLVerify", true); // Mark as verified
                    formik.setFieldValue("isPublicURLVerify", true); // Mark as verified
                    formik.setFieldError("public_url", null); // Clear any errors
                    isVerified = true;
                } catch (err) {
                    const errorMessage = err?.response?.data?.message || "Verification failed.";
                    formik.setFieldError("public_url", errorMessage);
                    formik.setFieldValue("isPublicURLVerify", false);
                    // formik.unregisterField("isPublicURLVerify", true); // Mark as verified

                    // Update the slug with retry count
                    count++;
                    publicURL = initialPublicURL + `0${count}`;
                    formik.setFieldValue("public_url", publicURL);
                }
            }
        };

        verifyWithRetries(notEmpty(formik?.values?.public_url) ? formik?.values?.public_url : initialState);
    }, [initialState, currentStep, formik?.values?.first_name, formik?.values?.last_name]); // Depend on public_url changes

    const handleUpdateCityName = (city) => {
        formik.setFieldValue("city_id", city?.value)
        // console.log(city,"city")
        setCurrentCity({ label: city?.label, value: city?.value })
        setSearch(city?.label)
    }
    const handleUpdateCountryName = (country) => {
        formik.setFieldValue("country_id", country?._id)
        // console.log(country, "country")
        setCurrentCountry(country)
    }
    const handleUpdateStateName = (state) => {
        // formik.setFieldValue("state_id", state?._id)
        // console.log(state, "state")
        // setCurrentState({ label: country?.name, value: country?._id, id: country?.id })
    }
    const handleSearchCityChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearch(value);
        // refetchCities();
    };
    const handleSearchCountryChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchCountry(value);
        // refetchCities();
    };
    const handleSearchStateChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchState(value);
        // refetchCities();
    };
    const handleSelectionCityChange = (event, value) => {
        // console.log(value, "value")

        formik.setFieldValue("city_id", value ? value?._id : '');
        setCurrentCity(value)
        event.preventDefault();
    };
    const handleSelectionCountryChange = (event, value) => {
        event.preventDefault();
        // console.log(value, "value")
        formik.setFieldValue("country_id", value ? value?._id : '');
        setCurrentCountry(value)
        formik.setFieldValue("state_id", null);
        setCurrentState(null)
        setCurrentCity(null);
        formik.setFieldValue("city_id", null)
        setSearchState("")
        setSearch("")
        refetchData()
        // console.log("EnDDDD")
        event.preventDefault();
    };
    const handleSelectionStateChange = (event, value) => {
        // console.log(value, "value")
        formik.setFieldValue("state_id", value ? value?._id : null);
        setCurrentState(value)
        setCurrentCity(null);
        formik.setFieldValue("city_id", null)
        setSearch("")
        refetchData('city')
        event.preventDefault();
    };
    const handleSearchUpdateCity = () => {
        refetchCities();
    }
    const handleSearchUpdateCountry = () => {
        refetchCountries();
    }
    const handleSearchUpdateState = () => {
        refetchStates();
    }
    const refetchData = (type) => {
        if (type === "city") {
            refetchCities().then(response => {
                setTimeout(() => {
                    setCurrentCity(response.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
                    setSearch("");
                    // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
                }, 0)
            })
        }
        else {
            formik.values?.country_id != undefined && refetchStates().then(res => {
                setCurrentState(res?.data?.filter(item => item?._id == formik.values.state_id)[0]?.label || '');
                setSearchState("");
                // setSearchState(res?.data?.find(item => item?.value == formik.values.state_id)?.label || '');
            })

            formik.values?.state_id != undefined && refetchCities().then(response => {
                setTimeout(() => {
                    setCurrentCity(response?.data?.find(item => item?._id == formik?.values?.city_id)?.label || '')
                    setSearch("");
                    // setSearch(response.data?.find(item => item?.value == formik?.values?.city_id)?.label || '')
                }, 200)
            })

        }
        // refetchPopularCities()
    }

    useEffect(() => {
        refetchCountries().then(respo => {
            if (formik.values?.country_id != undefined) {
                refetchStates().then(res => {
                    setCurrentState(res?.data?.filter(item => item?._id == formik.values?.state_id)[0])
                }).then(() => {
                    if (formik.values.state_id != undefined) {
                        refetchCities().then(response => {
                            setCurrentCity(response?.data?.find(item => item?._id == formik?.values?.city_id)[0])
                        })
                    }
                })
            }
        })
    }, [])

    // const handleInputChange = (event, index) => {
    //     const { value } = event.target;
    //     if (value.length === 1 && index < inputRefs.length - 1) {
    //         inputRefs[index + 1].current.focus();
    //     }
    //     const newOtpValues = [...otpValues];
    //     newOtpValues[index] = value;
    //     setOtpValues(newOtpValues);
    //     setError(null);
    // };

    // const handleKeyDown = (event, index) => {
    //     // Get the reference to the current input element
    //     // const currentRef = inputRefs[index];

    //     if (event.key === 'Backspace' && !event.target.value && index > 0) {
    //         inputRefs[index - 1].current.focus();
    //     }
    //     if (event.key === 'Enter') {
    //         handleVerifyOTP();
    //     }

    //     // Check if the arrow keys are pressed
    //     if (event.key === 'ArrowRight') {
    //         // Move focus to the next input if it's not the last one
    //         if (index < inputRefs.length - 1) {
    //             inputRefs[index + 1]?.current?.focus();
    //         }
    //     } else if (event.key === 'ArrowLeft') {
    //         // Move focus to the previous input if it's not the first one
    //         if (index > 0) {
    //             inputRefs[index - 1]?.current?.focus();
    //         }
    //     }
    // };

    // const handlePaste = (event) => {
    //     event.preventDefault();

    //     // Get the pasted data and filter out non-numeric characters
    //     const pastedData = event.clipboardData
    //         .getData('text')
    //         .replace(/\D/g, '') // Remove all non-numeric characters
    //         .slice(0, 4); // Ensure only the first 4 characters are processed

    //     const newOtpValues = [...otpValues];

    //     pastedData.split('').forEach((char, index) => {
    //         if (inputRefs[index]) {
    //             inputRefs[index].current.value = char;
    //             newOtpValues[index] = char;
    //         }
    //     });

    //     setOtpValues(newOtpValues);
    // };

    // const handleResendingOTP = () => {
    //     resend({ mobile_number: formik?.values.whatsapp_number }).then(() => {
    //         setCountDown(30);
    //     });
    // };

    // const handleVerifyOTP = () => {
    //     const OTP = otpValues.join('');
    //     if (OTP?.trim()?.length > 0) {
    //         verifyWhatsAppOTPAsync({ enabled: formik?.values?.enabled, otp: OTP });
    //     }
    //     else {
    //         setError("Please enter OTP");
    //     }
    // };

    const handleVerifyOTP = (tempFunction) => {
        // console.log(tempFunction,'adf')
        // console.log(formik?.values?.newOtpValues,'formik?.values?.newOtpValues')
        if (!tempFunction) {
            const OTP = formik?.values?.newOtpValues?.join('');
            if (OTP?.trim()?.length > 0) {
                verifyWhatsAppOTPAsync({ enabled: formik?.values?.enabled, otp: OTP }, {
                    onSuccess: (successData) => {
                        setTimeout(() => {
                            handleOpenWhatsappSuccessPopup();
                            formik.setFieldValue("otp_verified", true);
                            // setCurrentStep(currentStep + 1);
                            // setSearchParams({ page: currentStep + 1 });
                            // navigate('/whatsApp-success');
                        }, 100);
                    },
                    onError: (error) => {
                        error?.data?.data?.message && setError(verifyWhatsAppOTPResponse?.data?.data?.message || null)
                    }
                });
            }
            else {
                setError("Please enter OTP");
            }
        }
        // tempFunction();
    };

    // const handleVerifyUrl = async () => {
    //     try {
    //         const result = await verifyPublicURLAsync({
    //             url: formik.values.public_url, // Pass the current public URL as a payload
    //         });
    //         //   console.log("Verification successful:", result);
    //         // Optionally, handle success state or update form state if needed
    //     } catch (error) {
    //         console.error("Error verifying URL:", error);
    //         // Handle the error (e.g., show a notification or update the UI)
    //     }
    // };


    const handleOpenWhatsappSuccessPopup = () => {
        formik.registerField("openWhatsAppSuccessPopup", true)
        formik.setFieldValue("openWhatsAppSuccessPopup", true)
    }
    const handleCloseWhatsappSuccessPopup = () => {
        formik.setFieldValue("openWhatsAppSuccessPopup", false);
        setError(null)
        refetchProfileDetails()
        setCurrentStep(currentStep + 1);
        formik.setFieldValue("step", currentStep + 1);
        formik.unregisterField("openWhatsAppSuccessPopup");
    }

    // useEffect(() => {
    //     if (formik?.values?.newOtpValues?.join("")?.trim()?.length > 0) {
    //         if (verifyWhatsAppOTPResponse?.data?.statusCode === 200) {
    //             setTimeout(() => {
    //                 setError(null)
    //                 refetchProfileDetails()
    //                 // setCurrentStep(currentStep + 1);
    //                 // setSearchParams({ page: currentStep + 1 });
    //                 // navigate('/whatsApp-success');
    //                 handleOpenWhatsappSuccessPopup();
    //             }, 100);
    //         } else if (verifyWhatsAppOTPResponse?.data?.statusCode !== 200) {
    //             verifyWhatsAppOTPResponse?.data?.message && setError(verifyWhatsAppOTPResponse?.data?.message || null)

    //         }
    //     }
    // }, [verifyWhatsAppOTPResponse?.data?.statusCode]);

    // // Effect to set selected category based on formik values
    // useEffect(() => {
    //     if (!isLoading && !isLoadingCategories && formik?.values && Array.isArray(startup_categories)) {
    //         const category = startup_categories?.filter(item => formik?.values?.category_id?.includes(item?._id));

    //         if (category.length) {
    //             setSelectedCategory(category.map(item => item?.name).join(","));  // Or whichever property you want to use
    //         }
    //     }
    // }, [isLoading, isLoadingCategories, formik?.values, startup_categories]);

    // // Effect to refetch subcategories when selectedCategory changes
    // useEffect(() => {
    //     if (!isRefetchingCategories && !isLoadingCategories && selectedCategory) {
    //         refetchSubCategories();
    //     }
    // }, [selectedCategory, isRefetchingCategories || isLoadingCategories]);

    // useEffect(() => {
    //     if (formik.values?.sector_id?.length > 0 && startup_sub_categories) {
    //         const matchedSubCategories = startup_sub_categories?.filter(subCat =>
    //             formik.values?.sector_id?.includes(subCat?._id)
    //         );
    //         setCurrentSubCategory(
    //             matchedSubCategories?.map(subCat => ({
    //                 label: subCat?.sub_category,
    //                 value: subCat?._id,
    //                 description: subCat?.description,
    //             }))
    //         );
    //     }
    //     if (formik.values?.sector_type === "All") {
    //         setIsSectorAgnostic(true);
    //         setCurrentSubCategory([])
    //     }
    // }, [startup_sub_categories, formik.values?.sector_type]);

    // const handleSearchSubCategoryChange = (event, value) => {
    //     if (value !== undefined) {
    //         setSearchSubCategory(value); // Update search state
    //     }
    //     // console.log(setSearchSubCategory,"setSearchSubCategory")
    // };
    // const handleSelectionSubCategoryChange = (event, selectedSectors) => {
    //     formik.setFieldValue(
    //         "sector_id",
    //         selectedSectors.map(item => item.value)
    //     );
    //     setCurrentSubCategory(selectedSectors);
    // };
    // const handleSearchUpdateSubCategory = () => {
    //     refetchSubCategories();
    // };
    // const availableOptions = startup_sub_categories
    //     ?.filter(item => {
    //         const isSelected = currentSubCategory.some(
    //             selected => selected.value === item._id
    //         );
    //         const matchesSearch = item.sub_category
    //             .toLowerCase()
    //             .includes(searchSubCategory.toLowerCase());
    //         return !isSelected && matchesSearch;
    //     }) || [];
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    const matchesUpXs = useMediaQuery(theme.breakpoints.up('xs'));
    const matchesUpSm = useMediaQuery(theme.breakpoints.up('sm'));
    const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));
    const dimensions = matchUpLg ? { width: "5rem", height: "5rem", marginTop: 'auto' } : matchesUpMd ? { width: "4.5rem", height: "4.5rem", marginTop: 'auto' } : matchesUpMd ? { width: "6rem", height: "6rem", marginTop: 'auto' } : { width: "4rem", height: "4rem", marginTop: 'auto' }

    const [isPressed, setIsPressed] = React.useState(true);

    const minDistance = 1;

    const valuetext = (value, currency) => {
        // console.log(value, currency, 'ffff')
        return convertCurrencyAmountRange(value, currency);
    }

    // const [value1, setValue1] = React.useState(
    //     !isLoading && formik?.values?.min !== undefined && formik?.values?.max !== undefined
    //         ? [
    //             getMarks(currency).find(item => item.selectedValue === formik.values.min)?.value || 0,
    //             getMarks(currency).find(item => item.selectedValue === formik.values.max)?.value || 3
    //         ]
    //         : [0, 3]
    // );

    // const handleChange1 = (event, newValue, activeThumb) => {
    //     if (!Array.isArray(newValue)) return;

    //     const minValue = Math.min(newValue[0], value1[1] - 1);
    //     const maxValue = Math.max(newValue[1], value1[0] + 1);

    //     setValue1([minValue, maxValue]);
    //     formik.setFieldValue('min', getMarks(currency)[minValue].selectedValue);
    //     formik.setFieldValue('max', getMarks(currency)[maxValue].selectedValue);
    // };


    // React.useEffect(() => {
    //     if (!isLoading && formik.values?.min !== undefined && formik.values?.max !== undefined) {
    //         const minIndex = getMarks(currency).findIndex(item => item.selectedValue === formik.values?.min);
    //         const maxIndex = getMarks(currency).findIndex(item => item.selectedValue === formik.values?.max);

    //         setValue1([
    //             minIndex !== -1 ? minIndex : 0,
    //             maxIndex !== -1 ? maxIndex : 3
    //         ]);
    //     }
    // }, [formik.values, isLoading, currency]);

    // React.useEffect(() => {
    //     if (!isLoading && investmentSizeList?.currencies && Array.isArray(investmentSizeList?.currencies)) {
    //         setCurrency(investmentSizeList?.currencies?.find(item => item?.name === formik?.values?.investment_amount_type)?.name)
    //         // setCurrency()
    //     }
    // }, [investmentSizeList?.currencies, isLoading, formik.values])

    // console.log(formik?.values?.investor_type,"formik?.values?.investor_type")


    const shiftSteps = formik?.values?.investor_type ? String(formik?.values?.investor_type)?.toLowerCase()?.includes("angel") : true;

    return (
        <>
            <Modal open={formik?.values?.openWhatsAppSuccessPopup} onClose={handleCloseWhatsappSuccessPopup}>
                <WhatsAppSuccessScreen Action={handleCloseWhatsappSuccessPopup} />
            </Modal>
            <Grid xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"column"} sx={{ maxHeight: "80vh", height: '100%', overflow: "hidden" }}>
                {/* <CustomHeaderIcon customIcon={currentStep === 3 ? personalInformationImage : currentStep === 4 ? selectCountryImage : currentStep === 7 ? investmentSize : currentStep === 8 || 6 ? startupDetailsIcon : currentStep === 9 ? startupInformationIcon : currentStep === 10 ? whatsappNotification : currentStep === 11 ? linkedinAddIcon : currentStep === 5 ? selectCityImage : personalInformationImage} /> */}
                <CustomHeaderIconSwitch currentStep={currentStep} />
                <HeadingInvestorTypeStepsWiseSwitchFunction currentStep={currentStep} setCurrentStep={setCurrentStep} investor_type={formik?.values?.investor_type} />
                <SubHeadingInvestorTypeStepsWiseSwitchFunction currentStep={currentStep} setCurrentStep={setCurrentStep} investor_type={formik?.values?.investor_type} />


                {/* {currentStep === 7 &&
                    <>
                        <Grid container alignItems="center" justifyContent="space-between"
                            marginTop={"-0.75rem"}>
                            <Grid item>
                                <CustomLabel sx={{ marginBottom: 0 }}>{config?.validationFields?.size?.label}</CustomLabel>
                            </Grid>
                            <Grid item display="flex" alignItems="center">
                                <Select
                                    labelId="currency-label"
                                    id="currency-select"
                                    value={!isLoadingInvestmentSizeList ? currency : "INR"}
                                    defaultValue='INR'
                                    onChange={handleCurrencyChange}
                                    sx={{
                                        border: 'none',
                                        color: '#344054',
                                        fontSize: '0.875rem',
                                        borderRadius: 0,
                                        padding: 0,
                                        margin: 0,
                                        minWidth: '80px',
                                        boxShadow: 'none',
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            border: 'none',
                                        },
                                        '&:focus': {
                                            outline: 'none',
                                        }
                                    }}
                                >
                                    {
                                        currencyOptions?.map(item => {
                                            return (
                                                <MenuItem value={item.name}>{item.name}</MenuItem>
                                            )
                                        })
                                        
                                    }
                                </Select>
                            </Grid>
                        </Grid>
                    </>
                } */}
                {/* {currentStep === 8 &&
                    <>
                        <CustomLabel sx={{ marginTop: "0rem", textTransform: 'none' }}>{config?.validationFields?.stages?.label} (MULTI SELECT) {formik.errors.stage_id && (
                            <CustomValidationText value={formik.errors.stage_id} />
                        )}</CustomLabel>
                    </>
                } */}

                {/* {currentStep === 9 &&
                    <>
                        <CustomLabel sx={{ marginTop: "0rem" }}>{config?.validationFields?.investment_count?.label} </CustomLabel>
                    </>
                } */}
                {/* {
                    currentStep === 11 &&
                    <>
                        <Grid
                            container
                            xs={12}
                            gap={1}
                            paddingBottom={2}

                        >

                            <Grid item xs={12} position="relative" pt={1}>
                                <CustomUrlField
                                    label={config?.validationFields?.linkedIn_profile_url?.label}
                                    name={'linkedIn_profile_url'}
                                    value={
                                        String(formik?.values?.linkedIn_profile_url)?.startsWith("https://")
                                            ? String(formik?.values?.linkedIn_profile_url)?.replace("https://", "")
                                            : formik?.values?.linkedIn_profile_url

                                    }
                                    placeholder={config?.validationFields?.linkedIn_linkedIn_profile_url?.placeholder}
                                    onChange={(evt) => {

                                        formik.setFieldValue("linkedIn_profile_url", String(evt.target.value)?.startsWith("https://")
                                            ? String(evt.target.value)?.replace("https://", "")
                                            : evt.target.value)
                                    }}
                                />
                                </Grid>
                            <Grid item xs={12} position="relative" pb={1} mt={"-6px"}>
                                <CustomUrlField
                                    sx={{
                                        '& .MuiInputBase-root .Mui-disabled': {
                                            backgroundColor: '#fff !important'
                                        },
                                        "& .MuiInputBase-root .Mui-disabled > .MuiInputAdornment-root": {
                                            backgroundColor: '#fff !important'
                                        }
                                    }}
                                    label={"PUBLIC URL"}
                                    startAdornmentText={formik?.values?.backrr_base_url?.substring(0, formik?.values?.backrr_base_url?.lastIndexOf('/'))}
                                    name={'public_url'}
                                    value={formik?.values?.public_url}
                                    disabled={isPublicURLVerify}
                                    // placeholder={"Username"}
                                    defaultValue={String(formik?.values?.first_name + formik?.values?.last_name)?.toLowerCase()?.replaceAll(" ", "")?.replace(/[^a-zA-Z0-9]/g, '')}
                                    // sx={{marginBottom:1}}
                                    onChange={(evt) => formik.setFieldValue("public_url", String(evt.target.value)?.replaceAll(" ", "")?.replaceAll(/[^a-zA-Z0-9]/g, ''))}
                                    InputProps={
                                        {
                                            endAdornment: (
                                                <InputAdornment
                                                    position="end"
                                                    sx={{
                                                        marginLeft: "0px",
                                                        paddingInline: "8px",
                                                        height: "49px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        cursor: "pointer",
                                                    }}
                                                    onClick={() => handlePublicURLVerify()}
                                                >
                                                    {isPublicURLVerify ? <Edit /> : <Check sx={{ color: "green" }} />}
                                                </InputAdornment>
                                            ),
                                            pattern: '[a-zA-Z0-9]*', // Optional: Validates only alphanumeric input
                                            maxLength: 30, // Optional: Limit the input length
                                        }
                                    }

                                />

                            </Grid>

                            <Grid
                                role="button"
                                alignItems="center"
                                display={'flex'}
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}

                                sx={{
                                    background: `${theme?.palette?.customColors?.y50}`,
                                    padding: "1px 16px",
                                    height: 55,
                                    borderRadius: "8px",
                                    display: "grid",
                                    gridTemplateColumns: "auto 1fr auto",
                                    border: `1px solid ${theme?.palette?.customColors?.y300}`,
                                    boxShadow: "0px 1px 2px 0px #1018280D",
                                    cursor: "pointer",
                                }}
                            >
                                <img src={eye_icon} alt='whats' width={20} height={20} style={{ marginRight: '0.5rem' }} />

                                <Typography
                                    variant="subtitle2"
                                    fontWeight={400}
                                    color={theme.palette.customColors.g300}
                                    fontSize="1rem"
                                >
                                    {config?.dynamicText?.enableDiscover}
                                </Typography>
                                <Switch checked={isPublicEnabled} onChange={handleTogglePublic} />
                            </Grid>
                            <Typography variant='caption' fontWeight={400} pt={0} fontSize={'14px'}>
                                {config?.dynamicText?.dicoverMessage}
                            </Typography>
                        </Grid>
                    </>
                } */}
                <Box containerRef={containerRef} style={{
                    minHeight: "35vh", height: '100%',
                    paddingRight: isDesktop ? "0.5rem" : 0,
                    //  maxHeight: 'calc(100vh - 205px)',
                    overflowX: 'hidden',
                    marginTop: 0,
                    paddingBottom: "1rem"
                    // marginTop: (currentStep === 5 || currentStep === 6 ? 0 : "1.5rem"), paddingBottom: currentStep === 5 || currentStep === 6 ? 0 : '1.5rem'
                }} className="scrollbar-container" id='onboarding-scroller'>

                    <InvestorTypeWiseStepsSwitchFunction
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        investor_type={formik?.values?.investor_type}
                        isLoading={isLoading}
                        isSectorAgnostic={isSectorAgnostic}
                        setIsSectorAgnostic={setIsSectorAgnostic}
                        isBusinessModelSelectAll={isBusinessModelSelectAll}
                        setIsBusinessModelSelectAll={setIsBusinessModelSelectAll}
                        resend={resend}
                        handleVerifyOTP={handleVerifyOTP}
                        refetchProfileDetails={refetchProfileDetails}
                        setError={setError} error={error}
                    />

                    {/* {currentStep === 2 &&
                        <>
                            

                        </>} */}

                    {/* {currentStep === 3 &&
                        <>
                            <Grid container xs={12} gap={0} sx={{ overflowX: "hidden" }}>
                                <Grid item xs={12} sm={12}>
                                    <CustomLogoButton label={config?.dynamicText?.uploadPhoto} handleUpdateFile={(file) => { formik.setFieldValue("custom_image_url", file); formik.setFieldValue("avatar_selected", null) }} name={"custom_image_url"} value={formik.values.custom_image_url} placeholder={placeHolderImage} Pheight={100} Pwidth={100} />
                                    </Grid>
                            </Grid>
                        </>} */}
                    {(shiftSteps ? currentStep === 4 : currentStep === 5) &&
                        <>
                            {!isLoadingCountries && !isRefetchingCountries ? <CustomAutoComplete
                                options={!isLoadingCountries && !isRefetchingCountries ? countries : []}
                                label={config?.dynamicText?.search}
                                name={"country_id"}
                                noOptionsText={!isLoadingCountries && !isRefetchingCountries ? "No Options" : "Searching..."}
                                placeholder={config?.dynamicText?.searchPlaceholder}
                                icon={gpsIcon}
                                handleSearchChange={handleSearchCountryChange}
                                handleSelectionChange={handleSelectionCountryChange}
                                search={searchCountry}
                                value={currentCountry}
                                defaultValue={countries?.find(item => item?._id == formik?.values?.country_id)}
                                loading={isRefetchingCountries}
                                handleSearchUpdate={handleSearchUpdateCountry}
                            /> : <>
                                <Box className="skeleton" sx={{ height: 17.6, width: '100%', marginBottom: 1 }}></Box>
                                <Box className="skeleton" sx={{ height: 50.6, width: '100%', marginBottom: 2 }}></Box>
                            </>}
                            <CustomLabel>POPULAR COUNTRIES</CustomLabel>
                            <Grid item container xs={12} gap={2} display={"flex"} justifyContent={"space-between"} paddingBottom={{ xs: "0rem" }}>
                                {popular_countries?.map((country, index) => <Grid key={index} onClick={() => handleUpdateCountryName(country)} item xs={5.5} sm={3.7} md={3.65} lg={3.65} flexGrow={1}
                                >
                                    <button onClick={(e) => e.preventDefault()} style={{
                                        minHeight: { xs: "4.5rem", sm: "3.1rem" },
                                        maxHeight: "7rem",
                                        height: "100%",
                                        padding: "0.5rem 0.2rem 0rem 0.5rem",
                                        background: formik.values.country_id === country?._id ? "#FFFAE6" : "#FFF",
                                        fontSize: "0.875rem",
                                        cursor: 'pointer',
                                        border: `1px solid ${formik.values.country_id === country?._id ? "#FFC900" : "#A1A3A7"}`,
                                        borderRadius: "8px",
                                        overflow: "hidden",
                                        display: "flex",
                                        width: '100%',
                                        justifyContent: "space-between"
                                    }}>

                                        <span style={{ textAlign: 'left' }}>{country?.label}</span>
                                        <img loading='lazy' rel="preload" src={country?.image_url} style={{ ...country?.size, ...dimensions, marginLeft: 'auto' }} />
                                    </button>
                                </Grid>
                                )}
                            </Grid>
                        </>}

                    {(shiftSteps ? currentStep === 5 : currentStep === 6) &&
                        <>
                            {!isLoadingStates && !isRefetchingStates ? <CustomAutoComplete
                                options={!isLoadingStates && !isRefetchingStates && states?.length > 0 ? states : []}
                                label={`${config?.dynamicText?.searchState} ${String(currentCountry?.label || countries?.find(item => item?._id === formik.values.country_id)?.label)?.toUpperCase()}`}
                                placeholder="Type a state name"
                                name="state_id"
                                noOptionsText={!isLoadingStates && !isRefetchingStates ? "No Options" : "Searching..."}
                                icon={gpsIcon}
                                handleSearchChange={handleSearchStateChange}
                                handleSelectionChange={handleSelectionStateChange}
                                search={searchState}
                                value={currentState?.label}
                                defaultValue={states?.find(item => item?._id === formik?.values?.state_id)?.label}
                                loading={false}
                                handleSearchUpdate={handleSearchUpdateState}
                            /> : <>
                                <Box className="skeleton" sx={{ height: 17.6, width: '100%', marginBottom: 1 }}></Box>
                                <Box className="skeleton" sx={{ height: 50.6, width: '100%', marginBottom: 2 }}></Box>
                            </>}
                            {!isLoadingCities && !isRefetchingCities && (currentState !== null ? states?.find(item => item?._id == formik.values?.state_id)?.value : currentState?.value) && <CustomAutoComplete
                                options={!isLoadingCities && !isRefetchingCities && cities?.length > 0 ? cities : []}
                                noOptionsText={!isLoadingCities && !isRefetchingCities ? "No Options" : "Searching..."}
                                label={`SEARCH CITY IN ${String(currentState?.label || states?.find(item => item?._id === formik.values.state_id)?.label)?.toUpperCase()}`}
                                placeholder="Type a city name"
                                name="city_id"
                                icon={gpsIcon}
                                handleSearchChange={handleSearchCityChange}
                                handleSelectionChange={handleSelectionCityChange}
                                search={search}
                                value={currentCity?.label}
                                defaultValue={cities?.find(item => item?._id === formik?.values?.city_id)?.label}
                                handleSearchUpdate={handleSearchUpdateCity}
                            />}

                        </>}

                    {/* {currentStep === 6 && (
                        <>
                            <Grid container item xs={12} gap={1} paddingBottom={0} paddingRight={1} marginTop={'0rem'} >
                                <Grid item xs={12}>
                                    <CustomLabel sx={{ marginTop: "0rem", marginBottom: 0 }}>Select Category (Multi Select) {formik.errors?.category_id && (
                                        <CustomValidationText value={formik.errors?.category_id} style={{ textTransform: 'none' }} />
                                    )}</CustomLabel>
                                </Grid>
                                <FieldArray
                                    name="category_id"
                                    render={(arrayHelpers) => (
                                        <>
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    display: 'flex',
                                                    flexWrap: 'wrap',
                                                    padding: 0,
                                                    paddingBottom: 1,
                                                    marginTop: 0,
                                                    justifyContent: 'space-between',
                                                }}
                                                gap={2}
                                            >
                                                {Array.isArray(startup_categories) &&
                                                    startup_categories?.map((category, index) => {
                                                        const isSelected = formik?.values?.category_id?.includes(category?._id);

                                                        const handleCheckboxChange = () => {
                                                            if (isSelected) {
                                                                const idx = formik.values.category_id.indexOf(category?._id);
                                                                arrayHelpers.remove(idx);
                                                            } else {
                                                                arrayHelpers.push(category?._id);
                                                            }
                                                        };

                                                        return (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={5.8}
                                                                md={5.75}
                                                                key={category?._id}
                                                                onClick={handleCheckboxChange}
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    gap: '0.5rem',
                                                                    background: isSelected ? '#FFFAE6' : '#FFF',
                                                                    fontSize: '0.875rem',
                                                                    border: `1px solid ${isSelected ? '#FFC900' : '#A1A3A7'}`,
                                                                    borderRadius: '8px',
                                                                    paddingRight: '1rem',
                                                                    paddingLeft: '1rem',
                                                                    paddingBlock: '1.5rem',
                                                                    height: '2.5rem',
                                                                    cursor: 'pointer',
                                                                    textTransform: 'none',
                                                                    color: 'inherit',
                                                                }}
                                                            >
                                                                {index === 0 && (
                                                                    <img
                                                                        src={laptopScreenIcon}
                                                                        style={{ height: '1.6rem', width: '1.6rem', marginRight: '0.5rem' }}
                                                                        alt="icon"
                                                                    />
                                                                )}
                                                                {index === 1 && (
                                                                    <img
                                                                        src={mobileShoppingIcon}
                                                                        style={{ height: '1.4rem', width: '1.4rem', marginRight: '0.5rem' }}
                                                                        alt="icon"
                                                                    />
                                                                )}
                                                                {index === 2 && (
                                                                    <img
                                                                        src={marketplaceIcon}
                                                                        style={{ height: '1.4rem', width: '1.4rem', marginRight: '0.5rem' }}
                                                                        alt="icon"
                                                                    />
                                                                )}
                                                                {index === 3 && (
                                                                    <img
                                                                        src={digitalServiceIcon}
                                                                        style={{
                                                                            height: '1.4rem',
                                                                            width: '1.4rem',
                                                                            marginRight: '0.5rem',
                                                                            transform: 'rotateY(180deg)',
                                                                        }}
                                                                        alt="icon"
                                                                    />
                                                                )}

                                                                <span>{category?.name}</span>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={isSelected}
                                                                    readOnly
                                                                    style={{ marginLeft: 'auto', cursor: 'pointer' }}
                                                                />

                                                            </Grid>
                                                        );
                                                    })}
                                            </Grid>
                                        </>
                                    )}
                                />
                                <Grid item xs={12} mt="0.5rem">
                                    <CustomAutoComplete
                                        label={<Box display="flex" alignItems="center" gap={1}>
                                            <span>SELECT SECTORS (MULTI SELECT)</span>
                                            <FormControlLabel
                                                label="SECTOR AGNOSTIC"
                                                labelPlacement="start"
                                                control={
                                                    <input
                                                        type="checkbox"
                                                        checked={isSectorAgnostic}
                                                        onChange={(e) => setIsSectorAgnostic(e.target.checked)}
                                                        style={{ cursor: 'pointer' }}
                                                    />

                                                }
                                                sx={{
                                                    marginLeft: 'auto',
                                                    marginRight: '0px',
                                                    gap: "6px",
                                                    '& .MuiFormControlLabel-label': {
                                                        fontSize: '0.875rem',
                                                        color: '#0f1319',
                                                    },
                                                }}
                                                onClick={() => { formik.setFieldValue('sector_id', []); setCurrentSubCategory([]) }}
                                            />
                                        </Box>}
                                        options={availableOptions.map(item => ({
                                            label: item.sub_category,
                                            value: item._id,
                                            description: item.description,
                                        }))}
                                        disabled={isSectorAgnostic}
                                        name="sector_id"
                                        multiple={true}
                                        noOptionsText={
                                            !isLoadingSubCategories && !isRefetchingSubCategories
                                                ? 'No Options'
                                                : 'Searching...'
                                        }
                                        placeholder={isSectorAgnostic ? "Sector Agnostic" : "Type a sector name"}
                                        onInputChange={(event, value) =>
                                            handleSearchSubCategoryChange(event, value)
                                        }
                                        handleSelectionChange={handleSelectionSubCategoryChange}
                                        value={currentSubCategory}
                                        renderOption={(props, option) => (
                                            <li {...props} style={{ display: 'grid' }}>
                                                <span style={{ fontWeight: 600 }}>{option.label}</span>
                                                <p style={{ fontSize: '0.8rem' }}>{option.description}</p>
                                            </li>
                                        )}
                                        loading={isRefetchingSubCategories}
                                        handleSearchUpdate={handleSearchUpdateSubCategory}
                                    />
                                </Grid>
                                <Grid item xs={12} sx={{ marginTop: '-5px' }}>
                                    <CustomAutoComplete
                                        label={<Box display="flex" alignItems="center" gap={1}>
                                            <span>SELECT BUSINESS MODEL (MULTI SELECT)</span>
                                            <FormControlLabel
                                                label="SELECT ALL"
                                                labelPlacement="start"
                                                control={
                                                    <input
                                                        type="checkbox"
                                                        checked={isBusinessModelSelectAll}
                                                        onChange={(e) => setIsBusinessModelSelectAll(e.target.checked)}
                                                        style={{ cursor: 'pointer' }}
                                                    />

                                                }
                                                sx={{
                                                    marginLeft: 'auto',
                                                    marginRight: '0px',
                                                    gap: "6px",
                                                    '& .MuiFormControlLabel-label': {
                                                        fontSize: '0.875rem',
                                                        color: '#0f1319',
                                                    },
                                                }}
                                                onClick={() => { formik.setFieldValue('business_model_id', []); setCurrentBusinessModel([]) }}
                                            />
                                        </Box>

                                        }
                                        options={!isLoadingBusinessModel && !isRefetchingBusinessModel && Array.isArray(availableOptionsBusinessModel) ? availableOptionsBusinessModel?.map(item => ({ label: item?.name, value: item?._id, description: item?.description })) : []}
                                        name={"business_model_id"}
                                        disabled={isBusinessModelSelectAll}
                                        multiple={true}
                                        noOptionsText={!isLoadingBusinessModel && !isRefetchingBusinessModel ? "No Options" : "Searching..."}
                                        placeholder={isBusinessModelSelectAll ? "All Selected" : "Business model"}
                                        // icon={null}
                                        handleSearchChange={handleSearchBusinessModelChange}
                                        handleSelectionChange={handleSelectionBusinessModelChange}
                                        search={searchBusinessModel}
                                        renderOption={(props, option) => (
                                            <li {...props} style={{ display: 'grid' }}>
                                                <span style={{ fontWeight: 600 }}>{option.label}</span>
                                                <p style={{ fontSize: '0.8rem' }}>{option?.description}</p>
                                            </li>
                                        )}
                                        value={currentBusinessModel || []}
                                        defaultValue={startup_business_model?.filter(item => item?._id == formik?.values?.business_model_id)}
                                        loading={isRefetchingBusinessModel}
                                        handleSearchUpdate={handleSearchUpdateBusinessModel}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )} */}

                    {/* {currentStep === 7 &&
                        <>
                            <Grid
                                display={"flex"}
                                justifyContent={"space-between"}
                                flexWrap={"wrap"}
                                item
                                container
                                xs={12}
                                gap={"1rem"}
                            >
                                <Slider
                                    sx={{
                                        paddingBlock: '3rem 3rem',
                                        marginLeft: currency === "INR" ? '1rem' : "1.5rem",
                                        marginRight: '2rem',
                                        color: theme?.palette?.customColors.indigo600,
                                        '& .MuiSlider-thumb': {
                                            width: 20,
                                            height: 20,
                                            backgroundColor: '#ffffff',
                                            border: `2px solid ${theme?.palette?.customColors.indigo600}`,
                                            '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                                boxShadow: '0px 0px 0px 8px rgba(25, 118, 210, 0.16)',
                                            },
                                        },
                                        '& .MuiSlider-track': {
                                            height: 8,
                                            borderRadius: 4,
                                        },
                                        '& .MuiSlider-rail': {
                                            height: 8,
                                            borderRadius: 4,
                                            opacity: 0.3,
                                            backgroundColor: '#bfbfbf',
                                        },
                                        '& .MuiSlider-markLabel': {
                                            fontSize: '0.875rem',
                                            color: '#333',
                                            top: '15px !important'
                                        },
                                        '& .MuiSlider-valueLabel': {
                                            backgroundColor: '#1976d2',
                                            fontSize: '0.75rem',
                                            top: -10,
                                            display: "none",
                                        },
                                    }}
                                    getAriaLabel={() => 'Investment Size'}
                                    value={value1}
                                    min={0}
                                    max={getMarks(currency)?.length - 1}
                                    step={1}
                                    marks={getMarks(currency)}
                                    onChange={handleChange1}
                                    valueLabelDisplay="auto"
                                    disableSwap
                                />
                            </Grid>
                        </>
                    } */}

                    {/* {currentStep === 8 &&
                        <>
                            <Grid item container xs={12} gap={2} mt={1} display={"flex"} paddingBottom={4}>
                                {startup_stages?.map((stage, index) => {
                                    const isSelected = formik.values.stage_id?.includes(stage?._id);
                                    return (
                                        <Grid key={index}
                                            onClick={() => {
                                                if (isSelected) {
                                                    selectedStages.splice(formik.values.stage_id.indexOf(stage?._id), 1);
                                                } else {
                                                    selectedStages.push(stage?._id);
                                                }
                                                formik.setFieldValue("stage_id", selectedStages)
                                            }
                                            } item xs={12} sm={5.8} md={5.6} lg={5.75} flex={1} >
                                            <button key={index} onClick={(e) => e.preventDefault()} style={{
                                                minHeight: "2.5rem",
                                                height: "100%",
                                                maxHeight: '4rem',
                                                width: "100%",
                                                padding: "1rem 0rem 6rem 0.5rem",
                                                background: isSelected ? "#FFFAE6" : "#FFF",
                                                fontSize: "0.875rem",
                                                cursor: 'pointer',
                                                border: `1px solid ${isSelected ? "#FFC900" : "#A1A3A7"}`,
                                                borderRadius: "8px",
                                                overflow: "hidden",
                                                display: "flex",
                                                justifyContent: "space-between",
                                                position: 'relative'
                                            }}>
                                                <span style={{ position: 'absolute', fontWeight: 600, top: "5px" }}>
                                                    {stage?.name}
                                                </span>
                                                <img src={stage?.image_url} rel='preload' style={{ ...stage?.size, marginLeft: 'auto' }} />
                                            </button>
                                        </Grid>
                                    )
                                }
                                )}
                            </Grid>
                        </>} */}
                    {/* {currentStep === 9 &&
                        <>
                            <Grid container xs={12} gap={0} marginTop={1} display={"flex"} alignItems={"flex-start"} alignContent={"flex-start"} justifyContent={"space-between"}>
                                <Grid item container xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"row"}>
                                    <Grid
                                        display={"flex"}
                                        flexWrap={"wrap"}
                                        justifyContent={"space-between"}
                                        item
                                        container
                                        xs={12}
                                        gap={{ xs: 0.5, sm: 1, md: 2 }}
                                        mb={2}
                                    >
                                        {number_investment?.map((num, index) => {
                                            const isSecondLast = index === number_investment.length - 2;
                                            const isLast = index === number_investment.length - 1;
                                            return (
                                                <Grid
                                                    role={"button"}
                                                    item
                                                    xs={isSecondLast || isLast ? 12 : 2}
                                                    sm={isSecondLast || isLast ? 12 : 2}
                                                    md={isSecondLast || isLast ? 12 : 2}
                                                    lg={isSecondLast || isLast ? 12 : 2.1}
                                                    onClick={() => {
                                                        formik.setFieldValue(
                                                            "investment_count",
                                                            num?.value
                                                        );
                                                    }}
                                                    sx={{
                                                        background:
                                                            formik.values.investment_count ==
                                                                num?.value
                                                                ? theme?.palette?.customColors?.y50
                                                                : theme?.palette?.customColors?.whiteBG,
                                                        padding: "12px 16px",
                                                        height: 55,
                                                        borderRadius: "8px",
                                                        display: "grid",
                                                        placeItems: "center",
                                                        border: `1px solid ${formik.values.investment_count ==
                                                            num?.value
                                                            ? theme?.palette?.customColors?.y300
                                                            : theme?.palette?.customColors?.g75
                                                            }`,
                                                        boxShadow: "0px 1px 2px 0px #1018280D",
                                                        cursor: "pointer",
                                                    }}
                                                >
                                                    <Typography
                                                        variant="subtitle2"
                                                        color={theme.palette.customColors.g200}
                                                        fontSize="0.875rem"
                                                    >
                                                        {num?.label}
                                                    </Typography>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>

                                </Grid>

                            </Grid>
                        </>
                    } */}
                    {/* {console.log(formik.values?.startups, "formik.values?.startups")} */}
                    {/* {currentStep === 9 &&
                        <>
                            <Grid
                                container
                                xs={12}
                                display="flex"
                                gap={0}
                                justifyContent="space-between"
                                flexDirection="column"
                                paddingBottom={2}
                            >
                                <FieldArray
                                    name="startups"
                                    render={(arrayHelpers) => (
                                        <>
                                            {formik.values?.startups && Array.isArray(formik.values?.startups) && formik.values?.startups?.length > 0 &&
                                                formik.values?.startups?.map((current, index) => (
                                                    <React.Fragment>
                                                        <Accordion key={index} defaultExpanded={formik.values?.startups?.length - 1 === index} sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                                                            <AccordionSummary
                                                                expandIcon={<ArrowDown height={20} width={20} stroke={theme?.palette?.customColors?.g100} />}
                                                                aria-controls="panel1-content"
                                                                id="panel1-header"
                                                                sx={{ fontWeight: '700', padding: 0 }}
                                                            >
                                                                Startup {index + 1}
                                                            </AccordionSummary>
                                                            <AccordionDetails sx={{ paddingBottom: formik.values?.startups?.length - 1 === index ? 0 : "0.5rem", paddingInline: 0 }}>
                                                                <>
                                                                    <Grid item xs={12} position="relative" pt={1}>
                                                                        <CustomInputField
                                                                            label="STARTUP NAME"
                                                                            name={`startups.${index}.startup_name`}
                                                                            value={current?.startup_name}
                                                                            placeholder="Startup Name"
                                                                            onChange={(e) =>
                                                                                formik.setFieldValue(
                                                                                    `startups.${index}.startup_name`,
                                                                                    e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1)
                                                                                )
                                                                            }
                                                                        />
                                                                    </Grid>
                                                                    <Box>
                                                                        <Grid item xs={12} display="flex">
                                                                            <CustomUrlField
                                                                                label="STARTUP WEBSITE URL"
                                                                                sx={{ marginBottom: 0 }}
                                                                                name={`startups.${index}.startup_url`}
                                                                                value={current?.startup_url}
                                                                                onChange={(e) =>
                                                                                    formik.setFieldValue(
                                                                                        `startups.${index}.startup_url`,
                                                                                        String(e.target.value)?.startsWith("https://")
                                                                                            ? String(e.target.value)?.replace("https://", "")
                                                                                            : e.target.value
                                                                                    )
                                                                                }
                                                                            />
                                                                        </Grid>
                                                                    </Box>
                                                                    {formik.values?.startups?.length > 1 && <Box>
                                                                        <Typography variant="caption" color="#000" sx={{ textDecorationLine: 'underline', cursor: 'pointer' }} onClick={() => {
                                                                            formik.setFieldValue(`startups.${index}`, { ...current, edit: false }).then(() =>
                                                                                arrayHelpers.remove(index)
                                                                            );
                                                                        }}>
                                                                            Remove startup
                                                                        </Typography>
                                                                    </Box>}
                                                                </>
                                                            </AccordionDetails>
                                                        </Accordion>

                                                        {formik.values?.startups?.length - 1 === index && (
                                                            <>
                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        gap: 1,
                                                                        justifyContent: "space-between",
                                                                    }}
                                                                >
                                                                    <Box
                                                                        sx={{ cursor: "pointer" }}
                                                                        onClick={(e) => {
                                                                            if (current?.startup_name !== "" && current?.startup_url !== "") {
                                                                                formik.setFieldValue(`startups.${index}`, { ...current, edit: false }).then(() =>
                                                                                    arrayHelpers.insert(index + 1, {
                                                                                        startup_name: "",
                                                                                        startup_url: "",
                                                                                        edit: true,
                                                                                    })
                                                                                );
                                                                            } else {
                                                                                handleFormSubmit(e, formik);

                                                                            }
                                                                        }}
                                                                    >
                                                                        <IconButton>
                                                                            <Tooltip title="Add next startup">
                                                                                <img src={AddIcon} rel="preload" width={32} height={32} />
                                                                            </Tooltip>
                                                                        </IconButton>
                                                                        <Typography marginLeft="1rem" variant="caption" color="#000">
                                                                            Add another startup
                                                                        </Typography>
                                                                    </Box>
                                                                </Box>
                                                                <Box sx={{ display: "flex", alignItems: "center", gap: 1, justifyContent: "space-between" }}>
                                                                    <Typography
                                                                        color={theme?.palette.customColors.b300}
                                                                        fontSize="1rem"
                                                                    >
                                                                    </Typography>
                                                                </Box>
                                                            </>
                                                        )}
                                                        {formik.values?.startups?.length > 1 && <Divider sx={{ marginTop: 1, marginBottom: 1 }} />}
                                                    </React.Fragment>
                                                ))}
                                        </>
                                    )}
                                />
                            </Grid>
                        </>
                    } */}
                    {/* {currentStep === 10 &&
                        <>
                            <Grid
                                role="button"
                                alignItems="center"
                                item
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                sx={{
                                    background: `${theme?.palette?.customColors?.y50}`,
                                    padding: "0px 16px",
                                    height: 55,
                                    borderRadius: "8px",
                                    display: "grid",
                                    gridTemplateColumns: "auto 1fr auto",
                                    border: `1px solid ${theme?.palette?.customColors?.y300}`,
                                    boxShadow: "0px 1px 2px 0px #1018280D",
                                    cursor: "pointer",
                                }}
                            >
                                <img src={whatsapp_icon} alt='whats' width={20} height={20} style={{ marginRight: '0.5rem' }}></img>
                                <Typography
                                    variant="subtitle2"
                                    color={theme.palette.customColors.g200}
                                    fontSize="0.875rem"
                                >
                                    Enable WhatsApp notification
                                </Typography>
                                <Switch checked={isWhatsAppEnabled} onChange={handleToggle} />
                            </Grid>
                            {isWhatsAppEnabled && (
                                <>
                                    <Grid height="min-content" item xs={12} sm={12} md={12} lg={12} pt={2}>
                                        <CustomInputField
                                            name="whatsapp_number"
                                            label="MOBILE NUMBER"
                                            fullWidth
                                            InputProps={{ ...currencyInputProps }}
                                            onChange={handleMobileChange}
                                            value={formik.values.whatsapp_number}
                                        />
                                    </Grid>
                                    {!isVerify && isMobileEdited && (
                                        <Typography
                                            color={theme.palette.customColors.b300}
                                            fontSize="1rem"
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                textDecoration: 'underline',
                                                textUnderlineOffset: '5px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleVerify}
                                        >
                                            <span>Verify mobile number</span>
                                        </Typography>
                                    )}

                                    {isVerify && (
                                        <Grid container xs={12} display="grid" sx={{ placeItems: 'flex-start', placeContent: 'stretch', gap: '0.4rem' }}>
                                            <Typography color="gray" variant="subtitle2" mb="0rem">
                                                ENTER OTP
                                            </Typography>
                                            <Grid container xs={12} gap={2}>
                                                {inputRefs?.map((ref, index) => (
                                                    <Grid item flex={1} key={index}>
                                                        <CustomInputFieldOTP
                                                            inputProps={inputProps}
                                                            required
                                                            sx={{ marginBottom: 0 }}
                                                            inputRef={ref}
                                                            onChange={(event) => handleInputChange(event, index)}
                                                            onKeyDown={(event) => handleKeyDown(event, index)}
                                                            onPaste={index === 0 ? handlePaste : undefined}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            {error !== null && <CustomValidationText style={{ marginTop: '-0.5rem' }} value={error} />}
                                            {isLoading ? (
                                                <Typography
                                                    color={theme.palette.customColors.b300}
                                                    sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '0.5rem' }}
                                                    fontSize="1rem"
                                                >
                                                    <CircularProgress sx={{ color: theme.palette.customColors.b300 }} size="1rem" />
                                                    <span>Resending OTP</span>
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    color={theme.palette.customColors.b300}
                                                    fontSize="1rem"
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        textDecoration: 'underline',
                                                        textUnderlineOffset: '5px',
                                                        marginTop: '0.5rem',
                                                    }}
                                                >
                                                    <span
                                                        onClick={countDown === 0 ? handleResendingOTP : undefined}
                                                        style={{
                                                            color: countDown > 0 ? theme.palette.customColors.b75 : theme.palette.customColors.b300,
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        {config?.dynamicText?.resend}
                                                    </span>
                                                    <span>&nbsp;{countDown > 0 && `(${countDown} Sec)`}</span>
                                                </Typography>
                                            )}
                                        </Grid>
                                    )}
                                </>
                            )}




                        </>
                    } */}

                </Box>

            </Grid>
            <Grid item container maxHeight={"5.5vh"} xs={12} display={"flex"} alignItems={"flex-end"} justifyContent={"center"} gap={3} >
                {currentStep !== 1 && <Grid xs={5} md={5.5} lg={5.65} item>
                    <BLUEBORDER sx={{ textTransform: "none", padding: 2, borderWidth: 1, borderRadius: "8px", height: '56.5px' }} type="no usetr" onClick={(e) => {
                        e.preventDefault();
                        if (currentStep === 1) {
                            Cookies.remove("jwt");
                            navigate("/login");
                        }
                        else {
                            setCurrentStep(currentStep - 1);
                        };
                        // setSearchParams({ page: currentStep - 1 });
                        Cookies.set("step", currentStep - 1)
                        window.scrollTo(0, 0);
                        //  refetchData();
                    }} fullWidth>Back</BLUEBORDER>
                </Grid>}
                <Grid xs={5} md={5.5} lg={5.65} item>
                    <CustomButton disabled={(shiftSteps ? currentStep === 11 : currentStep === 12) ? !isPublicURLVerify : false} sx={{ padding: 2, borderRadius: "8px", height: '56.5px', ":disabled": { color: 'gray' } }} onClick={(e) => {
                        if (shiftSteps ? currentStep == 10 : currentStep === 11) {

                            if (formik.values.enabled || isWhatsAppEnabled) {
                                if (formik?.values?.otp_verified) {
                                    setCurrentStep(currentStep + 1);
                                    Cookies.set("step", currentStep + 1)
                                }
                                else {
                                    // console.log("Verifyededed")
                                    if (formik?.values?.isVerify) {
                                        handleVerifyOTP()

                                    } else {
                                        handleFormSubmit(e, formik)
                                        if (formik?.values?.whatsapp_number?.trim()?.length > 0) {
                                            formik.setFieldValue("isVerify", true)
                                        }
                                    }
                                }
                            } else {
                                setCurrentStep(currentStep + 1);
                                Cookies.set("step", currentStep + 1)
                                // setSearchParams({ page: currentStep + 1 });
                            }
                        } else {
                            // console.log("happendein")
                            handleFormSubmit(e, formik)
                        }
                    }} fullWidth> {isUpdating ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.4rem'} /> : (shiftSteps ? currentStep === 11 : currentStep === 13) ? "Submit" : "Next"} </CustomButton>
                </Grid>
            </Grid>
            {/* </Grid>

                <CustomBackgroundComponent />
            </Grid> */}

        </>
    )
}

export default ProfileInformation
