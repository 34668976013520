import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken";

export const AddReport = () => {
    return useMutation((payload) => {
        return axios({
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}/reports/add`,
            headers: {
                'Authorization': `Bearer ${Cookies.get('jwt')}`,
                'Content-type': 'multipart/form-data'
            },
            data: payload
        })
    }, {
        // onSuccess: (data)=>console.log("API successfull", data.data.data['_id']),
        // retry: (failureCount, error)=>{
        //   return RetryOnError(failureCount, error)
        // },
        retry: 0,
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchReportDetails = (id) => {
    return useQuery(
        ['master-listing-template-report--details-using-id-fetch' + id, id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/reports/get-reports/${id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data?.data?.data,'values new')
            const savedData = data?.data?.data;
            const savedValues = {
                report: {
                    name: savedData?.reports[0]?.name,
                    overview: savedData?.reports[0]?.overview,
                    duration: {
                        from_date: savedData?.reports[0]?.from_date,
                        to_date: savedData?.reports[0]?.to_date
                    },
                    report_documents: [
                        // {
                        //     document_name: '',
                        //     type_id: "0",
                        //     reports: null
                        // }
                    ],
                },
                documents: savedData?.documents || [],
                // ...savedData
            }
            return savedValues
        },
        refetchOnWindowFocus: false,
        enabled: !!id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchAllReports = (id) => {
    return useQuery(
        ['master-listing-template-report-listinginginginginging-details-fetch' + id, id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/investors/get-all-reports/${id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data?.data?.data,'values new')
            const savedData = data?.data?.data;
            return savedData
        },
        refetchOnWindowFocus: false,
        enabled: !!id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const DeleteReportByID = () => {
    return useMutation(
        ({ payload, document_id }) => {
            return axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_URL}/reports/delete-report/${document_id} `,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const DeleteReportDocument = () => {
    return useMutation(
        ({ payload, document_id }) => {
            return axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_URL}/reports/delete-document/${document_id} `,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};