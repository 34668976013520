import React from 'react'
import PersonalDetails from './components/PersonalDetails'
import UploadPhoto from './components/UploadPhoto';
import SelectCountry from './components/SelectLocation';
import SelectStateCity from './components/SelectStateCity';
import PreferredCategory from './components/PreferredCategory';
import PreferredStages from './components/PreferredStages';
import InvestmentRange from './components/InvestmentRange';
import LinkedInProfileDetails from './components/ProfileURLDetails';
import WhatsAppNotifications from './components/WhatsAppNotifications';
import StartupInvestments from './components/StartupInvestments';
import SelectLocation from './components/SelectLocation';

const InvestorAngel = ({
    currentStep,
    setCurrentStep,
    investor_type,
    isLoading,
    isSectorAgnostic, setIsSectorAgnostic, isBusinessModelSelectAll, setIsBusinessModelSelectAll,
    handleVerifyOTP,
    resend,
    refetchProfileDetails,
    error,
    setError
}) => {
    switch (currentStep) {
        case 2: return <PersonalDetails investor_type={investor_type} />;
        case 3: return <UploadPhoto />;
        case 4: return <></>;
        case 5: return <></>;
        case 6: return <PreferredCategory isLoading={isLoading} isSectorAgnostic={isSectorAgnostic}
            setIsSectorAgnostic={setIsSectorAgnostic}
            isBusinessModelSelectAll={isBusinessModelSelectAll}
            setIsBusinessModelSelectAll={setIsBusinessModelSelectAll} />;
        case 7: return <InvestmentRange isLoading={isLoading} />;
        case 8: return <PreferredStages />;
        case 9: return <StartupInvestments />;
        case 10: return <WhatsAppNotifications refetchProfileDetails={refetchProfileDetails} isLoading={isLoading} resend={resend} handleVerifyOTP={handleVerifyOTP} setError={setError} error={error} />;
        case 11: return <LinkedInProfileDetails currentStep={currentStep} />;
    }
}

export default InvestorAngel