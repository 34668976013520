import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid } from '@mui/material';
import { useTheme } from '@emotion/react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { B300 } from './CustomButton';

const AlertDialog = ({ open, handleClose, heading, subheading, image = null }) => {
    const theme = useTheme();

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="delete-confirmation-dialog" PaperProps={{ sx: { borderRadius: "20px", width: { xs: '100%', md: '30%' } } }}>
            {image == null ? <CheckCircleOutlineIcon style={{ alignSelf: 'center', marginTop: 10, height: '5rem', width: '5rem', color: theme.palette.customColors.b300 }} /> : <img src={image} style={{alignSelf: 'center', marginTop: 10, height: '5rem', width: '5rem', color: theme.palette.customColors.b300}} />}
            {heading && <DialogTitle id="delete-confirmation-dialog" sx={{ textAlign: 'center', fontWeight: 700, fontSize: '1.5rem', color: theme.palette.customColors.g300, paddingTop: 1 }}>{heading}</DialogTitle>}
            {subheading && <DialogContent>
                <Grid container justifyContent="center">
                    <Typography sx={{ fontSize: '1rem', color: theme.palette.customColors.g200, textAlign: 'center' }}>{subheading}</Typography>
                </Grid>
            </DialogContent>}
            <DialogActions sx={{ justifyContent: 'center', padding: '1.5rem' }}>
                <B300 onClick={handleClose} sx={{ padding: '0.75rem 2.25rem', fontSize: '1rem', backgroundColor: theme.palette.customColors.indigo600 }}>
                    OK
                </B300>
            </DialogActions>
        </Dialog>
    );
};

export default AlertDialog;