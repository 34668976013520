import { useTheme } from "@emotion/react";
import { Add, EditOutlined } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Chip, Grid, IconButton, Menu, MenuItem, Skeleton, Stack, Tooltip, Typography, useMediaQuery } from "@mui/material";
import BreadcrumbHeader from "layout/BreadcrumbsHeader";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import AsyncTableListing from "ui-components/AsyncTableListing";
import { B300 } from "ui-components/CustomButton";
import NeedHelpButton from "ui-components/NeedHelpButton";
import ThemeBGWrapper from "ui-components/ThemeBGWrapper";
import { ReactComponent as MoreOptionsIcon } from "assets/rounds_icons/more_solid_icon.svg";
import { DeletePortfolio, FetchAllPortfolio } from "api/portfolio";
import { useSelector } from "react-redux";
import DeleteConfirmationDialog from "ui-components/popups/DeleteConfirmationPopup";
import CustomToast from "ui-components/CustomToast";
import toast from "react-hot-toast";
import img1 from "assets/redysyn-logo.png"
import Cookies from "js-cookie";


const columns = [
  { Header: 'Startup Name', accessor: 'name', minWidth: 200, align: 'start', fixed: true },
  // { Header: 'Email', accessor: 'email', minWidth: 150, align: 'start' },
  { Header: 'Stage', accessor: 'stage', minWidth: 100, align: 'center' },
  { Header: 'Category', accessor: 'category', minWidth: 120, align: 'center' },
  // { Header: 'Sector', accessor: 'sector', minWidth: 200, align: 'center' },
  { Header: 'Business Model', accessor: 'business_model', minWidth: 200, align: 'center' },
  { Header: 'Investment Amount', accessor: 'total_investment_amount_ref', minWidth: 100, align: 'center' },
  { Header: 'Current Amount', accessor: 'total_current_investment_amount_ref', minWidth: 100, align: 'center' },
  { Header: 'Actions', accessor: 'actions', minWidth: 50, align: 'right' }
];

const data = [
  {
    name: 'Redesyn',
    email: 'puneetmadaan369@gmail.com',
    stage: 'Growth',
    category: 'Technology (CommunityTech)',
    business_model: 'B2C (Business-to-Consumer)',
    // sector: 'CommunityTech',
    investment_amount: '₹25L',
    current_amount: '₹32L',
    image: img1,
    actions: 'View Details',
  },

];





const Portfolio = () => {
  const theme = useTheme();
  const isNotLargeScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const userDetails = useSelector((state) => state?.investorDetails?.data);
  const navigate = useNavigate()
  const { data: portfolioAllValues, isLoading: isLoadingPortfolio, refetch: refetchPortfolioData } = FetchAllPortfolio({ id: userDetails?._id });

  const portfolioData = portfolioAllValues?.data ? portfolioAllValues?.data?.length > 0 ? portfolioAllValues?.data?.map(item => {
    let startup_info = item?.startup_info;
    let business_model_info = (startup_info ? item?.startup_business_model_info?.config_values : item?.business_model_info?.config_values) || [];
    let category_info = (startup_info ? item?.startup_category_type_info?.config_values : item?.category_type_info?.config_values) || null;
    let stage_info = startup_info ? item?.startup_stage_type_info?.config_values?.name : item?.stage_info?.name;
    return {
      ...item,
      // ...startup_info,
      name: startup_info ? startup_info?.startup_name : item?.startup_name,
      email: item?.founder_email,
      stage: stage_info,
      business_model:
        <>
          {(business_model_info && business_model_info?.length > 0
            ? business_model_info?.length > 1
              ?
              <Tooltip title={business_model_info?.map((item) => <span> {item?.name} <br /></span>)}>
                <Chip label={business_model_info[0]?.name + " + " + String(business_model_info?.length - 1)} />
              </Tooltip>
              : business_model_info[0]?.name : '-')}
        </>,
      category:
        <>
          {category_info?.name ? category_info?.name : "-"}
        </>,
      image: startup_info ? startup_info?.startup_logo_path : item?.startup_logo_path,
    }
  }) : [] : [];
  // console.log(portfolioData, "portfolioAllValues", portfolioAllValues, "ddddddddddddddddddd")
  // console.log(portfolioData, "ferfegf")
  const {
    mutateAsync: deletePortfolioAsync,
    isLoading: isdeletingReportDocument,
    status: deleteReportDocumentStatus,
    error: deleteReportDocumentError,
    data: deleteReportDocumentResponse
  } = DeletePortfolio();
  const [currentRow, setCurrentRow] = React.useState(null);

  const [openDeletePopup, setOpenDeletePopup] = React.useState(false);


  const handleOpenDeletePopup = (item) => {
    setOpenDeletePopup(true);
    //   console.log(row,"rrrrr")
    setCurrentRow(item)
  }

  const handleCloseDeletePopup = () => {
    setOpenDeletePopup(false);
    setCurrentRow(null)
  }

  const handleDeletion = ({ values }) => {
    deletePortfolioAsync(({ "portfolio_id": currentRow?._id, }), {
      onSuccess: (succ) => {
        // console.log(succ, 'succ')
        toast.success(
          <CustomToast message={"Notes Deleted!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
          {
            duration: 2000,
            position: "top-right",
          }
        );
        refetchPortfolioData();
        handleCloseDeletePopup();
      },
      onError: (err) => {
        toast.error(
          <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
          {
            duration: 2000,
            position: "top-right",
          }
        );
      }
    });

  }
  // console.log(portfolioData,"listinggg")
  const RowActions = ({ row, iconsColor = theme.palette.customColors.indigo700, index }) => {
    // return <>

    // </>
    // console.log(row, "row")

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event, index) => {
      setAnchorEl(event.currentTarget);
      // setCurrentIndex(index)
      // console.log(event.currentTarget,"re")
    };
    const handleCloseMenu = (event) => {
      // console.log(event,"event")
      setAnchorEl(null);
      // setCurrentIndex(null);
    };

    // switch (value) {
    //     case "new": return <>
    return <>          <IconButton
      sx={{ borderRadius: "4px" }}
      id={`more-details-button-${index}`}
      size="small"
      aria-controls={openMenu ? 'more-details-menu' : undefined}
      aria-haspopup="true"
      aria-expanded={openMenu ? 'true' : undefined}
      onClick={(e) => handleClickMenu(e)}
      centerRipple={false}
    >
      <MoreOptionsIcon />
    </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        aria-controls={openMenu ? 'fade-menu' : 'fade-menu'}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : 'false'}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            padding: "12px",
            borderRadius: "8px",
            "& > ul.MuiList-root": { padding: "0 !important" },
            bgcolor: theme.palette.customColors.g400,
            color: theme.palette.customColors.whiteBG,

          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
      >

        <MenuItem disableRipple sx={{ borderBottom: `0px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => { Cookies.set("portfolio_id", row?.original?._id); navigate(`/portfolio/create_portfolio`); }}>
          {/* <DoneRounded sx={{ height: 20, width: 20, color: iconsColor }} />  */}
          Edit
        </MenuItem>
        <MenuItem disableRipple sx={{ borderBottom: `0px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => { handleOpenDeletePopup(row?.original) }}>
          {/* <DoneRounded sx={{ height: 20, width: 20, color: iconsColor }} />  */}
          Delete
        </MenuItem>
        <MenuItem disableRipple sx={{ borderBottom: `0px solid ${theme.palette.customColors.g700}`, padding: '0.625rem 1rem' }} onClick={() => { Cookies.set("portfolio_id", row?.original?._id); navigate(`/portfolio/create_portfolio?page=1&add=true`); }}>
          {/* <DoneRounded sx={{ height: 20, width: 20, color: iconsColor }} />  */}
          Add Round
        </MenuItem>
      </Menu>
    </>
  }
  const Actions = () => {
    return (
      <B300 onClick={() => { Cookies.remove("portfolio_id"); navigate("/portfolio/create_portfolio"); }} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 600, minWidth: isSmallScreen ? "0" : '64px', '& > .MuiButton-icon.MuiButton-startIcon': { marginRight: isSmallScreen ? 0 : '8px', marginLeft: isSmallScreen ? 0 : '-4px' } }} startIcon={<Add fontSize='1.25rem' />}>
        {!isSmallScreen ? "Add Portfolio" : ''}
      </B300>
    )
  }

  const StartupLink = React.memo(({ row, children }) => {

    // console.log(row?.original?.startup_rounds_info,"row")
    return (
      <Stack onClick={() => {
        // console.log(row,"row")
        // if (row?.original?._id) {
        // Cookies.set("startup_id", row?.original?._id); navigate(`view`)
        // }
      }} style={{ textDecoration: 'none', color: '#616161', fontWeight: 700, cursor: 'pointer' }}>
        {/* <Link to={`view/${row?._id}`} > */}
        {children}
      </Stack>
    )
  })

  return (

    <>
      <DeleteConfirmationDialog open={openDeletePopup} handleClose={handleCloseDeletePopup} handleSubmission={handleDeletion} subheading={"Are you sure you want to delete this portfolio?"} />
      <ThemeBGWrapper>
        <BreadcrumbHeader
          title={"My Portfolio"}
          breadcrumbTitle="Portfolio"
          customBackgroundColor={theme.palette.customColors.indigo25}
          Actions={Actions}
        />
        <Grid item xs={12} container bgcolor={""} padding={"0rem 2rem 0rem 2rem"}>
          <Grid container spacing={{ xs: 2, md: 4 }} mb={2} justifyContent={"center"}>
            <Grid item xs={6} sm={4} md={3}>
              <Card sx={{ textAlign: 'center', padding: '1rem', borderRadius: '8px', boxShadow: 'none', border: `1px solid ${theme.palette.customColors.gray300}` }}>
                <Typography variant="h3">
                  Total Invested
                </Typography>
                <Typography variant="h6" mt={1}>
                  {/* ₹25L */}
                  {isLoadingPortfolio ? <Skeleton height={"2rem"} /> : <>
                    {portfolioAllValues?.total_investment_amount_ref ? portfolioAllValues?.total_investment_amount_ref : '-'}
                  </>}
                </Typography>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} md={3}>
              <Card sx={{ textAlign: 'center', padding: '1rem', borderRadius: '8px', boxShadow: 'none', border: `1px solid ${theme.palette.customColors.gray300}` }}>
                <Typography variant="h3">
                  Current Value
                </Typography>
                <Typography variant="h6" mt={1}>
                  {/* ₹32L */}
                  {isLoadingPortfolio ? <Skeleton height={"2rem"} /> : portfolioAllValues?.total_current_investment_amount_ref ? portfolioAllValues?.total_current_investment_amount_ref : '-'}
                </Typography>

              </Card>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Card sx={{ textAlign: 'center', padding: '1rem', borderRadius: '8px', boxShadow: 'none', border: `1px solid ${theme.palette.customColors.gray300}` }}>
                <Typography variant="h3">
                  Gain
                </Typography>
                <Typography variant="h6" color={String(portfolioAllValues?.total_gain_amount_ref)?.includes("-") ? "red" : 'green'} mt={1}>
                  {/* ₹7L */}
                  {isLoadingPortfolio ? <Skeleton height={"2rem"} /> : portfolioAllValues?.total_gain_amount_ref ? portfolioAllValues?.total_gain_amount_ref : "-"}
                </Typography>
              </Card>
            </Grid>
          </Grid>


          {/* <Grid item xs={12}>  */}
          {isLoadingPortfolio ?
            <Grid item xs={12} height={"100vh"} sx={{ width: '100%', padding: "2rem", paddingLeft: "2rem !important", paddingTop: "0rem !important", bgColor: theme.palette.customColors.yellow_bg }} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }}>
              <Box className='skeleton' minHeight={"60vh"} sx={{ borderRadius: "0 0 8px 8px", border: `1px solid ${theme.palette.customColors.b50}` }} marginTop={0}>

              </Box>
            </Grid> :
            <Box width={"100%"} height="auto" maxWidth={"100%"} overflow={"hidden"}>
              <AsyncTableListing
                columns={columns}
                data={portfolioData}
                // data={data}
                page={page}
                setPage={setPage}
                limit={limit}
                setLimit={setLimit}
                count={portfolioAllValues?.count}
                // count={[]}
                noDataTitle="No Portfolio Available"
                // title={"Investors"}
                // chipText={`${data?.length}${isSmallScreen ? "" : " Investors"}`}
                actions={Actions}
                CustomLink={StartupLink}
                RowActions={RowActions}
                pagination={true}
                sx={{ borderRadius: '8px', border: `1px solid ${theme.palette.customColors.b50}` }}
              // refetchListing={refetchListing}
              />
            </Box>
          }
        </Grid>
        {/* </Grid> */}
      </ThemeBGWrapper>
    </>
  );
};

export default Portfolio;
