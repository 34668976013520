import React from 'react';
import { Autocomplete, TextField, InputAdornment, IconButton, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { useTheme } from '@emotion/react';
import CustomInputField from './formik/CustomInputField';
import SearchIcon from '@mui/icons-material/Search';
import { useField } from 'formik';

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    '& .MuiFilledInput-root': {
        backgroundColor: theme.palette.customColors.whiteBG,
        border: `1px solid ${theme.palette.customColors.g75}`,
        borderRadius: '4px',
        padding: '0px 0px 0px',
        '&:hover': {
            backgroundColor: theme.palette.customColors.whiteBG,
        },
        '&.Mui-focused': {
            backgroundColor: theme.palette.customColors.whiteBG,
            borderColor: theme.palette.customColors.g75,
        },
        '& .MuiFilledInput-input': {
            padding: '13px 15px',
            height: 'auto',
            boxSizing: 'border-box',
        },
    },
    '& .MuiInputBase-input::placeholder': {
        color: theme.palette.customColors.g100,
        opacity: 1,
    },
}));

const CustomAutoComplete = ({ name, options, value, label, placeholder, icon: IconComponent = SearchIcon, search, handleSearchChange, handleSelectionChange, handleSearchUpdate, loading = false, noOptionsText = "Type to Search", multiple = false, labelStyle = {} ,...props }) => {
    const theme = useTheme();

    const [field, mata] = useField(name);

    // console.log("value", value)

    return (
        <div>
            <StyledAutocomplete
                options={options}
                inputValue={search}
                value={value}
                multiple={multiple}
                noOptionsText={noOptionsText}
                onInputChange={(event, value) => handleSearchChange(event, value)}
                onChange={(event, value) => handleSelectionChange(event, value)}
                filterSelectedOptions={(options, { inputValue }) =>
                    options
                }
                renderInput={(params) => (
                    <CustomInputField
                        {...params}
                        label={label}
                        name={name}
                        placeholder={placeholder}
                        labelStyle={labelStyle}
                        variant="filled"
                        InputProps={{
                            disableUnderline: true,
                            ...params.InputProps,
                            endAdornment: (
                                <InputAdornment position="end" sx={{ position: 'absolute', right: '18px' }}>
                                    {loading ? (
                                        <CircularProgress size={24} />
                                    ) : (
                                        props?.disabled ? null : IconComponent != null && <IconButton onClick={() => handleSearchUpdate()} edge="end">
                                            <IconComponent sx={{ width: 24, height: 24 }} />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                {...props}
            />
        </div>
    );
};

export default CustomAutoComplete;
