import { useTheme } from "@emotion/react";
import { Add, EditOutlined, RemoveRedEyeOutlined, Search } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Menu, MenuItem, Tooltip, Typography, useMediaQuery } from "@mui/material";
import BreadcrumbHeader from "layout/BreadcrumbsHeader";
import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AsyncTableListing from "ui-components/AsyncTableListing";
import { B300 } from "ui-components/CustomButton";
import NeedHelpButton from "ui-components/NeedHelpButton";
import ThemeBGWrapper from "ui-components/ThemeBGWrapper";
// import { ReactComponent as MoreOptionsIcon } from "assets/rounds_icons/more_solid_icon.svg";
import ViewMoreIcon from "../../assets/eye.png"
import { FetchAllPortfolio } from "api/portfolio";
import CustomInputField from "ui-components/CustomInputField";
import { DateRangePicker } from "rsuite";
import { makeStyles } from "@mui/styles";
import format from 'date-fns/format';
import Cookies from "js-cookie";


const PNGIconComponent = (({ row }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  return (
    <RemoveRedEyeOutlined style={{ width: 20, color: theme.palette.customColors.indigo600 }} />

  )

})


const columns = [
  { Header: 'Startup Name', accessor: 'name', minWidth: 150, align: 'start', fixed: true },
  { Header: 'Report Name', accessor: 'report_name', minWidth: 200, align: 'start' },
  { Header: 'Duration', accessor: 'duration', minWidth: 150, align: 'center' },
  // { Header: 'Overview', accessor: 'overview', minWidth: 300, align: 'center' },
  { Header: 'View Detail', accessor: 'actions', minWidth: 100, align: 'center' },
];

const reportData = [
  {
    report_name: 'MIS Q1 24',
    name: 'Redesyn',
    image: "https://startups-prod-pub.s3.ap-south-1.amazonaws.com/redesyn/logo.jpeg",
    email: 'puneetmadaan369@gmail.com',
    duration: '01-Mar-2024 - 30-Jun-2024',
    overview: 'Here is the MIS report of quarter 1 of 2024',
  },
  {
    report_name: 'MIS Q2 2024',
    name: 'Redesyn',
    image: 'https://startups-prod-pub.s3.ap-south-1.amazonaws.com/redesyn/logo.jpeg',
    email: 'puneetmadaan369@gmail.com',
    duration: '30-Jul-2024 - 29-Sep-2024',
    overview: 'Here is the MIS report of quarter 1 of 2024',
  }
];

const useStyles = makeStyles((theme) => ({
  scrollTabs: {
    '& > .Mui-disabled.MuiTabs-scrollButtons': {
      display: 'none',
      transition: 'all 1s ease'
    },
  },
  DateRangePicker: {
    '& > .rs-picker-input-group > input': {
      fontSize: "1rem",
      padding: '13.2px 15px',
    },
    '& .rs-input-group:focus-within': {
      borderColor: theme.palette.customColors.b50,
      boxShadow: 'none',
      outline: 'none',
      borderRadius: '4px'


    },
    '& .rs-input-group': {
      borderColor: theme.palette.customColors.b50 + " !important",
      // borderRadius:'8px'
    }


  }
}));






const Reports = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isNotLargeScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate()
  //   const { data: portfolioData, isLoading: isLoadingPortfolio, refetch: refetchPortfolioData } = FetchAllPortfolio();

  const RowActions = ({ row, iconsColor = theme.palette.customColors.indigo700, index }) => {
    // return <>

    // </>
    // console.log(row, "row")

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const handleClickMenu = (event, index) => {
      setAnchorEl(event.currentTarget);
      // setCurrentIndex(index)
      // console.log(event.currentTarget,"re")
    };
    const handleCloseMenu = (event) => {
      // console.log(event,"event")
      setAnchorEl(null);
      // setCurrentIndex(null);
    };

    // switch (value) {
    //     case "new": return <>
    return <> 
    <Tooltip title={'View'}>   
    <IconButton
      // onClick={(e) => handleClickMenu(e)}
      onClick={() => { Cookies.set("reportDetailData", JSON.stringify(row?.original)); navigate("/reports/view") }}

    >
      <PNGIconComponent row={row} />
    </IconButton>
    </Tooltip>

    </>
  }
  //   const Actions  = () => {
  //     return (
  //       <B300 onClick={() => navigate("/portfolio/create_portfolio")} sx={{ bgcolor: theme.palette.customColors.indigo600, border: `1px solid ${theme.palette.customColors.indigo600}`, padding: '10px 16px', fontWeight: 600, minWidth: isSmallScreen ? "0" : '64px', '& > .MuiButton-icon.MuiButton-startIcon': { marginRight: isSmallScreen ? 0 : '8px', marginLeft: isSmallScreen ? 0 : '-4px' } }} startIcon={<Add fontSize='1.25rem' />}>
  //         {!isSmallScreen ? "Add Portfolio" : ''}
  //       </B300>
  //     )
  //   }

  const [search, setSearch] = React.useState("");
  const [searchStatus, setSearchStatus] = React.useState(false);

  const refetchListing = () => {
    // console.log("refetchListing")
  }

  const CustomLink = ({ row, children }) => {
    return <div onClick={() => { Cookies.set("reportDetailData", JSON.stringify(row?.original)); navigate("/reports/view") }}>
      {children}
    </div>
  }

  return (

    <ThemeBGWrapper>
      <BreadcrumbHeader
        title={"My Portfolio"}
        breadcrumbTitle="Portfolio"
        customBackgroundColor={theme.palette.customColors.indigo25}
        // Actions={Actions}
        Actions={() => <NeedHelpButton />}
      />
      <Grid item xs={12} container bgcolor={""} padding={"0rem 2rem 0rem 2rem"} gap={"0.5rem"}>
        {/* <Grid item xs={12}>  */}
        <Grid item container xs={12} gap={"0.5rem"}>
          <Grid item xs={12} sm={5.5} md={5.5} display={"flex"} flexWrap={"nowrap"} gap={"0.5rem"}>
            <CustomInputField
              autoComplete="off"
              sx={{
                marginBottom: 0,
                "& > .MuiFilledInput-root": {
                  borderColor: `${theme.palette.customColors.b50} !important`,
                  borderRadius: '8px'
                }
              }}
              fullWidth
              value={search}
              name={`search_${Math.random().toString(36).substring(7)}`}  // Set a random name attribute
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  // alert(e.target.value);
                  refetchListing()
                  if (search) {
                    setSearchStatus(true)
                  }
                  // setPage(1);
                }
              }}
              onChange={(event) => {
                setSearch(event.target.value)
                // Cookies.set("search_startups", event.target.value);
                setSearchStatus(false)
              }}
              size="small"
              startAdornment={<>
                <Search sx={{ padding: "0rem 0rem 0rem 0.5rem" }} />
              </>}
              placeholder={'Search by name'}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} display={"flex"} flexWrap={"nowrap"} gap={"0.5rem"}>
            <DateRangePicker
              className={`${classes.DateRangePicker}`}
              ranges={[]}
              style={{ width: '100%' }}
              placeholder="Select Date"
              format="dd-MMM-yyyy"
              onChange={(value) => {
                // formik?.setFieldValue(
                //   'duration',
                //   value !== null
                //     ? { from_date: moment(value[0])?.toISOString(), to_date: moment(value[1])?.toISOString() }
                //     : null
                // );
              }}
              value={
                // formik.values?.duration !== null
                //   ? [new Date(formik.values?.duration?.from_date), new Date(formik.values?.duration?.to_date)]
                //   : 
                null
              }
              renderValue={([from_date, to_date]) => {
                return (
                  format(from_date, 'dd-MMM-yyyy') + ' - ' + format(to_date, 'dd-MMM-yyyy')
                );
              }}
            />
            <B300 onClick={() => {
            }} sx={{ padding: ".7rem 1.25rem", ":hover": { bgcolor: theme.palette.customColors.b400 }, border: `0px solid ${searchStatus && (search !== '') ? "black" : "inherit"}`, color: searchStatus && (search !== '') ? theme.palette.customColors.b300 : "white" }}  >{"Search"}</B300>

          </Grid>

        </Grid>
        <Box width={"100%"} height="auto" maxWidth={"100%"} overflow={"hidden"}>
          <AsyncTableListing
            columns={columns}
            data={reportData}
            noDataTitle="No Reports Available"
            // title={"Investors"}
            // chipText={`${data?.length}${isSmallScreen ? "" : " Investors"}`}
            // actions={Actions}
            CustomLink={CustomLink}
            RowActions={RowActions}
            pagination={true}
            sx={{ borderRadius: '8px', border: `1px solid ${theme.palette.customColors.b50}` }}
          // refetchListing={refetchListing}
          />
        </Box>

      </Grid>
      {/* </Grid> */}
    </ThemeBGWrapper>
  );
};

export default Reports;
