import { useTheme } from '@emotion/react'
import { Grid, Box, Typography, Tooltip, IconButton, Divider } from '@mui/material'
import { FieldArray, useFormikContext } from 'formik'
import React, { useState } from 'react'
import CustomLabel from '../../ui-components/CustomLabel'
import CustomValidationText from '../../ui-components/CustomValidationText'
import { investor_types } from 'utils'

const InvestorTypes = ({ isSticky = true }) => {
    const formik = useFormikContext()
    const theme = useTheme()

    const labelStyles = isSticky ? { position: 'sticky', top: "-1px", bgcolor: theme.palette.customColors.yellow_bg, width: '100%' } : {}
    return (
        <>
            <CustomLabel sx={{ marginTop: "0rem", ...labelStyles }}>What describes you best?</CustomLabel>
            <Grid item container xs={12} display={"flex"} gap={0} justifyContent={"space-between"} flexDirection={"row"}>
                <Grid
                    display={"flex"}
                    flexWrap={"wrap"}
                    justifyContent={"center"}
                    item
                    container
                    xs={12}
                    gap={"1rem"}
                >
                    {investor_types?.map((investor, index) => {
                        return (
                            <Grid
                                role={"button"}
                                item
                                xs={12}
                                sm={investor_types?.length % 2 != 0 && index === investor_types?.length - 1 ? 12 : 5.75}
                                md={investor_types?.length % 2 != 0 && index === investor_types?.length - 1 ? 12 : 5.65}
                                lg={investor_types?.length % 2 != 0 && index === investor_types?.length - 1 ? 12 : 5.8}
                                onClick={() => {
                                    formik.setFieldValue(
                                        "investor_type",
                                        investor?.value
                                    );
                                }}
                                sx={{
                                    background:
                                        formik.values.investor_type ==
                                            investor?.value
                                            ? theme?.palette?.customColors?.y50
                                            : theme?.palette?.customColors?.whiteBG,
                                    padding: "12px 16px",
                                    height: 55,
                                    borderRadius: "8px",
                                    display: "grid",
                                    placeItems: "center",
                                    border: `1px solid ${formik.values.investor_type ==
                                        investor?.value
                                        ? theme?.palette?.customColors?.y300
                                        : theme?.palette?.customColors?.g75
                                        }`,
                                    boxShadow: "0px 1px 2px 0px #1018280D",
                                    cursor: "pointer",
                                }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    color={theme.palette.customColors.g200}
                                    fontSize="0.875rem"
                                >
                                    {investor?.label}
                                </Typography>
                            </Grid>
                        );
                    })}
                </Grid>
                {formik.errors.investor_type && <CustomValidationText value={formik.errors.investor_type} style={{ textTransform: 'none' }} />}
            </Grid>
        </>
    )
}

export default InvestorTypes
