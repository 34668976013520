import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as NameTypeIcon } from "../../assets/rounds_icons/name_type_round_icon.svg";
import { ReactComponent as FundUtilizationIcon } from "../../assets/rounds_icons/fund_util_round_icon.svg";
import { ReactComponent as CurrentTractionIcon } from "../../assets/rounds_icons/current_traction_round_icon.svg";
import { ReactComponent as DocumentsIcon } from "../../assets/rounds_icons/documents_round_icon.svg";
import { ReactComponent as SavingIcon } from "../../assets/saving_icon.svg";
import marketplaceIcon from '../../assets/marketplace.png'
import mobileShoppingIcon from '../../assets/mobile-shopping.png'
import digitalServiceIcon from '../../assets/digital-services.png'
import laptopScreenIcon from '../../assets/laptop-screen.png'
import { ReactComponent as UploadDocumentsIcon } from "../../assets/rounds_icons/upload_documents_icon.svg";
import ClearIcon from '../../assets/doc_icons/close_icon.svg'
import { ReactComponent as RoundSizeIcon } from "../../assets/rounds_icons/round_size_val_round_icon.svg";
import {
    Box,
    CircularProgress,
    Divider,
    Grid,
    Typography,
    useMediaQuery,
    IconButton,
} from "@mui/material";
import {
    BLUEBORDER,
    SECONDARY,
    B300 as CustomButton,
    WHITEBG,
} from "../../ui-components/CustomButton";
import { useTheme } from "@emotion/react";
import { FieldArray, Form, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../ui-components/formik/CustomInputField";
import CustomLabel from "../../ui-components/CustomLabel";
import { useNavigate, useSearchParams } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
    UploadRoundDocuments,
    FetchAllStages,
    FetchAllSecurityTypes,
    FetchAllRoundStatus,
    FetchProfileDetails,
    SavePersonalInfo,
    SaveProfileImage,
    SaveStartupInfo,
    SendWhatsappOTP,
    FetchAllInvestmentSize,
    VerifyWhatsAppOTP,
    FetchAllCategories,
    FetchAllCities,
    FetchAllCountries,
    FetchAllCountriesWithPhoneCodes,
    FetchAllStates,
    FetchAllSubCategories,
    FetchAllBusinessModel,
    AddPortfolio
} from "../../api";


import * as Yup from "yup";
import ThemeBGWrapper from "../../ui-components/ThemeBGWrapper";
import toast from "react-hot-toast";
import moment from "moment";
import CustomToast from "../../ui-components/CustomToast";
import { containerRef, getFileIcon, getMarks, handleFormSubmit, isRequired, linkedInTestURL, notEmpty, websiteTestURL } from "../../utils";
import { ReactComponent as InfoIcon } from '../../assets/help-circle.svg';
import CustomValidationText from "../../ui-components/CustomValidationText";
import BreadcrumbHeader from "../../layout/BreadcrumbsHeader";
import CustomAutoComplete from "../../ui-components/CustomAutoComplete";
import { ReactComponent as gpsIcon } from "../../assets/gps.svg"
import placeHolderImage from "../../assets/placeholder_image.png"
import CustomLogoButton from '../../ui-components/formik/CustomLogoButton'
import CustomUrlField from "../../ui-components/CustomUrlField";
import VerticalScrollableTabs from "../../ui-components/VerticalScrollableTabs";
import { useConfig } from "../../dynamicConfiguration/configContext";
import CustomDatePicker from "ui-components/formik/CustomDatePicker";
import { ReactComponent as DeleteIcon } from "../../assets/rounds_icons/delete_icon.svg";
import { ReactComponent as EditIcon } from "../../assets/rounds_icons/edit_icon.svg";
import { Done, KeyboardArrowDownRounded } from "@mui/icons-material";
import styled from "@emotion/styled";
import CustomMenu from "ui-components/CustomMenu";
import { useSelector } from "react-redux";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });


const CreatePortfolio = () => {
    const theme = useTheme();
    const { config } = useConfig()
    const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const matchUpXl = useMediaQuery(theme.breakpoints.up('xl'));
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const userDetails = useSelector((state) => state?.investorDetails?.data);
// console.log(userDetails,"investorrrrrrrrrrrrrr")
    const navigate = useNavigate();

    let stepIndex = new URLSearchParams(window.location.search).get("page");
    const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

    const [searchParams, setSearchParams] = useSearchParams()
    const [currentIndex, setCurrentIndex] = useState(Number(stepIndex) || 0);
    const [amountRanges, setAmountRanges] = useState([])
    const [currency, setCurrency] = useState(null);
    const [currencyOptions, setCurrencyOptions] = useState([]);
    const [selectedRange, setSelectedRange] = useState(null); // Track selected range
    const [allAmountRanges, setAllAmountRanges] = useState([])
    const [selectedStages, setSelectedStages] = useState([])
    const [isWhatsAppEnabled, setIsWhatsAppEnabled] = useState(false);
    const [isPublicEnabled, setIsPublicEnabled] = useState(false);
    const [isVerify, setIsVerify] = useState(false);
    const [otpValues, setOtpValues] = useState(Array(4).fill(''));
    const [error, setError] = useState(null)
    const [countDown, setCountDown] = useState(30);
    const [search, setSearch] = useState("")
    const [searchState, setSearchState] = useState("")
    const [searchCountry, setSearchCountry] = useState("")
    const [currentSubCategory, setCurrentSubCategory] = useState('');
    const [searchSubCategory, setSearchSubCategory] = useState('');
    const [currentStages, setCurrentStages] = useState('');
    const [searchStages, setSearchStages] = useState('');
    const [currentSecurityType, setCurrentSecurityType] = useState('');
    const [searchSecurityType, setSearchSecurityType] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [currentBusinessModel, setCurrentBusinessModel] = React.useState('');
    const [searchBusinessModel, setSearchBusinessModel] = React.useState('');

    const { data: security_types, isRefetching: isRefetchingSecurityTypes, isLoading: isLoadingSecurityTypes, refetch: refetchSecurityTypes } = FetchAllSecurityTypes()
    const { data: profileDetailsData, isRefetching: isProfileDetailsRefetching, isLoading: isProfileDetailsLoading, refetch: refetchProfileDetails } = FetchProfileDetails()
    const { data: investmentSizeList, isRefetching: isRefetchingInvestmentSizeList, isLoading: isLoadingInvestmentSizeList, refetch: refetchInvestmentSizeList } = FetchAllInvestmentSize()
    const { mutateAsync: verifyWhatsAppOTPAsync, isLoading: isOTPLoading, data: verifyWhatsAppOTPResponse } = VerifyWhatsAppOTP();
    const { data: startup_stages, isRefetching: isRefetchingRoundTypes, isLoading: isLoadingRoundTypes, refetch: refetchRoundTypes } = FetchAllStages();
    const { data: countries, isRefetching: isRefetchingCountries, isLoading: isLoadingCountries, refetch: refetchCountries } = FetchAllCountries({ search: searchCountry })
    const { data: startup_categories, isRefetching: isRefetchingCategories, isLoading: isLoadingCategories, refetch: refetchCategories } = FetchAllCategories()
    const { data: startup_sub_categories, isRefetching: isRefetchingSubCategories, isLoading: isLoadingSubCategories, refetch: refetchSubCategories } = FetchAllSubCategories({ category: selectedCategory })
    const { data: startup_business_model, isRefetching: isRefetchingBusinessModel, isLoading: isLoadingBusinessModel, refetch: refetchBusinessModel } = FetchAllBusinessModel({})
    const [isSectorAgnostic, setIsSectorAgnostic] = useState(false);
    const [isBusinessModelSelectAll, setIsBusinessModelSelectAll] = useState(false);
    const {
        mutateAsync: SavePersonalInfoAsync,
        status: savePersonalInfoStatus,
        error: savePersonalInfoError,
        isLoading: isUpdatingsavePersonalInfo,
        data: savePersonalInfoResponse,
    } = SavePersonalInfo();
    const {
        mutateAsync: SaveProfileImageAsync,
        status: saveProfileImageStatus,
        error: saveProfileImageError,
        isLoading: isUpdatingsaveProfileImage,
        data: saveProfileImageResponse,
    } = SaveProfileImage();
    const {
        mutateAsync: SaveStartupInfoAsync,
        status: saveStartupInfoStatus,
        error: saveStartupInfoError,
        isLoading: isUpdatingsaveStartupInfo,
        data: saveStartupInfoResponse,
    } = SaveStartupInfo();
    const {
        mutateAsync: SendWhatsappOTPAsync,
        status: sendWhatsappOTPStatus,
        error: sendWhatsappOTPError,
        isLoading: isUpdatingSendWhatsappOTP,
        data: sendWhatsappOTPResponse,
    } = SendWhatsappOTP();
    const {
        mutateAsync: addPortfolioAsync,
        isLoading: isAddingPortfolio,
        status: createRoundStatus,
        error: addPortfolioError,
        data: addPortfolioResponse
      } = AddPortfolio({investor_id: userDetails?._id});
    //   console.log(userDetails?._id,"hjjbjbjkbk")
    const bottomElement = React.useRef(null);
    const scrollToBottom = () => {

        // Find the first visible validation message
        setTimeout(() => {
          if (bottomElement && bottomElement.current && bottomElement.current.offsetParent !== null) {
            bottomElement.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
        }, 10)
      }

      const validationObj = currentIndex == 0 ?{
        startup_website:Yup.string("Startup website is required").required("Startup website is required").trim().matches(websiteTestURL, "Please enter a valid website URL"),
        startup_name:Yup.string().required("Startup name is required"),
        startup_logo : Yup.mixed()
            .test('file-or-url', "Startup logo is required", function (value) {
                // If value is a file, check its type and size
                if (value && value instanceof File) {
                    const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
                    return validFileTypes.includes(value.type) && value.size <= 10000000; // File type & size check
                }
                // If value is a string, validate it as a URL
                if (typeof value === 'string') {
                    return Yup.string().url().isValidSync(value); // URL validation
                }
                return true; // If no value, it's valid (nullable)
            }).required("Startup logo is required")
      } :{}
      
      const validationSchema = Yup.object({ ...validationObj });



    const formik = useFormik({
        initialValues: 
        // !isProfileDetailsLoading && !isLoadingCountries && profileDetailsData ? profileDetailsData?.data : 
        {
            // step 0
            startup_website:'',
            startup_name:'',
            startup_logo : null,
            sectors:null,
            founder_email:'',
            stages: null,
            custom_image_url: null,
            business_model: [],
            category: null,
            investor_id:userDetails?._id,
            // step 1
            round_name:'',
            investment_date:'',
            syndicate_name:'',
            no_of_units:'',
            per_share_unit:'',
            investment_amount:'',
            current_value_per_unit:'',
            current_investment_value:'',
            security_type:'',
            documents: [
                {
                  title: "Round Document",
                  document: null,
                  editable: false,
                  type: "",
                  // document_type: null,
                },
              ],
            step: 1
        },
        validationSchema: validationSchema,
        // validate: (values) => { console.log(values, "valueseferfe"); console.log(formik.errors, "err") },
        validateOnChange: false,
        validateOnBlur: false,
        validateOnMount: false,
        enableReinitialize: true,
        onSubmit: (values) => {
         const formData = new FormData();
         let request = {}
        //  let currentDocuments = documents?.filter(doc => doc?.document?.size != undefined);
          switch(currentIndex){
            case 0:
                formData.append("startup_website" , values?.startup_website)
                formData.append("investor_id" , values?.investor_id)
                formData.append("startup_name" , values?.startup_name)
                notEmpty(values.founder_email) && formData.append("founder_email" , values?.founder_email)
                formData.append("stages" , values?.stages)
                if(values?.business_model?.length > 0){
                    values?.business_model?.forEach((element, index)=> {
                        formData.append(`business_model[${index}]` , element)
                    })
                }
                formData.append("sectors[0]" , values?.sectors)
                formData.append("category" , values?.category)
                if (values.startup_logo != null) {
                    if (typeof values.startup_logo != 'string') {
                        formData.append("startup_logo", values.startup_logo);
                    }
                } else {
                }
                break;
            case 1:
                formData.append("investor_id" , values?.investor_id)
                notEmpty(values.round_name) && formData.append("rounds[0][round_name]", values.round_name)
                notEmpty(values.investment_date) &&formData.append("rounds[0][investment_date]", values.investment_date)
                notEmpty(values.syndicate_name) &&formData.append("rounds[0][syndicate_name]", values.syndicate_name)
                notEmpty(values.no_of_units) &&formData.append("rounds[0][no_of_units]", values.no_of_units)
                notEmpty(values.per_share_unit) &&formData.append("rounds[0][per_share_unit]", values.per_share_unit)
                notEmpty(values.investment_amount) &&formData.append("rounds[0][investment_amount]", values.investment_amount)
                notEmpty(values.current_value_per_unit) &&formData.append("rounds[0][current_value_per_unit]", values.current_value_per_unit)
                notEmpty(values.security_type) &&formData.append("rounds[0][security_type]", values.security_type)

                if (values?.documents?.length > 0) {
                    values?.documents?.forEach((doc, index) => {
                    //   if (doc?.document?.size) {
                        doc?._id && formData.append(`id[${index}]`, doc?._id);
                        notEmpty(values.doc?.title) && formData.append(`round[${index}][title]`, doc?.title);
                        doc?.document && formData.append('round0', doc?.document);
                        // doc?.document && formData.append(`document_type[${index}]`, doc?.document_type);
                    //   }
                      // else {
                      //   formData.append(`title[${index}]`, doc?.title);
                      //   doc?._id && formData.append(`id[${index}]`, doc?._id);
                      //   // doc?._id && formData.append(`document_type[${index}]`, doc?.document_type);
                      // }
                    });
                  }

          } 
         
                addPortfolioAsync(formData).catch(err => {
                    // console.log(err?.response?.data?.message, "response herer")

                    toast.error(<CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} />, {
                        duration: 4000,
                        position: "top-right",
                        style: {
                            padding: "15px 30px",
                        },
                    });
                });
          
        },
    });



    React.useEffect(() => {
        if (addPortfolioResponse && addPortfolioResponse?.data?.statusCode == 200) {
          window.document
            .getElementById("scrollable-form")
            .scrollTo(0, 0);
    
          if (currentIndex == 1) {
            // toast.success(
            //   <CustomToast message={"Round Details Saved!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />
            //   , 3000)
            setTimeout(() => {
              navigate("/portfolio");
            }, 3000)
          }
          else {
            setCurrentIndex(currentIndex + 1);
            setSearchParams({ page: currentIndex + 1 });
            // console.log(addPortfolioResponse?.data?.data?._id,"addPortfolioResponse?.data?.data?._id")
            addPortfolioResponse?.data?.data?._id && navigate(`/porfolio/${window.location.pathname?.includes("create_portfolio") ? "create_portfolio" : 'edit_portfolio'}/${addPortfolioResponse?.data?.data?._id}`)
            // currentIndex == formik.values?.currentStep && formik.setFieldValue("currentStep", currentIndex + 1);
          }
        }
        else if (addPortfolioResponse && addPortfolioResponse?.data?.statusCode != 200) {
          // toast.error(
          //   <CustomToast message={createRoundError?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
          //   {
          //     duration: 2000,
          //     position: "top-right",
          //   }
          // );
          // toast.error("Internal Server Error")
          // console.log("error", createRoundError)
        }
      }, [addPortfolioResponse?.data?.statusCode]);

    useEffect(() => {
        if (countDown > 0) {
            const timer = setTimeout(() => setCountDown(prev => prev - 1), 1000);
            return () => clearTimeout(timer);
        }
    }, [countDown]);



    const portfolio_steps = [
        {
            name: "STARTUP INFORMATION",
            sub_title: "Provide name, logo, stage and sector",
            image_url: CurrentTractionIcon,
        },
        {
            name: "ROUND INFORMATION",
            sub_title: "Provide round information",
            image_url: RoundSizeIcon,
        },
    ];

    const bottomRef = useRef(null);

    const Actions = () => {
        return (
            <>
                {(isUpdatingsavePersonalInfo) && matchesUpMd && <Typography sx={{ marginRight: "1rem" }} display={"flex"} alignItems={'center'} gap={1} fontSize={"14px"} fontWeight={600} color={theme.palette.customColors.g200}>
                    <SavingIcon width={24} height={24} className="saving-icon" />
                    <span>Saving...</span>
                </Typography>}
                {/* <SECONDARY
                    sx={{
                        background: theme?.palette?.customColors.indigo50,
                        color: theme?.palette?.customColors?.indigo700,
                        fontWeight: 600,
                        fontSize: "14px",
                        padding: "10px 16px",
                        display: { xs: "none", md: 'inline-block' }
                    }}
                >
                    Need help?
                </SECONDARY> */}
                <IconButton sx={{ display: { xs: "inline-block", md: 'none' }, padding: "5px" }}><InfoIcon style={{ width: '1.5rem', height: '1.5rem', display: 'flex', alignItems: 'center', gap: "4px", marginBlock: 'auto', stroke: '#444CE7' }} /></IconButton>
            </>
        );
    };

    useEffect(() => {
        if (savePersonalInfoResponse && savePersonalInfoResponse?.data?.statusCode == 200) {
            if (currentIndex === 1) {
                navigate("/portfolio")
            }
            else {
                setCurrentIndex(currentIndex + 1);
                setSearchParams({ page: currentIndex + 1 });
            }
        }
        window.document.getElementsByClassName("scrollbar-container")[0]?.scroll(0, 0);
    }, [savePersonalInfoResponse?.data?.statusCode]);

    const isLoading = (isProfileDetailsLoading) || isLoadingRoundTypes;
    // Effect to set selected category based on formik values
    useEffect(() => {
        if (!isProfileDetailsLoading && !isLoadingCategories && profileDetailsData?.data && Array.isArray(startup_categories)) {
            const category = startup_categories?.filter(item => formik?.values?.category_id?.includes(item?._id));

            if (category.length) {
                setSelectedCategory(category.map(item => item?.name).join(","));  // Or whichever property you want to use
            }
        }
    }, [isProfileDetailsLoading, isLoadingCategories, profileDetailsData?.data, startup_categories, formik?.values?.category_id]);

    // Effect to refetch subcategories when selectedCategory changes
    useEffect(() => {
        if (!isRefetchingCategories && !isLoadingCategories && selectedCategory) {
            refetchSubCategories();
        }
    }, [selectedCategory, isRefetchingCategories || isLoadingCategories,]);

    React.useEffect(() => {
        if (formik.values.sector_id && startup_sub_categories) {
            const matchedSubCategory = startup_sub_categories.find(subCat => subCat._id === formik.values.sector_id);
            if (matchedSubCategory) {
                setCurrentSubCategory({ label: matchedSubCategory?.sub_category, value: matchedSubCategory?._id, description: matchedSubCategory?.description });
            }
        }
    }, [formik.values.sector_id, startup_sub_categories]);

    // console.log(formik,"ffff")

    const handleSearchSubCategoryChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchSubCategory(value);
        // refetchCities();
    };
    const handleSelectionSubCategoryChange = (event, sector) => {
        // console.log(value, "value")
        formik.setFieldValue("sector_id", sector ? sector?.value : '');
        setCurrentSubCategory(sector)
        event.preventDefault();
    };
    const handleSearchUpdateSubCategory = () => {
        refetchSubCategories();
    }
    React.useEffect(() => {
        if (formik.values.stage_id && startup_stages) {
            const matchedSubCategory = startup_stages.find(stage => stage._id === formik.values.stage_id);
            if (matchedSubCategory) {
                setCurrentStages({ label: matchedSubCategory?.name, value: matchedSubCategory?._id });
            }
        }
    }, [formik.values.stage_id, startup_stages]);

    const handleSearchStagesChanges = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchStages(value);
        // refetchCities();
    };
    const handleSelectionStageChange = (event, stage) => {
        // console.log(stage, "value")
        formik.setFieldValue("stage_id", stage ? stage?.value : '');
        setCurrentStages(stage)
        event.preventDefault();
    };
    const handleSearchUpdateStages= () => {
        refetchRoundTypes();
    }
    React.useEffect(() => {
        if (formik.values.security_type && security_types) {
            const matchedSubCategory = security_types.find(security => security._id === formik.values.security_type);
            if (matchedSubCategory) {
                setCurrentSecurityType({ label: matchedSubCategory?.name, value: matchedSubCategory?._id });
            }
        }
    }, [formik.values.security_type, security_types]);

    const handleSearchSecurityTypeChanges = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchSecurityType(value);
        // refetchCities();
    };
    const handleSelectionSecurityTypeChange = (event, security) => {
        // console.log(security, "value")
        formik.setFieldValue("security_type", security ? security?.value : '');
        setCurrentSecurityType(security)
        event.preventDefault();
    };
    const handleSearchUpdateSecurityType= () => {
        refetchSecurityTypes();
    }
    

    React.useEffect(() => {
        if (!isLoading && !isLoadingBusinessModel && formik?.values && Array.isArray(startup_business_model) && Array.isArray(formik?.values.business_model_id)) {
            const businessModel = startup_business_model?.filter(item => formik?.values?.business_model_id?.includes(item?._id));
            if (businessModel.length) {
                setCurrentBusinessModel(
                    businessModel.map(item => ({
                        label: item?.name,
                        value: item?._id,
                        description: item?.description,
                    }))
                );
            }
        }
    }, [isLoading, isLoadingBusinessModel, formik?.values, startup_business_model, formik?.values?.business_model_id]);

    const handleSearchBusinessModelChange = (event, value) => {
        // console.log(event.target.value, value,"ca")
        setSearchBusinessModel(value);
        // refetchCities();
    };
    const handleSelectionBusinessModelChange = (event, businessModel) => {
        formik.setFieldValue("business_model_id", businessModel ? businessModel?.map(item => item?.value) : []);
        setCurrentBusinessModel(businessModel)
        event.stopPropagation();
        // console.log(businessModel ,"businessModelbusinessModelbusinessModel")
    };
    const handleSearchUpdateBusinessModel = () => {
        refetchBusinessModel();
    }
    const availableOptionsBusinessModel = startup_business_model?.filter(
        item =>
            !(currentBusinessModel || []).some(selected => selected.value === item._id)
    ) || [];
    
    const handleFileChange = (e, index) => {
        const file = e.target.files[0];
        formik.setFieldValue(`documents.${index}.document`, file);
      };
    
    React.useEffect(() => {
        !isLoading && profileDetailsData?.data?.sector_type === "All" && setIsSectorAgnostic(true) && profileDetailsData?.data?.business_model_type === "All" && setIsBusinessModelSelectAll(true)
    }, [isLoading])
    const handleAddDocument = () => {
        const defaultDocument = {
        title: "Document Name", 
        document: null,
        editable: false,
        type: "",
        };
    
        formik.setFieldValue("documents", [
        ...formik.values?.documents,
        defaultDocument,
        ]);
    
        scrollToBottom();
    };

  
    return (
        <ThemeBGWrapper>
            <BreadcrumbHeader
                title={isProfileDetailsLoading ? "Loading..." : `Add`}
                breadcrumbTitle="Add"
                Actions={Actions}
            />
            {isLoading ?
                <Grid container xs={12} display={"flex"} height={"100%"} maxHeight={"85vh"} sx={{ padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 2rem 1rem 2rem" }, width: '100%' }}>
                    <Grid item xs={12} className="skeleton" height={"100%"} width={"100%"} borderRadius={"24px"}>

                    </Grid>
                </Grid> :
                <>
                    <Grid
                        container
                        xs={12}
                        sx={{
                            // height: "calc(100vh - 118px)",
                            height: '100%',
                            maxHeight: { xs: "90%", sm: "93%", md: '85vh' },
                            overflow: "hidden",
                            padding: { xs: "0 1rem", sm: '0 1rem 1rem 1rem', md: "0 2rem 1rem 2rem" },
                        }}
                    >
                        <Grid
                            xs={12}
                            item
                            container
                            sx={{
                                backgroundColor: "#FFFAF5",
                                borderRadius: { xs: "24px", sm: "24px" },
                                height: "100%",
                            }}
                        >
                            <Grid
                                item
                                sx={{
                                    position: "relative",
                                    backgroundColor: "#ADA2ED",
                                    borderRadius: `24px ${currentIndex === 0 ? "0" : "24px"} 24px 24px`,
                                    overflow: "hidden",
                                    height: "100%",
                                    width: { xs: '0%', md: '40%', lg: '40%', xl: '37.9%' },
                                }}
                            >
                                <VerticalScrollableTabs data={portfolio_steps} currentIndex={currentIndex} setCurrentIndex={setCurrentIndex} setSearchParams={setSearchParams} />
                            </Grid>
                            <Grid
                                item
                                container
                                sx={{ width: { xs: "100%", md: '60%', lg: '60%', xl: '62.1%' }, padding: { xs: '1rem', md: '2rem', lg: "2.5rem" } }}
                                gap={3}
                                marginTop={0}
                                display={"flex"}
                                alignItems={"flex-start"}
                                alignContent={"space-around"}
                                justifyContent={"space-between"}
                                // padding={"2.5rem"}
                                paddingBottom={0}
                            >
                                <FormikProvider value={formik}>
                                    <Form
                                        onSubmit={(event) => event.preventDefault()}
                                        style={{ width: "100%", height: "90%", maxHeight: matchUpXl ? "calc(100vh - 295px)" : matchUpLg ? "calc(100vh - 290px)" : matchesUpMd ? "calc(100vh - 285px)" : "calc(-220px + 100vh)", overflow: "hidden", paddingBottom: '1rem' }}
                                    >
                                        {!matchesUpMd &&
                                            <Box width={"100%"}>
                                                {portfolio_steps &&
                                                    portfolio_steps?.length > 0 ?
                                                    <>
                                                        <Typography marginInline={"auto"} flexWrap={"wrap"} variant="h6"
                                                            fontSize="1rem"
                                                            color={theme.palette.customColors.g300}
                                                            fontWeight={700} textAlign={"center"} marginBottom={1}>
                                                            <span>{portfolio_steps[currentIndex]?.name} ({`${currentIndex + 1}/${portfolio_steps?.length}`})</span>
                                                            <Divider sx={{ marginTop: 1, marginBottom: 2, marginInline: 'auto', maxWidth: '50%' }} />
                                                        </Typography>
                                                    </>
                                                    : ''}
                                            </Box>
                                        }
                                        <PerfectScrollbar
                                            ref={bottomRef}
                                            containerRef={containerRef}
                                            style={{
                                                minHeight: currentIndex == 5 ? "56vh" : "60vh",
                                                height: "100%",
                                                width: "98%",
                                                maxHeight: currentIndex == 5 ? "calc(-330px + 100vh)" : "calc(100vh - 285px)",
                                                paddingRight: "1rem",
                                                marginTop: 0,
                                                paddingBottom: "1rem",
                                                // paddingLeft: "1rem",
                                                overflow: 'hidden',
                                                marginInline: 'auto'
                                            }}
                                            className="scrollbar-container"
                                            id="scrollable-form"
                                        >
                                            <Grid
                                                item
                                                container
                                                xs={12}
                                                paddingBottom={{ xs: 1, md: currentIndex == 0 || currentIndex == 7 ? 2 : 0 }}
                                                // sx={{ width: "100%" }}
                                                justifyContent={"space-between"}
                                                columnSpacing={"0.5rem"}
                                            >
                                                {currentIndex == 0 && (
                                                    <>
                                                        <Grid item xs={12} gap={0} position="relative">
                                                            <CustomInputField
                                                                label={"STARTUP WEBSITE URL"}
                                                                name={'startup_website'}
                                                                value={formik?.values?.startup_website}
                                                                placeholder={"Startup Website url"}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} gap={0} sx={{ overflowX: "hidden", marginBottom: "1rem" }}>
                                                            {/* {console.log(formik.errors,"rerrr")} */}
                                                            <Grid item xs={12} sm={12}>
                                                                <CustomLogoButton label={"Logo"} handleUpdateFile={(file) => { formik.setFieldValue("startup_logo", file) }} name={"startup_logo"} value={formik.values.startup_logo } placeholder={placeHolderImage} Pheight={100} Pwidth={100} />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid height={"min-content"} item xs={12} sm={6}>
                                                            <CustomInputField name={`startup_name`} label={"STARTUP NAME"} placeholder={"Enter startup name"} value={formik.values?.startup_name} onChange={(e) => formik.setFieldValue(`startup_name`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                        </Grid>
                                                        <Grid height={"min-content"} item xs={12} sm={6}>
                                                            <CustomInputField name={`founder_email`} label={<>Founder Email <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span></>} placeholder={"Enter founder email"} value={formik.values?.founder_email} onChange={(e) => formik.setFieldValue(`founder_email`, e.target.value)} fullWidth />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <CustomAutoComplete 
                                                             options={!isLoadingRoundTypes && !isRefetchingRoundTypes && Array.isArray(startup_stages) ? startup_stages?.map(item => ({ label: item?.name, value: item?._id})) : []}
                                                             label={<>Select Stages <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span></>}
                                                             name={"stages"}
                                                             noOptionsText={!isLoadingRoundTypes && !isRefetchingRoundTypes ? "No Options" : "Searching..."}
                                                             placeholder="Type a stage name"
                                                             icon={null}
                                                             handleSearchChange={handleSearchStagesChanges}
                                                             handleSelectionChange={handleSelectionStageChange}
                                                             search={searchStages}
                                                             renderOption={(props, option) => (
                                                                 <li {...props} style={{ display: 'grid' }}>
                                                                     <span style={{ fontWeight: 500 }}>{option?.label}</span>
                                                                     {/* <p style={{ fontSize: '0.8rem' }}>{option?.description}</p> */}
                                                                     {/* {option.isSpecial && <span className="special-badge">Special</span>} */}
                                                                 </li>
                                                             )}
                                                             value={currentStages}
                                                             defaultValue={startup_stages?.find(item => item?._id == formik?.values?.stage_id)}
                                                             loading={isRefetchingRoundTypes}
                                                             handleSearchUpdate={handleSearchUpdateStages}
                                                            />                                                    
                                                        </Grid>
                                                        <Grid item xs={12} paddingBottom={0} paddingRight={1} marginTop={'0rem'} >
                                                                <CustomLabel sx={{ marginTop: "0rem" }}>Select Category <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span> {formik.errors?.category_id && (
                                                                    <CustomValidationText value={formik.errors?.category_id} style={{ textTransform: 'none' }} />
                                                                )}</CustomLabel>
                                                            </Grid>
                                                            <FieldArray
                                                                name="category_id"
                                                                render={arrayHelpers => (
                                                                    <>
                                                                        <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap',paddingBottom: 1, justifyContent: 'space-between' }} gap={2}>
                                                                            {Array.isArray(startup_categories) && startup_categories?.map((category, index) => {
                                                                                const isSelected = formik?.values?.category_id?.includes(category?._id);
                                                                                return (
                                                                                    <Grid
                                                                                        item
                                                                                        xs={12}
                                                                                        sm={5.8}
                                                                                        md={5.75}
                                                                                        style={{
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            gap: 0,
                                                                                            background: isSelected ? "#FFFAE6" : "#FFF",
                                                                                            fontSize: "0.875rem",
                                                                                            border: `1px solid ${isSelected ? "#FFC900" : "#A1A3A7"}`,
                                                                                            borderRadius: "8px",
                                                                                            paddingRight: "1rem",
                                                                                            paddingLeft: "1rem",
                                                                                            paddingBlock: '1.5rem',
                                                                                            height: "2.5rem",
                                                                                            cursor: "pointer",
                                                                                            textTransform: 'none',
                                                                                            color: 'inherit',
                                                                                        }}
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            formik.setFieldValue("category_id", category?._id)
                                                                                            setSelectedCategory(category?.name);
                                                                                            formik.setFieldValue("sector_id", '');
                                                                                            setCurrentSubCategory('')
                                                                                        
                                                                                        }}
                                                                                        key={category?._id}
                                                                                    >
                                                                                    

                                                                                            {index === 0 && (
                                                                                            <img
                                                                                                src={laptopScreenIcon}
                                                                                                style={{ height: '1.6rem', width: '1.6rem', marginRight: '0.5rem' }}
                                                                                                alt="icon"
                                                                                            />
                                                                                        )}
                                                                                        {index === 1 && (
                                                                                            <img
                                                                                                src={mobileShoppingIcon}
                                                                                                style={{ height: '1.4rem', width: '1.4rem', marginRight: '0.5rem' }}
                                                                                                alt="icon"
                                                                                            />
                                                                                        )}
                                                                                        {index === 2 && (
                                                                                            <img
                                                                                                src={marketplaceIcon}
                                                                                                style={{ height: '1.4rem', width: '1.4rem', marginRight: '0.5rem' }}
                                                                                                alt="icon"
                                                                                            />
                                                                                        )}
                                                                                        {index === 3 && (
                                                                                            <img
                                                                                                src={digitalServiceIcon}
                                                                                                style={{
                                                                                                    height: '1.4rem',
                                                                                                    width: '1.4rem',
                                                                                                    marginRight: '0.5rem',
                                                                                                    transform: 'rotateY(180deg)',
                                                                                                }}
                                                                                                alt="icon"
                                                                                            />
                                                                                        )}

                                                                                        <span> {category?.name} </span>
                                                                                    </Grid>
                                                                                );
                                                                            })}
                                                                        </Grid>
                                                                        {formik.errors?.category_id && (
                                                                            <CustomValidationText value={formik.errors?.category_id} style={{ textTransform: 'none' }} />
                                                                        )}
                                                                    </>
                                                                )}
                                                            />
                                                            <Grid item xs={12} mt={'1rem'}>
                                                            <CustomAutoComplete
                                                            options={!isLoadingSubCategories && !isRefetchingSubCategories && Array.isArray(startup_sub_categories) ? startup_sub_categories?.map(item => ({ label: item?.sub_category, value: item?._id, description: item?.description })) : []}
                                                            label={<>Select Sector <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span></>}
                                                            name={"sector_id"}
                                                            noOptionsText={!isLoadingSubCategories && !isRefetchingSubCategories ? "No Options" : "Searching..."}
                                                            placeholder="Type a sector name"
                                                            icon={null}
                                                            handleSearchChange={handleSearchSubCategoryChange}
                                                            handleSelectionChange={handleSelectionSubCategoryChange}
                                                            search={searchSubCategory}
                                                            renderOption={(props, option) => (
                                                                <li {...props} style={{ display: 'grid' }}>
                                                                    <span style={{ fontWeight: 600 }}>{option.label}</span>
                                                                    <p style={{ fontSize: '0.8rem' }}>{option?.description}</p>
                                                                    {/* {option.isSpecial && <span className="special-badge">Special</span>} */}
                                                                </li>
                                                            )}
                                                            value={currentSubCategory}
                                                            defaultValue={startup_sub_categories?.find(item => item?._id == formik?.values?.sector_id)}
                                                            loading={isRefetchingSubCategories}
                                                            handleSearchUpdate={handleSearchUpdateSubCategory}
                                                            />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                            <CustomAutoComplete
                                                                options={!isLoadingBusinessModel && !isRefetchingBusinessModel && Array.isArray(availableOptionsBusinessModel) ? availableOptionsBusinessModel?.map(item => ({ label: item?.name, value: item?._id, description: item?.description })) : []}
                                                                label={<>SELECT BUSINESS MODEL (MULTI SELECT) <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span></>}
                                                                name={"business_model_id"}
                                                                multiple={true}
                                                                noOptionsText={!isLoadingBusinessModel && !isRefetchingBusinessModel ? "No Options" : "Searching..."}
                                                                placeholder="Business model"
                                                                icon={null}
                                                                handleSearchChange={handleSearchBusinessModelChange}
                                                                handleSelectionChange={handleSelectionBusinessModelChange}
                                                                search={searchBusinessModel}
                                                                renderOption={(props, option) => (
                                                                    <li {...props} style={{ display: 'grid' }}>
                                                                        <span style={{ fontWeight: 600 }}>{option.label}</span>
                                                                        <p style={{ fontSize: '0.8rem' }}>{option?.description}</p>
                                                                        {/* {option.isSpecial && <span className="special-badge">Special</span>} */}
                                                                    </li>
                                                                )}
                                                                value={currentBusinessModel || []}
                                                                defaultValue={startup_business_model?.filter(item => item?._id == formik?.values?.business_model_id )}
                                                                loading={isRefetchingBusinessModel}
                                                                handleSearchUpdate={handleSearchUpdateBusinessModel}
                                                            />
                                                        </Grid>
                                                        
                                                        
                                                    </>
                                                )}
                                                {currentIndex == 1 &&(
                                                    <>
                                                    <Grid item xs={12} marginTop={0} marginBottom={1.5}>
                                                    <CustomLabel
                                                        sx={{
                                                        fontSize: "1rem !important",
                                                        fontWeight: "500 !important",
                                                        }}
                                                    >
                                                        ROUND DETAILS
                                                    </CustomLabel>
                                                    </Grid>
                                                    <Grid height={"min-content"} item xs={12} sm={6}>
                                                            <CustomInputField name={`round_name`} label={"Round NAME"} placeholder={"Enter round name"} value={formik.values?.round_name} onChange={(e) => formik.setFieldValue(`round_name`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                        </Grid>
                                                        <Grid height={"min-content"} item xs={12} sm={6}>
                                                        <CustomDatePicker
                                                            fullWidth={true}
                                                            name="investment_date"
                                                            label="Investment Date"
                                                            value={moment(formik.values?.investment_date)}
                                                            placeholder="Select Date"
                                                            sx={{
                                                            background: "#fff",
                                                            borderColor: 'rgba(0, 0, 0, 0.23)',
                                                            borderRadius: '4px',
                                                            margin: 0,
                                                            width: "100%",
                                                            padding:"14px 14px",
                                                            }}
                                                            format={"DD/MM/YYYY"}
                                                        />
                                                        </Grid>
                                                        <Grid height={"min-content"} item xs={12} sm={6}>
                                                            <CustomInputField name={`syndicate_name`} label={"Platform/Syndicate Name"} placeholder={"Enter syndicate name"} value={formik.values?.syndicate_name} onChange={(e) => formik.setFieldValue(`syndicate_name`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                        </Grid>
                                                        <Grid height={"min-content"} item xs={12} sm={6}>
                                                            <CustomAutoComplete 
                                                            options={!isLoadingSecurityTypes && !isRefetchingSecurityTypes && Array.isArray(security_types) ? security_types?.map(item => ({ label: item?.name, value: item?._id})) : []}
                                                            label={<>Select Security Type <span style={{ color: theme?.palette?.customColors?.g100, textTransform: "none" }}>(Optional)</span></>}
                                                            name={"security_type"}
                                                            noOptionsText={!isLoadingSecurityTypes && !isRefetchingSecurityTypes ? "No Options" : "Searching..."}
                                                            placeholder="Type a security"
                                                            icon={null}
                                                            handleSearchChange={handleSearchSecurityTypeChanges}
                                                            handleSelectionChange={handleSelectionSecurityTypeChange}
                                                            search={searchSecurityType}
                                                            renderOption={(props, option) => (
                                                                <li {...props} style={{ display: 'grid' }}>
                                                                    <span style={{ fontWeight: 500 }}>{option?.label}</span>
                                                                    {/* <p style={{ fontSize: '0.8rem' }}>{option?.description}</p> */}
                                                                    {/* {option.isSpecial && <span className="special-badge">Special</span>} */}
                                                                </li>
                                                            )}
                                                            value={currentSecurityType}
                                                            defaultValue={security_types?.find(item => item?._id == formik?.values?.security_type)}
                                                            loading={isRefetchingSecurityTypes}
                                                            handleSearchUpdate={handleSearchUpdateSecurityType}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} marginTop={1.5} marginBottom={1.5}>
                                                        <CustomLabel
                                                            sx={{
                                                            fontSize: "1rem !important",
                                                            fontWeight: "500 !important",
                                                            }}
                                                        >
                                                            Investment Details
                                                        </CustomLabel>
                                                        </Grid>
                                                        <Grid height={"min-content"} item xs={12} sm={6}>
                                                            <CustomInputField  name={`units`} label={"No. of Shares/Units"} placeholder={"Enter units"} value={formik.values?.units} onChange={(e) => formik.setFieldValue(`units`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                        </Grid>
                                                        <Grid height={"min-content"} item xs={12} sm={6}>
                                                            <CustomInputField  name={`value_units`} label={"Value Per Share/Unit"} placeholder={"Enter value per units"} value={formik.values?.value_units} onChange={(e) => formik.setFieldValue(`value_units`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                        </Grid>
                                                        <Grid height={"min-content"} item xs={12} sm={6}>
                                                            <CustomInputField  name={`investment_amount`} label={"Investment Amount"} placeholder={"Enter investment amount"} value={formik.values?.investment_amount} onChange={(e) => formik.setFieldValue(`investment_amount`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                        </Grid>
                                                        <Grid height={"min-content"} item xs={12} sm={6}>
                                                            <CustomInputField  name={`current_value`} label={"Current Value Per Share/Unit"} placeholder={"Enter current value per units"} value={formik.values?.current_value} onChange={(e) => formik.setFieldValue(`current_value`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                        </Grid>
                                                        <Grid height={"min-content"} item xs={12} sm={6}>
                                                            <CustomInputField  name={`current_investment_value`} label={"Current Investment Value"} placeholder={"Enter current investment value"} value={formik.values?.current_investment_value} onChange={(e) => formik.setFieldValue(`current_investment_value`, e.target.value?.charAt(0)?.toUpperCase() + e.target.value?.slice(1))} fullWidth />
                                                        </Grid>
                                                        <Grid item xs={12} marginTop={1.5} marginBottom={0}  display={"flex"} 
                                                        justifyContent={"space-between"}
                                                        alignItems={"center"}
                                                        // rowGap={1}
                                                        >
                                                        <CustomLabel
                                                            sx={{
                                                            fontSize: "1rem !important",
                                                            fontWeight: "500 !important",
                                                            }}
                                                        >
                                                            Documents
                                                        </CustomLabel>
                                                        <WHITEBG
                                                            sx={{
                                                            border: `1px solid ${theme.palette.customColors.g100}`,
                                                            borderRadius: "8px",
                                                            }}
                                                            onClick={handleAddDocument} 
                                                        >
                                                            Add Document
                                                        </WHITEBG>
                                                        </Grid>
                                                        <Grid item container gap={1} marginTop={2} paddingBottom={3}>
                                                        <FieldArray
                                                            name="documents"
                                                            key={"documents"}
                                                            render={(arrayHelpers) => (
                                                            <>
                                                                {formik.values?.documents &&
                                                                formik.values?.documents?.length > 0 &&
                                                                formik.values?.documents?.map(
                                                                    (document, index) => {
                                                                    return (
                                                                        <>
                                                                        <Grid
                                                                            item
                                                                            xs={12}
                                                                            display={"flex"}
                                                                            alignItems={"center"}
                                                                            justifyContent={"space-between"}
                                                                            spacing={1}
                                                                            position={"relative"}
                                                                            key={index}
                                                                        >
                                                                            <Grid
                                                                            item
                                                                            xs={12}
                                                                            display={"flex"}
                                                                            alignItems={"center"}
                                                                            justifyContent={"space-between"}
                                                                            sx={{
                                                                                background:
                                                                                theme?.palette.customColors
                                                                                    ?.whiteBG,
                                                                                padding: "0.8rem 1rem",
                                                                                borderRadius: "8px",
                                                                                border: `1px solid ${theme.palette.customColors.g75}`,
                                                                            }}
                                                                            >
                                                                            <Grid
                                                                                display={"flex"}
                                                                                alignItems={"center"}
                                                                                gap={1}
                                                                            >
                                                                                {document?.editable ? (
                                                                                <CustomInputField
                                                                                    sx={{
                                                                                    margin: 0,
                                                                                    "& .MuiFilledInput-input": {
                                                                                        padding:
                                                                                        "8px 15px !important",
                                                                                    },
                                                                                    }}
                                                                                    name={`documents.${index}.title`}
                                                                                    value={document?.title}
                                                                                    onChange={(e) =>
                                                                                    formik.setFieldValue(
                                                                                        `documents.${index}.title`,
                                                                                        e.target.value
                                                                                    )
                                                                                    }
                                                                                />
                                                                                // !formik.values?.documents?.find(item => item?.title == "Pitch Deck Round")
                                                                                ) :
                                                                                (
                                                                                    <Typography variant="body1">
                                                                                    {document?.title}
                                                                                    </Typography>
                                                                                )
                                                                                //  :(
                                                                                //   <CustomValidationText value="Pitch Deck Round is already exists"/>
                                                                                //  )) 
                                                                                }
                                                                                {document?.editable ? (
                                                                                <>
                                                                                    {document?.title != "Pitch Deck Round" && <Done
                                                                                    sx={{ cursor: "pointer" }}
                                                                                    onClick={async () => {
                                                                                        // {
                                                                                        //   document?._id && await updateRoundDocumentTitleAsync({
                                                                                        //     "document_id": document?._id,
                                                                                        //     "title": document?.title
                                                                                        //   })
                                                                                        // }
                                                                                        formik.setFieldValue(
                                                                                        `documents.${index}.editable`,
                                                                                        false
                                                                                        );
                                                                                    }}
                                                                                    />}
                                                                                </>
                                                                                ) : (document?.title != "Pitch Deck Round" &&
                                                                                <EditIcon
                                                                                    style={{
                                                                                    cursor: "pointer",
                                                                                    width: 20,
                                                                                    height: 20,
                                                                                    }}
                                                                                    onClick={() => {
                                                                                    formik.setFieldValue(
                                                                                        `documents.${index}.editable`,
                                                                                        true
                                                                                    );
                                                                                    }}
                                                                                />
                                                                                )}
                                                                            </Grid>

                                                                            {/* {console.log("ffffff", formik.values?.documents)} */}
                                                                            <Grid
                                                                                sx={{
                                                                                display: "flex",
                                                                                alignItems: "center",
                                                                                gap: "1rem",

                                                                                }}
                                                                            >
                                                                                {(document?.document || document?._id) && (
                                                                                <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                                                                    <img
                                                                                    style={{ width: "36px", height: '36px' }}
                                                                                    src={getFileIcon(
                                                                                        document?.document?.name || document?.filename)}
                                                                                    />
                                                                                    {document?.document && <img style={{ position: 'absolute', top: 0, right: 0, height: 16, width: 16, cursor: 'pointer' }} src={ClearIcon} onClick={() => {
                                                                                    formik.setFieldValue(
                                                                                        `documents.${index}.document`,
                                                                                        null
                                                                                    );
                                                                                    }} />}
                                                                                </Box>
                                                                                )}
                                                                                {/* {console.log(document?.document_type, "feferfefef")} */}
                                                                                {/* {(!document?._id) && */}
                                                                                <WHITEBG
                                                                                component="label"
                                                                                role={undefined}
                                                                                tabIndex={-1}
                                                                                sx={{
                                                                                    minWidth: "0 !important",
                                                                                    border: `1px solid #D0D5DD !important`,
                                                                                }}
                                                                                >
                                                                                <UploadDocumentsIcon
                                                                                    style={{
                                                                                    width: 20,
                                                                                    height: 20,
                                                                                    stroke: "#344054"
                                                                                    }}
                                                                                />

                                                                                <VisuallyHiddenInput
                                                                                    accept=".pdf,.png,.jpeg,.jpg,.mp4,.mov,.xlsx,.csv,.xls,.doc,.docx"
                                                                                    type="file"
                                                                                    name={`documents.${index}.document`}
                                                                                    id={`documents.${index}.document`}
                                                                                    onChange={(e) =>
                                                                                    handleFileChange(e, index)
                                                                                    }
                                                                                />
                                                                                </WHITEBG>
                                                                                {/* } */}

                                                                            </Grid>
                                                                            </Grid>
                                                                            {formik.values?.documents?.length > 1 && (
                                                                            matchesUpMd ?
                                                                                <Grid item ml={1}>
                                                                                {/* <Button sx={{ padding: '14px 15px', background: "white", marginBlock: "8px 16px", border: `1px solid ${theme?.palette?.customColors?.g75}` }}><DeleteIcon /></Button> */}
                                                                                <BLUEBORDER
                                                                                    sx={{
                                                                                    padding: "18px 15px",
                                                                                    background: "white",
                                                                                    margin: 0,
                                                                                    border: `1px solid ${theme?.palette?.customColors?.g75}`,
                                                                                    }}
                                                                                    onClick={() => {
                                                                                    if (window.confirm("Are you sure you want to delete this document?") == true) {
                                                                                        if (document?._id) {
                                                                                        //   deleteRoundDocumentAsync({ payload: { document_id: document?._id }, round_id: roundID }, {
                                                                                        //     onSuccess: (succ) => {
                                                                
                                                                                        //       arrayHelpers?.remove(index);
                                                                                        //       toast.success(
                                                                                        //         <CustomToast message={"Document Deleted!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                                                                                        //         {
                                                                                        //           duration: 2000,
                                                                                        //           position: "top-right",
                                                                                        //         }
                                                                                        //       );
                                                                                        //       refetchRoundDetails();
                                                                                        //     },
                                                                                        //     onError: (err) => {
                                                                                        //       toast.error(
                                                                                        //         <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                                                                                        //         {
                                                                                        //           duration: 2000,
                                                                                        //           position: "top-right",
                                                                                        //         }
                                                                                        //       );
                                                                                        //     }
                                                                                        //   });
                                                                                        }
                                                                                        else {
                                                                                        arrayHelpers?.remove(index)
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        return;
                                                                                    }

                                                                                    }
                                                                                    }
                                                                                >
                                                                                    <DeleteIcon />
                                                                                </BLUEBORDER>
                                                                                </Grid>
                                                                                : <img style={{ position: 'absolute', top: "-0.5rem", right: "-0.5rem", height: "1.2rem", width: "1.2rem", cursor: 'pointer' }} src={ClearIcon} onClick={() => {
                                                                                if (window.confirm("Are you sure you want to delete this document?") == true) {
                                                                                    if (document?._id) {
                                                                                    //   deleteRoundDocumentAsync({ document_id: document?._id }, {
                                                                                    //     onSuccess: (succ) => {
                                                                                    //       // console.log(succ, 'succ')
                                                                                    //       arrayHelpers?.remove(index);
                                                                                    //       toast.success(
                                                                                    //         <CustomToast message={"Document Deleted!"} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                                                                                    //         {
                                                                                    //           duration: 2000,
                                                                                    //           position: "top-right",
                                                                                    //         }
                                                                                    //       );
                                                                                    //     },
                                                                                    //     onError: (err) => {
                                                                                    //       toast.error(
                                                                                    //         <CustomToast message={err?.response?.data?.message} style={{ padding: "0px 15px" }} closeIcon={true} closeIconPosition={"center"} />,
                                                                                    //         {
                                                                                    //           duration: 2000,
                                                                                    //           position: "top-right",
                                                                                    //         }
                                                                                    //       );
                                                                                    //     }
                                                                                    //   });
                                                                                    }
                                                                                    else {
                                                                                    arrayHelpers?.remove(index)
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    return;
                                                                                }

                                                                                }
                                                                                } />
                                                                            )}
                                                                        </Grid>
                                                                        {
                                                                            formik.errors?.documents?.[index]?.document && formik.touched.documents?.[index]?.document && (
                                                                            <CustomValidationText value={formik.errors?.documents[index]?.document} />
                                                                            )
                                                                        }
                                                                        </>
                                                                    );
                                                                    }
                                                                )}
                                                            </>
                                                            )}
                                                        />
                                                        {
                                                            formik.values?.documents?.filter(item => item?.title == "Pitch Deck Round")?.length > 1 && (
                                                            <CustomValidationText value="Pitch Deck Round is already exists" />
                                                            )

                                                        }
                                                        </Grid>

                                                    </>
                                                )}
                                              
                                            </Grid>
                                        </PerfectScrollbar>
                                    </Form>
                                </FormikProvider>
                                <Grid
                                    item
                                    container
                                    display={"flex"}
                                    xs={12}
                                    sm={12}
                                    height={"min-content"}
                                    gap={"1.5rem"}
                                    justifyContent={"center"}
                                    padding={"0 0rem 1rem 0rem"}
                                >
                                    {currentIndex !== 0 && <Grid xs={5} sm={5} md={5.5} lg={5.7} item>
                                        <BLUEBORDER
                                            sx={{
                                                textTransform: "none",
                                                padding: 2,
                                                borderWidth: 1,
                                                borderRadius: "8px",
                                            }}
                                            type="no usetr"
                                            onClick={() => {
                                                if (currentIndex === 0) {
                                                    // navigate(-1);
                                                } else {
                                                    setCurrentIndex(currentIndex - 1);
                                                }
                                                window.document
                                                    .getElementById("scrollable-form")
                                                    .scrollTo(0, 0);
                                                // window.scrollTo(0, 0);
                                            }}
                                            fullWidth
                                        >
                                            Back
                                        </BLUEBORDER>
                                    </Grid>}
                                    {/* {console.log(handleFormSubmit,"handleFormSubmithandleFormSubmit")} */}

                                    <Grid xs={5} sm={5} md={5.5} lg={5.7} item>
                                        <CustomButton
                                            sx={{ padding: 2, borderRadius: "8px", ":disabled": { color: '#d9d9d9' } }}
                                            onClick={(e) => {
                                                handleFormSubmit(e, formik);
                                            }}
                                            // disabled={currentIndex === 5 && formik.values?.documents?.filter(item => item?.t}
                                            // onClick={() => { setCurrentIndex(currentIndex + 1) }}
                                            fullWidth
                                        >
                                            {(isUpdatingsavePersonalInfo) && !matchesUpMd ? <CircularProgress sx={{ color: "white", marginInline: 'auto' }} size={'1.5rem'} /> : currentIndex == 1 ? "Submit" : "Next"}
                                        </CustomButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            }
        </ThemeBGWrapper>
    );
};

export default CreatePortfolio;