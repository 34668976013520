import React from "react";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement, } from "chart.js";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";

ChartJS.register(PointElement, LineElement, Title, Tooltip, Legend);

const LineChart = ({
  graphData,
  width = "330px",
  height = "calc(100vh - 25rem)",
  chartMaxWidth = 330,
}) => {
  const theme = useTheme();
  const totalPercentage = graphData?.reduce((total, item) => {
    return total + Number(item?.percentage || 0);
  }, 0);

  const colorsTheme = [
    "#4285F4", // Blue
    "#AA46BB", // Purple
    "#0F9D58", // Green
    "#DB4437", // Red
    "#FF6F00", // Orange
    "#A5733C", // Brown
    "#FFCC00", // Yellow
    "#FF00FF", // Pink
    "#00C3E3", // Cyan
  ];

  const remainingPercentage = 100 - totalPercentage;

  const data = {
    labels: graphData?.map(item => {return item.label}),
    datasets: [
      {
        data: graphData
          ? [
            ...graphData?.map((item) => Number(item?.percentage))
          ].filter(Boolean) // filter out null values
          : [],
        backgroundColor: [...colorsTheme],
        borderColor: '#4285F4',
        borderWidth: 1,
        tension: 0.1
      },
    ],
  };

  const options = {
    // cutout: "40%", // Adjust the size of the center hole
    responsive: true,
    width: 330,
    plugins: {
      legend: {
        display: false
      },
      tooltip: true,
      datalabels: {
        display: false,
        align: "center",
      },
    },
    scales: {
      x: {
        ticks: {
          font: {
            family: '"Lato", sans-serif'
          }
        },
      },
      y: {
        ticks: {
          font: {
            family: '"Lato", sans-serif'
          }
        },
      },
    },
  };

  return (
    <div
      style={{
        width: width,
        height: height,
        marginInline: "auto",
      }}
    >
      {totalPercentage > 0 ? (
        <>
          <Box style={{
            marginBottom: '1rem', marginInline: "auto", "& > canvas": {
              marginInline: "auto"
            }
          }}>
            <Line data={data} options = {options} />
          </Box>
        </>
      ) : (
        <Grid
          display={"grid"}
          sx={{ placeItems: "center", height: "calc(100vh - 5rem)" }}
        >
          No Data Available
        </Grid>
      )}
    </div>
  );
};

export default LineChart;
