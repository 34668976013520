import React from "react";
import { useNavigate } from "react-router-dom";
import {
  BLUEBORDER,
  B300 as CustomButton,
} from "../../ui-components/CustomButton";
import { Box, Grid, Modal, Typography, useMediaQuery } from "@mui/material";
import Wrapper from "../onboarding/Wrapper";
import CustomBackgroundComponent from "../../ui-components/CustomBackgroundComponent";
import bgImage from "../../assets/login_screen_image.png";
import welcomeImage from "../../assets/Illustration.webp";
import Cookies from "js-cookie"
import { FetchProfileDetails, FetchStartupDetails } from "../../api";
import { SET_INVESTOR_DETAILS } from "../../store/actions";
import { useDispatch } from "react-redux";
import { useTheme } from "@emotion/react";

const WelcomeScreen = () => {
  const { data: userData, isLoading: isUserDataLoading } = FetchProfileDetails();
  const dispatch = useDispatch()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  // console.log(userData?.data,"userData")
  const navigate = useNavigate();
  React.useEffect(() => {
    if (userData?.data) dispatch({ type: SET_INVESTOR_DETAILS, data: userData?.data });
  }, [isUserDataLoading])
  return (
    <>
      <Modal open={isUserDataLoading}>
        <Box className="skeleton" height={"100vh"} width={"100%"}></Box>
      </Modal>
      <Grid container spacing={2} justifyContent="center" marginBlock={"auto"}>
        <Grid item xs={12} display="grid" sx={{ gap: "0.5rem", textAlign: "center" }}>
          <img
            src={welcomeImage}
            alt="welcome"
            style={{
              width: "100%",
              maxWidth: isSmallScreen ? 200 : 300,
              maxHeight: isSmallScreen ? 150 : 200,
              margin: "auto",
              aspectRatio: "1",
            }}
          />
          <Typography
            variant="title" sx={{ fontSize: { xs: "1.5rem", md: "1.75rem" } }}
          >
            Welcome aboard, {userData?.data?.first_name ? userData?.data?.first_name : ''}!
          </Typography>
          <Typography variant="body1">
            Your investor profile has been successfully created.
          </Typography>
        </Grid>
        <Grid
          // item
          xs={12}
          sm={8}
          // md={6}
          sx={{
            border: "1px solid #ADA2ED",
            background: "#EBE8FB",
            padding: { xs: "1rem", lg: "1rem 1.5rem" },
            borderRadius: "8px",
            maxWidth: "100%",
            marginTop: "1rem",
            textAlign: "center",
            marginLeft: { xs: "1rem", sm: "1rem" }
          }}
        >
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            You are a Backrr now.
          </Typography>
          <Typography variant="body2" height={"min-content"} sx={{marginTop: 1}}>
            Effortlessly explore and manage all of your startup investments from a single, intuitive platform.
          </Typography>
        </Grid>

        <Grid
          item
          container
          xs={12}
          display={"flex"}
          alignItems={"flex-end"}
          justifyContent={"center"}
          gap={{ xs: 2 }}
          mt={2}
        >
          {/* <Grid xs={12} sm={5} md={5.5} lg={5.65} item>
              <BLUEBORDER
                sx={{
                  textTransform: "none",
                  padding: 2,
                  borderWidth: 1,
                  borderRadius: "8px",
                }}
                type="no usetr"
                onClick={() => {
                  navigate("/");
                }}
                fullWidth
              >
                Explore
              </BLUEBORDER>
            </Grid> */}
          <Grid xs={12} sm={5} md={5.5} lg={5.65} item>
            <CustomButton
              sx={{ padding: "0.75rem 1rem", borderRadius: "8px" }}
              onClick={() => {
                // Cookies.set("is_profile_complete", true)
                navigate("/startups");
              }}
              fullWidth
            >
              Continue to dashboard
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default WelcomeScreen;
