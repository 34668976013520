import React from 'react';

// Define the ImageIcon component and wrap it with React.memo
const ImageIcon = React.memo(({ src, alt }) => (
    <img
        src={src}
        alt={alt}
        style={{ width: 40, height: 40, background: "#F9F5FF", borderRadius: '4px' }}
        loading="lazy"
    />
));

export default ImageIcon;
