import { Grid } from '@mui/material';
import { useConfig } from '../../../dynamicConfiguration/configContext'
import { useFormikContext } from 'formik';
import React from 'react'
import CustomLogoButton from 'ui-components/formik/CustomLogoButton'
import placeHolderImage from "assets/placeholder_image.png"

const UploadOrgPhoto = ({ label }) => {
    const { config } = useConfig();
    const formik = useFormikContext();
    return (
        <Grid container xs={12} gap={0} sx={{ overflowX: "hidden" }}>
            <Grid item xs={12} sm={12}>
                <CustomLogoButton label={label ? label : config?.dynamicText?.uploadPhoto} handleUpdateFile={(file) => { formik.setFieldValue("website_logo", file); formik.setFieldValue("avatar_selected", null) }} name={"website_logo"} value={formik?.values?.website_logo} placeholder={placeHolderImage} Pheight={100} Pwidth={100} />
                {/* {console.log(formik?.values?.website_logo)} */}

                {/* <CustomUploadButton label="STARTUP LOGO" handleUpdateFile={(evt) => { formik.setFieldValue("startup_logo", evt.target.files[0]) }} selectedFile={formik.values.startup_logo} /> */}
            </Grid>
        </Grid>
    )
}

export default UploadOrgPhoto