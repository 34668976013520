import { Box, FormControlLabel, Grid } from '@mui/material';
import { FetchAllBusinessModel, FetchAllCategories, FetchAllSubCategories } from 'api/utils';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react'
import CustomLabel from 'ui-components/CustomLabel';
import CustomValidationText from 'ui-components/CustomValidationText';
import placeHolderImage from "assets/user_profile.png"
import marketplaceIcon from 'assets/marketplace.png'
import mobileShoppingIcon from 'assets/mobile-shopping.png'
import digitalServiceIcon from 'assets/digital-services.png'
import laptopScreenIcon from 'assets/laptop-screen.png'
import CustomAutoComplete from 'ui-components/CustomAutoComplete';


const PreferredCategory = ({ isLoading, isSectorAgnostic, setIsSectorAgnostic, isBusinessModelSelectAll, setIsBusinessModelSelectAll }) => {
  const formik = useFormikContext()
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [currentSubCategory, setCurrentSubCategory] = React.useState([]);
  // const [searchSubCategory, setSearchSubCategory] = useState('');
  const [searchSubCategory, setSearchSubCategory] = React.useState("");
  const [currentBusinessModel, setCurrentBusinessModel] = React.useState([]);
  const [searchBusinessModel, setSearchBusinessModel] = React.useState([]);
  const { data: startup_business_model, isRefetching: isRefetchingBusinessModel, isLoading: isLoadingBusinessModel, refetch: refetchBusinessModel } = FetchAllBusinessModel({})
  const { data: startup_categories, isRefetching: isRefetchingCategories, isLoading: isLoadingCategories, refetch: refetchCategories } = FetchAllCategories()
  const { data: startup_sub_categories, isRefetching: isRefetchingSubCategories, isLoading: isLoadingSubCategories, refetch: refetchSubCategories } = FetchAllSubCategories({ category: selectedCategory })
  React.useEffect(() => {
    if (!isLoading && !isLoadingBusinessModel && formik?.values && Array.isArray(startup_business_model) && Array.isArray(formik?.values.business_model_id)) {
      const businessModel = startup_business_model?.filter(item => formik?.values?.business_model_id?.includes(item?._id));
      if (businessModel.length) {
        setCurrentBusinessModel(
          businessModel.map(item => ({
            label: item?.name,
            value: item?._id,
            description: item?.description,
          }))
        );
      }
    }

  }, [isLoading, isLoadingBusinessModel, formik?.values, startup_business_model, formik?.values?.business_model_id]);

  React.useEffect(() => {
    if (formik.values?.business_model_type === "All") {
      setIsBusinessModelSelectAll(true)
      setCurrentBusinessModel([])
    }
  }, [startup_business_model, formik?.values?.business_model_type])

  const handleSearchBusinessModelChange = (event, value) => {
    // console.log(event.target.value, value,"ca")
    setSearchBusinessModel(value);
    // refetchCities();
  };
  const handleSelectionBusinessModelChange = (event, businessModel) => {
    formik.setFieldValue("business_model_id", businessModel ? businessModel?.map(item => item?.value) : []);
    setCurrentBusinessModel(businessModel)
    event.stopPropagation();
    // console.log(businessModel ,"businessModelbusinessModelbusinessModel")
  };
  const handleSearchUpdateBusinessModel = () => {
    refetchBusinessModel();
  }
  const availableOptionsBusinessModel = startup_business_model?.filter(
    item =>
      !(currentBusinessModel || []).some(selected => selected.value === item._id)
  ) || [];

  // Effect to set selected category based on formik values
  React.useEffect(() => {
    if (!isLoading && !isLoadingCategories && formik?.values && Array.isArray(startup_categories)) {
      const category = startup_categories?.filter(item => formik?.values?.category_id?.includes(item?._id));

      if (category.length) {
        setSelectedCategory(category.map(item => item?.name).join(","));  // Or whichever property you want to use
      }
    }
  }, [isLoading, isLoadingCategories, formik?.values, startup_categories]);

  React.useEffect(() => {
    if (!isRefetchingCategories && !isLoadingCategories && selectedCategory) {
      refetchSubCategories();
    }
  }, [selectedCategory, isRefetchingCategories || isLoadingCategories]);

  React.useEffect(() => {
    if (formik.values?.sector_id?.length > 0 && startup_sub_categories) {
      const matchedSubCategories = startup_sub_categories?.filter(subCat =>
        formik.values?.sector_id?.includes(subCat?._id)
      );
      setCurrentSubCategory(
        matchedSubCategories?.map(subCat => ({
          label: subCat?.sub_category,
          value: subCat?._id,
          description: subCat?.description,
        }))
      );
    }
    if (formik.values?.sector_type === "All") {
      setIsSectorAgnostic(true);
      setCurrentSubCategory([])
    }
  }, [startup_sub_categories, formik.values?.sector_type]);

  const handleSearchSubCategoryChange = (event, value) => {
    if (value !== undefined) {
      setSearchSubCategory(value); // Update search state
    }
    // console.log(setSearchSubCategory,"setSearchSubCategory")
  };
  const handleSelectionSubCategoryChange = (event, selectedSectors) => {
    formik.setFieldValue(
      "sector_id",
      selectedSectors.map(item => item.value)
    );
    setCurrentSubCategory(selectedSectors);
  };
  const handleSearchUpdateSubCategory = () => {
    refetchSubCategories();
  };
  const availableOptions = startup_sub_categories
    ?.filter(item => {
      const isSelected = currentSubCategory.some(
        selected => selected.value === item._id
      );
      const matchesSearch = item.sub_category
        .toLowerCase()
        .includes(searchSubCategory.toLowerCase());
      return !isSelected && matchesSearch;
    }) || [];
  return (
    <>
      <Grid container item xs={12} gap={1} paddingBottom={0} paddingRight={1} marginTop={'0rem'} >
        <Grid item xs={12}>
          <CustomLabel sx={{ marginTop: "0rem", marginBottom: 0 }}>Select Category (Multi Select) {formik.errors?.category_id && (
            <CustomValidationText value={formik.errors?.category_id} style={{ textTransform: 'none' }} />
          )}</CustomLabel>
        </Grid>
        <FieldArray
          name="category_id"
          render={(arrayHelpers) => (
            <>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  padding: 0,
                  paddingBottom: 1,
                  marginTop: 0,
                  justifyContent: 'space-between',
                }}
                gap={2}
              >
                {Array.isArray(startup_categories) &&
                  startup_categories?.map((category, index) => {
                    const isSelected = formik?.values?.category_id?.includes(category?._id);

                    const handleCheckboxChange = () => {
                      if (isSelected) {
                        const idx = formik.values.category_id.indexOf(category?._id);
                        arrayHelpers.remove(idx);
                      } else {
                        arrayHelpers.push(category?._id);
                      }
                    };

                    return (
                      <Grid
                        item
                        xs={12}
                        sm={5.8}
                        md={5.75}
                        key={category?._id}
                        onClick={handleCheckboxChange}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                          background: isSelected ? '#FFFAE6' : '#FFF',
                          fontSize: '0.875rem',
                          border: `1px solid ${isSelected ? '#FFC900' : '#A1A3A7'}`,
                          borderRadius: '8px',
                          paddingRight: '1rem',
                          paddingLeft: '1rem',
                          paddingBlock: '1.5rem',
                          height: '2.5rem',
                          cursor: 'pointer',
                          textTransform: 'none',
                          color: 'inherit',
                        }}
                      >
                        {index === 0 && (
                          <img
                            src={laptopScreenIcon}
                            style={{ height: '1.6rem', width: '1.6rem', marginRight: '0.5rem' }}
                            alt="icon"
                          />
                        )}
                        {index === 1 && (
                          <img
                            src={mobileShoppingIcon}
                            style={{ height: '1.4rem', width: '1.4rem', marginRight: '0.5rem' }}
                            alt="icon"
                          />
                        )}
                        {index === 2 && (
                          <img
                            src={marketplaceIcon}
                            style={{ height: '1.4rem', width: '1.4rem', marginRight: '0.5rem' }}
                            alt="icon"
                          />
                        )}
                        {index === 3 && (
                          <img
                            src={digitalServiceIcon}
                            style={{
                              height: '1.4rem',
                              width: '1.4rem',
                              marginRight: '0.5rem',
                              transform: 'rotateY(180deg)',
                            }}
                            alt="icon"
                          />
                        )}

                        <span>{category?.name}</span>
                        <input
                          type="checkbox"
                          checked={isSelected}
                          readOnly
                          style={{ marginLeft: 'auto', cursor: 'pointer' }}
                        />

                      </Grid>
                    );
                  })}
              </Grid>
            </>
          )}
        />
        <Grid item xs={12} mt="0.5rem">
          <CustomAutoComplete
            label={<Box display="flex" alignItems="center" gap={1}>
              <span>SELECT SECTORS (MULTI SELECT)</span>
              <FormControlLabel
                label="SECTOR AGNOSTIC"
                labelPlacement="start"
                control={
                  <input
                    type="checkbox"
                    checked={isSectorAgnostic}
                    onChange={(e) => setIsSectorAgnostic(e.target.checked)}
                    style={{ cursor: 'pointer' }}
                  />

                }
                sx={{
                  marginLeft: 'auto',
                  marginRight: '0px',
                  gap: "6px",
                  '& .MuiFormControlLabel-label': {
                    fontSize: '0.875rem',
                    color: '#0f1319',
                  },
                }}
                onClick={() => { formik.setFieldValue('sector_id', []); setCurrentSubCategory([]) }}
              />
            </Box>}
            options={availableOptions.map(item => ({
              label: item.sub_category,
              value: item._id,
              description: item.description,
            }))}
            disabled={isSectorAgnostic}
            name="sector_id"
            multiple={true}
            noOptionsText={
              !isLoadingSubCategories && !isRefetchingSubCategories
                ? 'No Options'
                : 'Searching...'
            }
            placeholder={isSectorAgnostic ? "Sector Agnostic" : "Type a sector name"}
            onInputChange={(event, value) =>
              handleSearchSubCategoryChange(event, value)
            }
            handleSelectionChange={handleSelectionSubCategoryChange}
            value={currentSubCategory}
            renderOption={(props, option) => (
              <li {...props} style={{ display: 'grid' }}>
                <span style={{ fontWeight: 600 }}>{option.label}</span>
                <p style={{ fontSize: '0.8rem' }}>{option.description}</p>
              </li>
            )}
            loading={isRefetchingSubCategories}
            handleSearchUpdate={handleSearchUpdateSubCategory}
          />
        </Grid>
        <Grid item xs={12} sx={{ marginTop: '-5px' }}>
                                    <CustomAutoComplete
                                        label={<Box display="flex" alignItems="center" gap={1}>
                                            <span>SELECT BUSINESS MODEL (MULTI SELECT)</span>
                                            <FormControlLabel
                                                label="SELECT ALL"
                                                labelPlacement="start"
                                                control={
                                                    <input
                                                        type="checkbox"
                                                        checked={isBusinessModelSelectAll}
                                                        onChange={(e) => setIsBusinessModelSelectAll(e.target.checked)}
                                                        style={{ cursor: 'pointer' }}
                                                    />

                                                }
                                                sx={{
                                                    marginLeft: 'auto',
                                                    marginRight: '0px',
                                                    gap: "6px",
                                                    '& .MuiFormControlLabel-label': {
                                                        fontSize: '0.875rem',
                                                        color: '#0f1319',
                                                    },
                                                }}
                                                onClick={() => { formik.setFieldValue('business_model_id', []); setCurrentBusinessModel([]) }}
                                            />
                                        </Box>

                                        }
                                        options={!isLoadingBusinessModel && !isRefetchingBusinessModel && Array.isArray(availableOptionsBusinessModel) ? availableOptionsBusinessModel?.map(item => ({ label: item?.name, value: item?._id, description: item?.description })) : []}
                                        name={"business_model_id"}
                                        disabled={isBusinessModelSelectAll}
                                        multiple={true}
                                        noOptionsText={!isLoadingBusinessModel && !isRefetchingBusinessModel ? "No Options" : "Searching..."}
                                        placeholder={isBusinessModelSelectAll ? "All Selected" : "Business model"}
                                        // icon={null}
                                        handleSearchChange={handleSearchBusinessModelChange}
                                        handleSelectionChange={handleSelectionBusinessModelChange}
                                        search={searchBusinessModel}
                                        renderOption={(props, option) => (
                                            <li {...props} style={{ display: 'grid' }}>
                                                <span style={{ fontWeight: 600 }}>{option.label}</span>
                                                <p style={{ fontSize: '0.8rem' }}>{option?.description}</p>
                                                {/* {option.isSpecial && <span className="special-badge">Special</span>} */}
                                            </li>
                                        )}
                                        value={currentBusinessModel || []}
                                        defaultValue={startup_business_model?.filter(item => item?._id == formik?.values?.business_model_id)}
                                        loading={isRefetchingBusinessModel}
                                        handleSearchUpdate={handleSearchUpdateBusinessModel}
                                    />
                                </Grid>
      </Grid>
    </>
  )
}

export default PreferredCategory