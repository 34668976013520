import { useTheme } from '@emotion/react'
import { CircularProgress, Grid, InputAdornment, Switch, Tooltip, Typography } from '@mui/material'
import React, { useRef } from 'react'
import AddIcon from "assets/add_button.png"
import whatsapp_icon from "assets/whatsapp_main_icon.png"
import { useFormikContext } from 'formik'
import CustomInputField from 'ui-components/formik/CustomInputField'
import { currencyInputProps } from 'utils/index'
import { VerifyWhatsAppOTP } from 'api/profile'
import { useNavigate } from 'react-router-dom'
import CustomInputFieldOTP from 'ui-components/CustomInputField'
import CustomValidationText from 'ui-components/CustomValidationText'
import { useConfig } from 'dynamicConfiguration/configContext'
import { Check } from '@mui/icons-material'
import CustomPhone from 'ui-components/CustomPhone'
import { FetchAllCountriesWithPhoneCodes } from 'api/utils'
import CustomUploadButton from 'ui-components/CustomUploadButton'
import { B300 } from 'ui-components/CustomButton'

const inputProps = {
  maxLength: 1,
  inputMode: 'numeric',
  pattern: "[0-9]*",
  style: {
    textAlign: 'center',
  },
  onKeyDown: (event) => {
    if (event.key === '-' || event.keyCode === 189) {
      event.preventDefault();
    }
  },
  onKeyPress: (event) => {
    const allowedKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace', 'ArrowLeft', 'ArrowRight', 'Delete', 'Tab'];
    if (!allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  },
  onInput: (event) => {
    const inputValue = event.target.value.replace(/[^0-9]/g, '');
    event.target.value = inputValue;
  }
};

const WhatsAppNotifications = ({ resend, handleVerifyOTP, isLoading, refetchProfileDetails, error, setError }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const formik = useFormikContext()
  // console.log('enabeled', formik.values)
  const { config } = useConfig()
  const isVerify = formik?.values?.isVerify;
  const [isWhatsAppEnabled, setIsWhatsAppEnabled] = React.useState(formik.values?.enabled || false);
  const [isPublicEnabled, setIsPublicEnabled] = React.useState(false);
  const [countDown, setCountDown] = React.useState(30);
  const [otpValues, setOtpValues] = React.useState(Array(4).fill(''));
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const { mutateAsync: verifyWhatsAppOTPAsync, isLoading: isOTPLoading, data: verifyWhatsAppOTPResponse } = VerifyWhatsAppOTP();
  const { data: countriesWithPhoneCodes, isRefetching: isRefetchingCountriesPC, isLoading: isLoadingCountriesPC, refetch: refetchCountriesPC } = FetchAllCountriesWithPhoneCodes({})
  const handleToggle = () => {
    setIsWhatsAppEnabled(!isWhatsAppEnabled);
    formik.setFieldValue("enabled", !isWhatsAppEnabled)
  };

  React.useEffect(() => {
    if (countDown > 0) {
      const timer = setTimeout(() => setCountDown(prev => prev - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [countDown]);

  const handleTogglePublic = (event) => {
    setIsPublicEnabled(!isPublicEnabled);
    formik.setFieldValue("linkedin_public_profile", !isPublicEnabled)
    // setIsPublicEnabled(event.target.checked)
  };
  // const [isVerify, setIsVerify] = React.useState(false);
  const [isMobileEdited, setIsMobileEdited] = React.useState(true);

  const handleVerify = () => {
    // setIsVerify(true);
    formik.setFieldValue("isVerify", true)
    setIsMobileEdited(false);
    setCountDown(30);
    formik?.handleSubmit();
  };
  const handleMobileChange = (event) => {
    const value = event.target.value;
    setIsMobileEdited(value.length > 0);
    formik.setFieldValue("otp_verified", false);
    formik.setFieldValue("isVerify", false)
    formik.handleChange(event);
  };

  React.useEffect(() => {

    setIsWhatsAppEnabled(formik?.values?.enabled)
  }, [])

  // console.log(formik.values?.otp_verified,"otp_verified")

  React.useEffect(() => {
    setIsMobileEdited(!formik.values?.otp_verified)
  }, [formik.values?.otp_verified])


  const handleInputChange = (event, index) => {
    const { value } = event.target;

    // Copy the current OTP values
    const newOtpValues = [...otpValues];

    // Update the current field value
    if (value.length <= 1) {
      newOtpValues[index] = value;
    }

    // Update state and Formik values
    setOtpValues(newOtpValues);
    formik.registerField('newOTPValues', []);
    formik.setFieldValue("newOtpValues", newOtpValues);

    // Clear any existing error
    setError(null);

    // Automatically focus on the next input (if a single character is entered)
    if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  // console.log(formik?.values?.newOtpValues,"newOtpValues")

  const tempFunction = () => {
    const OTP = otpValues.join('');
    if (OTP?.trim()?.length > 0) {
      verifyWhatsAppOTPAsync({ enabled: formik?.values?.enabled, otp: OTP });
    }
    else {
      setError("Please enter OTP");
    }
  }

  const handleKeyDown = (event, index) => {
    // Get the reference to the current input element
    // const currentRef = inputRefs[index];

    if (event.key === 'Backspace' && !event.target.value && index > 0) {
      inputRefs[index - 1].current.focus();
    }
    if (event.key === 'Enter') {
      handleVerifyOTP(tempFunction);
    }

    // Check if the arrow keys are pressed
    if (event.key === 'ArrowRight') {
      // Move focus to the next input if it's not the last one
      if (index < inputRefs.length - 1) {
        inputRefs[index + 1]?.current?.focus();
      }
    } else if (event.key === 'ArrowLeft') {
      // Move focus to the previous input if it's not the first one
      if (index > 0) {
        inputRefs[index - 1]?.current?.focus();
      }
    }
  };

  const handlePaste = (event) => {
    event.preventDefault();

    // Get the pasted data and filter out non-numeric characters
    const pastedData = event.clipboardData
      .getData('text')
      .replace(/\D/g, '') // Remove all non-numeric characters
      .slice(0, 4); // Ensure only the first 4 characters are processed

    const newOtpValues = [...otpValues];

    pastedData.split('').forEach((char, index) => {
      if (inputRefs[index]) {
        inputRefs[index].current.value = char;
        newOtpValues[index] = char;
      }
    });

    setOtpValues(newOtpValues);
  };

  const handleResendingOTP = () => {
    resend({ mobile_number: formik?.values.whatsapp_number }).then(() => {
      setCountDown(30);
    });
  };

  React.useEffect(() => {
    if (otpValues?.join("")?.trim()?.length > 0) {
      if (verifyWhatsAppOTPResponse?.data?.statusCode === 200) {
        setTimeout(() => {
          setError(null)
          refetchProfileDetails()
          // setCurrentStep(currentStep + 1);
          // setSearchParams({ page: currentStep + 1 });
          navigate('/whatsApp-success');
        }, 100);
      } else if (verifyWhatsAppOTPResponse?.data?.statusCode !== 200) {
        verifyWhatsAppOTPResponse?.data?.message && setError(verifyWhatsAppOTPResponse?.data?.message || null)

      }
    }
  }, [verifyWhatsAppOTPResponse?.data?.statusCode]);
  return (
    <>
      <Grid
        role="button"
        alignItems="center"
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        sx={{
          background: `${theme?.palette?.customColors?.y50}`,
          padding: "0px 16px",
          height: 55,
          borderRadius: "8px",
          display: "grid",
          gridTemplateColumns: "auto 1fr auto",
          border: `1px solid ${theme?.palette?.customColors?.y300}`,
          boxShadow: "0px 1px 2px 0px #1018280D",
          cursor: "pointer",
        }}
      >
        <img src={whatsapp_icon} alt='whats' width={20} height={20} style={{ marginRight: '0.5rem' }}></img>
        <Typography
          variant="subtitle2"
          color={theme.palette.customColors.g200}
          fontSize="0.875rem"
        >
          Enable WhatsApp notification
        </Typography>
        <Switch checked={isWhatsAppEnabled} onChange={handleToggle} />
      </Grid>
      {isWhatsAppEnabled && (
        <>
          <Grid height="min-content" item xs={12} sm={12} md={12} lg={12} pt={2}>
            <CustomPhone
              name="whatsapp_number"
              label="MOBILE NUMBER"
              selectedCode={formik.values?.phone_code ? formik.values?.phone_code : ""} handleChange={(event) => formik.setFieldValue("phone_code", event.target.value)} countryCodes={countriesWithPhoneCodes ? countriesWithPhoneCodes : []}
              fullWidth
              InputProps={{
                ...currencyInputProps,
                endAdornment: (
                  formik.values?.otp_verified && <InputAdornment
                    position="end"
                    sx={{
                      marginLeft: "0px",
                      paddingInline: "8px",
                      height: "49px",
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <Tooltip title="Verified" style={{ marginTop: '0.4rem' }}> {<Check sx={{ color: "green" }} />}</Tooltip>
                  </InputAdornment>
                ),
              }}
              onChange={handleMobileChange}
              value={formik.values.whatsapp_number}
            />
          </Grid>
          {!formik.values?.otp_verified && isMobileEdited && !isVerify && (
            <>
              {/* <Typography
              color={theme.palette.customColors.b300}
              fontSize="1rem"
              sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'underline',
                textUnderlineOffset: '5px',
                cursor: 'pointer',
              }}
              onClick={() => handleVerify()}
            >
              <span>Verify mobile number</span>
            </Typography> */}
              <Grid item display={"grid"} alignItems={"center"} justifyContent={"center"}
              >
                <B300  onClick={() => handleVerify()} sx={{ padding: 2, borderRadius: "8px", height: '56.5px', minWidth: 100 }}>
                  Verify
                </B300>
              </Grid>

            </>
          )}

          {isVerify && (
            <Grid container xs={12} display="grid" sx={{ placeItems: 'flex-start', placeContent: 'stretch', gap: '0.4rem' }}>
              <Typography color="gray" variant="subtitle2" mb="0rem">
                ENTER OTP
              </Typography>
              <Grid container xs={12} gap={2}>
                {inputRefs?.map((ref, index) => (
                  <Grid item flex={1} key={index}>
                    <CustomInputFieldOTP
                      inputProps={inputProps}
                      required
                      sx={{ marginBottom: 0 }}
                      inputRef={ref}
                      onChange={(event) => handleInputChange(event, index)}
                      onKeyDown={(event) => handleKeyDown(event, index)}
                      onPaste={index === 0 ? handlePaste : undefined}
                    />
                  </Grid>
                ))}
              </Grid>
              {error !== null && <CustomValidationText style={{ marginTop: '-0.5rem' }} value={error} />}
              {isLoading ? (
                <Typography
                  color={theme.palette.customColors.b300}
                  sx={{ display: 'flex', alignItems: 'center', gap: 1, marginTop: '0.5rem' }}
                  fontSize="1rem"
                >
                  <CircularProgress sx={{ color: theme.palette.customColors.b300 }} size="1rem" />
                  <span>Resending OTP</span>
                </Typography>
              ) : (
                <Typography
                  color={theme.palette.customColors.b300}
                  fontSize="1rem"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textDecoration: 'underline',
                    textUnderlineOffset: '5px',
                    marginTop: '0.5rem',
                  }}
                >
                  <span
                    onClick={countDown === 0 ? handleResendingOTP : undefined}
                    style={{
                      color: countDown > 0 ? theme.palette.customColors.b75 : theme.palette.customColors.b300,
                      cursor: 'pointer',
                    }}
                  >
                    {config?.dynamicText?.resend}
                  </span>
                  <span>&nbsp;{countDown > 0 && `(${countDown} Sec)`}</span>
                </Typography>
              )}
            </Grid>
          )}
        </>
      )
      }
    </>
  )
}

export default WhatsAppNotifications