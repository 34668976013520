import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import Cookies from "js-cookie";
import { RetryOnError, verifyToken } from "../utils/VerifyToken";

export const AddPortfolio = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/portfolio/add`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                },
                data: payload,
                // onUploadProgress: (data) => {
                //     setProgress(Math.round((data.loaded / data.total) * 100) == 100 ? 99 : Math.round((data.loaded / data.total) * 100));
                // }
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const EditPortfolio = () => {
    return useMutation(
        ({ payload, portfolio_id }) => {
            return axios({
                method: 'put',
                url: `${process.env.REACT_APP_BASE_URL}/portfolio/edit/${portfolio_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                },
                data: payload,
                // onUploadProgress: (data) => {
                //     setProgress(Math.round((data.loaded / data.total) * 100) == 100 ? 99 : Math.round((data.loaded / data.total) * 100));
                // }
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

export const AddPortfolioRound = () => {
    return useMutation(
        ({ payload }) => {
            return axios({
                method: 'post',
                url: `${process.env.REACT_APP_BASE_URL}/portfolio/add-portfolio-rounds`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'multipart/form-data'
                },
                data: payload,
                // onUploadProgress: (data) => {
                //     setProgress(Math.round((data.loaded / data.total) * 100) == 100 ? 99 : Math.round((data.loaded / data.total) * 100));
                // }
            });
        },
        {
            retry: 0,
            // onSuccess: ()=>console.log("API successfull"),
            retry: (failureCount, error) => {
                return RetryOnError(failureCount, error)
            },
            onError: (err) => {
                // console.error(err);
                return verifyToken(err);
            }
        }
    );
};

// export const EditPortfolioRound = () => {
//     return useMutation(
//         ({ payload, portfolio_id }) => {
//             return axios({
//                 method: 'put',
//                 url: `${process.env.REACT_APP_BASE_URL}/portfolio/edit/${portfolio_id}`,
//                 headers: {
//                     Authorization: `Bearer ${Cookies.get('jwt')}`,
//                     'Content-type': 'multipart/form-data'
//                 },
//                 data: payload,
//                 // onUploadProgress: (data) => {
//                 //     setProgress(Math.round((data.loaded / data.total) * 100) == 100 ? 99 : Math.round((data.loaded / data.total) * 100));
//                 // }
//             });
//         },
//         {
//             retry: 0,
//             // onSuccess: ()=>console.log("API successfull"),
//             retry: (failureCount, error) => {
//                 return RetryOnError(failureCount, error)
//             },
//             onError: (err) => {
//                 // console.error(err);
//                 return verifyToken(err);
//             }
//         }
//     );
// };

export const FetchAllPortfolio = ({ status = "new", id, page = 1, limit = 20, sort = -1, filter, queryParams, searchText = "" }) => {
    return useQuery(
        ['master-listing-template-portfolio-listinginginginginging-details-fetch' + status, status, id, page, limit, sort],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/portfolio/get-all/${id}?search=${searchText}&page=${page}&limit=${limit}${sort != 0 ? `&sort=${sort}` : ''}&status=${status}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            // console.log(data?.data?.data?.data,'values new')
            const savedData = data?.data
            // ? data?.data?.data?.data?.length > 0 ? data?.data?.data?.data
            // const savedData = data?.data?.data
            return { data: savedData?.data, count: savedData?.count ?? 0, ...savedData }
        },
        refetchOnWindowFocus: false,
        enabled: !!id,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const FetchPortfolioDetails = ({ id }) => {
    // console.log(page.page,page.search,"page")
    return useQuery(
        ['master-id-id-FetchStartupDetails-id-id-fetch', id],
        () => {
            return axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/portfolio/get-details/${id}`,
                headers: {
                    'Authorization': `Bearer ${Cookies.get('jwt')}`
                },
            })
        }, {
        // select: (data) => data,
        select: (data) => {
            const savedData = data?.data?.data[0];
            // const startup_info = savedData?.startup_info
            const savedValues = {
                ...savedData,
                stages: savedData?.startup_info ? savedData?.startup_info?.stage_id : savedData?.stage_id,
                sectors: savedData?.startup_info ? String(savedData?.startup_info?.sector_id) : String(savedData?.sector_id),
                business_model: savedData?.startup_info ? savedData?.startup_info?.business_model_id : savedData?.business_model_id,
                category: savedData?.startup_info ? savedData?.startup_info?.category_id : savedData?.category_id,
                // startup_logo: savedData?.startup_info ? savedData?.startup_info?.stage_id : savedData?.startup_logo_path,
                startup_logo: savedData?.startup_info ? savedData?.startup_info?.startup_logo_path : savedData?.startup_logo_path,
                startup_website: savedData?.startup_info ? (String(savedData?.startup_info?.website_url)?.startsWith("https://") ? String(savedData?.startup_info?.website_url)?.slice(8) : savedData?.startup_info?.website_url) :
                    savedData?.website_url ? (String(savedData?.website_url)?.startsWith("https://") ? String(savedData?.website_url)?.slice(8) : savedData?.website_url) : '',
                // savedData?.website_url,
                documents: savedData?.documents ? savedData?.documents?.map((item => {
                    return ({
                        ...item,
                        document: item?.file_path
                    })
                })) : [],
                round_name: '',
                investment_date: '',
                syndicate_name: '',
                no_of_units: '',
                per_share_unit: '',
                investment_amount: '',
                current_value_per_unit: '',
                current_investment_value: '',
                security_type: null,
            }
            // console.log(savedValues, "new Data")
            return { data: savedValues, status: savedData?.statusCode }
        },
        enabled: !!id,
        refetchOnWindowFocus: false,
        retry: (failureCount, error) => {
            return RetryOnError(failureCount, error)
        },
        onError: (err) => {
            return verifyToken(err)
        },
    })
}

export const DeletePortfolio = () => {
    return useMutation(
        ({ payload, portfolio_id }) => {
            return axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_URL}/portfolio/delete-portfolio/${portfolio_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                // data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const DeletePortfolioRound = () => {
    return useMutation(
        ({ payload, portfolio_id, round_id }) => {
            return axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_URL}/portfolio/round/${round_id}?portfolio_id=${portfolio_id}`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                // data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};

export const DeletePortfolioRoundDocument = () => {
    return useMutation(
        (payload) => {
            return axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_URL}/portfolio/delete-document`,
                headers: {
                    Authorization: `Bearer ${Cookies.get('jwt')}`,
                    'Content-type': 'application/json'
                },
                data: payload
            });
        },
        {
            retry: 0,
            // onSuccess: (data)=>console.log("API successfull"),
            // retry: (failureCount, error)=>{
            //   return RetryOnError(failureCount, error)
            // },
            onError: (err) => {
                return verifyToken(err);
            }
        }
    );
};


