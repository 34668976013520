import React from 'react';
import CustomTable from '../../../ui-components/CustomTable';
import { B300, B400 } from '../../../ui-components/CustomButton';
import { Add, Mail, MailOutline, MailOutlined, MailOutlineRounded, PersonAddAlt, ThumbUp, PublishedWithChanges, CallReceived, PersonRemove, DeleteOutlineRounded, EditOutlined, UpdateOutlined, Upload } from '@mui/icons-material';
import { ReactComponent as MoreOptionsIcon } from "../../../assets/rounds_icons/more_solid_icon.svg";
import ModalComponent from '../../../ui-components/ModalComponent';
import InviteInvestorPopup from '../popups/InviteInvestorPopup';
import { ReactComponent as RoundInvestmentIcon } from "../../../assets/rounds_icons/round_investor_icon.svg"
import { Box, Button, Card, Dialog, Grid, Paper, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import BankDetailsPopup from '../popups/BankDetailsPopup';
import CustomTabs from '../../../ui-components/CustomTabs';
import { makeStyles } from '@mui/styles';
import { TabContext } from '@mui/lab';
import AddInvestmentPopup from '../popups/AddInvestmentPopup';
import AllocateAmountPopup from '../popups/AllocateAmountPopup';
import AddSHADocumentsPopup from '../popups/AddSHADocumentsPopup';
import { ReactComponent as RoundInvestorsIcon } from "../../../assets/rounds_icons/round_investor_icon.svg"
import AllocateUnits from '../popups/AllocateUnits';

const useStyles = makeStyles((theme) => ({
    scrollTabs: {
        "&": {
            padding: "3rem"
        }
        // '& .MuiTabs-root > .MuiTabs-scroller > .MuiTabs-flexContainer' : {marginLeft: '2rem', gap: '5rem'}
    }
    //     '& .MuiTabs-root > .Mui-disabled.MuiTabs-scrollButtons': {
    //         display: 'none !important',
    //         transition: 'all 1s ease'
    //     },
    // }
}));

function generateEmail(name) {
    return `${name.toLowerCase().replace(" ", ".")}@example.com`;
}

const CardHeader = ({ title, data: CardHeader, action = () => { } }) => {
    return <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} p={"0.5rem 1rem"}>
        <Typography fontWeight={700} fontSize={"1.25rem"}>
            {title}
        </Typography>
    </Box>
}


const RoundInvestors = ({ currency }) => {
    const theme = useTheme()
    const classes = useStyles();
    const [value, setValue] = React.useState("invited");
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('lg'));


    const [openInvitePopup, setOpenInvitePopup] = React.useState(false);
    const [openBankDetailsPopup, setOpenBankDetailsPopup] = React.useState(false);

    const handleInvitePopupOpen = () => {
        setOpenInvitePopup(true);
    }

    const handleInvitePopupClose = () => {
        setOpenInvitePopup(false);
    }

    const handleInviteInvestor = () => {
        handleInvitePopupClose()
    }

    const handleBankDetailsPopupOpen = () => {
        setOpenBankDetailsPopup(true)
    }

    const handleBankDetailsPopupClose = () => {
        setOpenBankDetailsPopup(false)
    }

    const handleSubmitBankDetails = () => {
        handleBankDetailsPopupClose()
    }

    const InvitedColumns = [
        { Header: 'Name', accessor: 'name', minWidth: 150, align: 'start' },
        // { Header: 'Email', accessor: 'email', minWidth: 150, align: 'start' },
        { Header: 'Date', accessor: 'date', minWidth: 150, align: 'center' },
        { Header: 'Lead Investor', accessor: 'is_lead_investor', minWidth: 150, align: 'center', toggle: true, },
        { Header: 'Status', accessor: 'status', minWidth: 100, align: 'center' },
        { Header: 'Actions', accessor: 'actions', minWidth: 100 },
    ];

    const InterestedColumns = [
        { Header: 'Name', accessor: 'name', minWidth: 150, align: 'start' },
        // { Header: 'Email', accessor: 'email', minWidth: 150, align: 'start' },
        { Header: 'Interested Amount', accessor: 'interestedAmount', minWidth: 150, align: 'center' },
        { Header: 'Date', accessor: 'date', minWidth: 150, align: 'center' },
        { Header: 'Lead Investor', accessor: 'is_lead_investor', minWidth: 150, align: 'center', toggle: true, },
        // { Header: 'Total Amount', accessor: 'totalAmount', minWidth: 150, align: 'center' },
        { Header: 'Status', accessor: 'status', minWidth: 100, align: 'center' },
        { Header: 'Actions', accessor: 'actions', minWidth: 100 },
    ];

    const InProgressColumns = [
        { Header: 'Name', accessor: 'name', minWidth: 150, align: 'start' },
        // { Header: 'Email', accessor: 'email', minWidth: 150, align: 'start' },
        { Header: 'KYC', accessor: 'kyc', minWidth: 150, align: 'center', clickable: true },
        { Header: 'Lead Investor', accessor: 'is_lead_investor', minWidth: 150, align: 'center', toggle: true, },
        { Header: 'Allocation Confirmation', accessor: 'allocationConfirmation', minWidth: 200, align: 'center' },
        { Header: 'Fund Received Status with Date', accessor: 'statusWithDate', minWidth: 250, align: 'center' },
        { Header: 'Documents', accessor: 'documents', minWidth: 150, align: 'center', clickable: true },
        { Header: 'Status', accessor: 'status', minWidth: 100, align: 'center' },
        { Header: 'Actions', accessor: 'actions', minWidth: 100 },
    ];

    const InvestedColumns = [
        { Header: 'Name', accessor: 'name', minWidth: 150, align: 'start' },
        // { Header: 'Email', accessor: 'email', minWidth: 150, align: 'start' },
        { Header: 'Investment Amount', accessor: 'interestedAmount', minWidth: 150, align: 'center' },
        { Header: 'Number of Shares', accessor: 'allocationConfirmation', minWidth: 150, align: 'center' },
        { Header: 'Price per Share', accessor: 'pricePerShare', minWidth: 150, align: 'center' },
        { Header: 'Lead Investor', accessor: 'is_lead_investor', minWidth: 150, align: 'center', toggle: true, },
        { Header: 'Status', accessor: 'status', minWidth: 100, align: 'center' },
        { Header: 'Actions', accessor: 'actions', minWidth: 100 },
    ];




    const columns = React.useMemo(
        () =>
            //      [
            //     { Header: 'Name', accessor: 'name', minWidth: 150, align: 'start' },
            //     { Header: 'Interested Amount', accessor: 'amountCommitted', minWidth: 100, align: 'center' },
            //     { Header: 'Allocated Amount', accessor: 'amountReceived', minWidth: 100, align: 'center' },
            //     { Header: 'Received Amount', accessor: 'callForMoney', minWidth: 100, align: 'center' },
            //     // { Header: 'Call for Money', accessor: 'callForMoney', minWidth: 100, align: 'center' },
            //     { Header: 'Price per Share', accessor: 'drawdown', minWidth: 100, align: 'center' },
            //     // { Header: 'Drawdown %', accessor: 'drawdown', minWidth: 150 },
            //     // { Header: 'Share %', accessor: 'share', minWidth: 150 },
            //     { Header: 'No. of Shares', accessor: 'units', minWidth: 100, align: 'center' },
            //     { Header: 'Status', accessor: 'status', minWidth: 100, align: 'center' },
            //     { Header: 'Actions', accessor: 'actions', minWidth: 100 },
            // ]
            value === "invited" ? InvitedColumns : value === 'interested' ? InterestedColumns : value === 'in_progress' ? InProgressColumns : InvestedColumns
        ,
        [value]
    );


    const data = React.useMemo(
        () => [
            {
                "name": "Olivia Rhye",
                "email": "",
                "amountAllocated": currency +" 120K",
                "amountReceived": currency +" 120K",
                "callForMoney": currency +" 120K",
                "receivedCallForMoney": currency +" 120K",
                "status": 'Invited',
                "drawdown": "10",
                "share": "5%",
                "units": "120"
            },
            {
                "name": "Phoenix Baker",
                "amountCommitted": currency +" 120K",
                "amountAllocated": currency +" 120K",
                "amountReceived": currency +" 120K",
                "callForMoney": currency +" 120K",
                "receivedCallForMoney": currency +" 120K",
                "status": 'Invited',
                "drawdown": "10",
                "share": "5%",
                "units": "120"
            },
            {
                "name": "Lana Steiner",
                "amountCommitted": currency +" 120K",
                "amountAllocated": currency +" 120K",
                "amountReceived": currency +" 120K",
                "callForMoney": currency +" 120K",
                "receivedCallForMoney": currency +" 120K",
                "status": 'Invited',
                "drawdown": "10",
                "share": "5%",
                "units": "120"
            },
            {
                "name": "Candice Wu",
                "amountCommitted": currency +" 120K",
                "amountAllocated": currency +" 120K",
                "amountReceived": currency +" 120K",
                "callForMoney": currency +" 120K",
                "receivedCallForMoney": currency +" 120K",
                "status": 'Invited',
                "drawdown": "10",
                "share": "5%",
                "units": "120"
            },
            {
                "name": "Harper Willis",
                "amountCommitted":currency +  "95K",
                "amountAllocated":currency +  "95K",
                "amountReceived":currency +  "95K",
                "callForMoney":currency +  "95K",
                "receivedCallForMoney":currency +  "95K",
                "status": 'Invited',
                "drawdown": "12",
                "share": "6%",
                "units": "95"
            },
            {
                "name": "Evelyn James",
                "amountCommitted": currency +" 130K",
                "amountAllocated": currency +" 130K",
                "amountReceived": currency +" 130K",
                "callForMoney": currency +" 130K",
                "receivedCallForMoney": currency +" 130K",
                "status": 'Invited',
                "drawdown": "11",
                "share": "4.5%",
                "units": "130"
            },
            {
                "name": "Isabella Green",
                "amountCommitted":currency +  "85K",
                "amountAllocated":currency +  "85K",
                "amountReceived":currency +  "85K",
                "callForMoney":currency +  "85K",
                "receivedCallForMoney":currency +  "85K",
                "status": 'Invited',
                "drawdown": "9",
                "share": "7%",
                "units": "85"
            },
            {
                "name": "Sophia Brown",
                "amountCommitted": currency +" 105K",
                "amountAllocated": currency +" 105K",
                "amountReceived": currency +" 105K",
                "callForMoney": currency +" 105K",
                "receivedCallForMoney": currency +" 105K",
                "status": 'Invited',
                "drawdown": "8",
                "share": "5.5%",
                "units": "105"
            },
            {
                "name": "Amelia Moore",
                "amountCommitted": currency +" 150K",
                "amountAllocated": currency +" 150K",
                "amountReceived": currency +" 150K",
                "callForMoney": currency +" 150K",
                "receivedCallForMoney": currency +" 150K",
                "status": 'Invited',
                "drawdown": "13",
                "share": "6.5%",
                "units": "150"
            },
            {
                "name": "Mia Martinez",
                "amountCommitted":currency +  "70K",
                "amountAllocated":currency +  "70K",
                "amountReceived":currency +  "70K",
                "callForMoney":currency +  "70K",
                "receivedCallForMoney":currency +  "70K",
                "status": 'Invited',
                "drawdown": "7",
                "share": "4%",
                "units": "70"
            },
            {
                "name": "Liam Nelson",
                "amountCommitted":currency +  "95K",
                "amountAllocated":currency +  "95K",
                "amountReceived":currency +  "95K",
                "callForMoney":currency +  "95K",
                "receivedCallForMoney":currency +  "95K",
                "status": 'Invited',
                "drawdown": "10",
                "share": "5%",
                "units": "95"
            },
            {
                "name": "Noah White",
                "amountCommitted": currency +" 110K",
                "amountAllocated": currency +" 110K",
                "amountReceived": currency +" 110K",
                "callForMoney": currency +" 110K",
                "receivedCallForMoney": currency +" 110K",
                "status": 'Invited',
                "drawdown": "9",
                "share": "4.5%",
                "units": "110"
            },
            {
                "name": "William Black",
                "amountCommitted": currency +" 135K",
                "amountAllocated": currency +" 135K",
                "amountReceived": currency +" 135K",
                "callForMoney": currency +" 135K",
                "receivedCallForMoney": currency +" 135K",
                "status": 'Invited',
                "drawdown": "12",
                "share": "5.5%",
                "units": "135"
            },
            {
                "name": "James Adams",
                "amountCommitted":currency +  "80K",
                "amountAllocated":currency +  "80K",
                "amountReceived":currency +  "80K",
                "callForMoney":currency +  "80K",
                "receivedCallForMoney":currency +  "80K",
                "status": 'Invited',
                "drawdown": "8",
                "share": "3.5%",
                "units": "80"
            },
            {
                "name": "Benjamin Scott",
                "amountCommitted": currency +" 120K",
                "amountAllocated": currency +" 120K",
                "amountReceived": currency +" 120K",
                "callForMoney": currency +" 120K",
                "receivedCallForMoney": currency +" 120K",
                "status": 'Invited',
                "drawdown": "10",
                "share": "5%",
                "units": "120"
            },
            {
                "name": "Lucas Hall",
                "amountCommitted": currency +" 140K",
                "amountAllocated": currency +" 140K",
                "amountReceived": currency +" 140K",
                "callForMoney": currency +" 140K",
                "receivedCallForMoney": currency +" 140K",
                "status": 'Invited',
                "drawdown": "11",
                "share": "6%",
                "units": "140"
            },
            {
                "name": "Henry Davis",
                "amountCommitted": currency +" 115K",
                "amountAllocated": currency +" 115K",
                "amountReceived": currency +" 115K",
                "callForMoney": currency +" 115K",
                "receivedCallForMoney": currency +" 115K",
                "status": 'Invited',
                "drawdown": "9",
                "share": "4%",
                "units": "115"
            },
            {
                "name": "Alexander Young",
                "amountCommitted": currency +" 130K",
                "amountAllocated": currency +" 130K",
                "amountReceived": currency +" 130K",
                "callForMoney": currency +" 130K",
                "receivedCallForMoney": currency +" 130K",
                "status": 'Invited',
                "drawdown": "10",
                "share": "5%",
                "units": "130"
            },
            {
                "name": "Jackson Wright",
                "amountCommitted": currency +" 125K",
                "amountAllocated": currency +" 125K",
                "amountReceived": currency +" 125K",
                "callForMoney": currency +" 125K",
                "receivedCallForMoney": currency +" 125K",
                "status": 'Invited',
                "drawdown": "10",
                "share": "5%",
                "units": "125"
            },
            {
                "name": "Sebastian Harris",
                "amountCommitted": currency +" 145K",
                "amountAllocated": currency +" 145K",
                "amountReceived": currency +" 145K",
                "callForMoney": currency +" 145K",
                "receivedCallForMoney": currency +" 145K",
                "status": 'Invited',
                "drawdown": "13",
                "share": "6.5%",
                "units": "145"
            },
            {
                "name": "Daniel Robinson",
                "amountCommitted": currency +" 100K",
                "amountAllocated": currency +" 100K",
                "amountReceived": currency +" 100K",
                "callForMoney": currency +" 100K",
                "receivedCallForMoney": currency +" 100K",
                "status": 'Invited',
                "drawdown": "9",
                "share": "4.5%",
                "units": "100"
            },
            {
                "name": "Matthew Clark",
                "amountCommitted":currency +  "90K",
                "amountAllocated":currency +  "90K",
                "amountReceived":currency +  "90K",
                "callForMoney":currency +  "90K",
                "receivedCallForMoney":currency +  "90K",
                "status": 'Invited',
                "drawdown": "8",
                "share": "4%",
                "units": "90"
            },
            {
                "name": "Aiden Lewis",
                "amountCommitted":currency +  "75K",
                "amountAllocated":currency +  "75K",
                "amountReceived":currency +  "75K",
                "callForMoney":currency +  "75K",
                "receivedCallForMoney":currency +  "75K",
                "status": 'Invited',
                "drawdown": "7",
                "share": "3.5%",
                "units": "75"
            }
        ]
        ,
        []
    );

    const transformedData = React.useMemo(() => {
        return data.map((item, index) => {
            switch (value) {
                case "invited":
                    return {
                        name: item.name || "",
                        image: 'https://storage.googleapis.com/backrr-saas-dev-environment/reports/1725975557548-logo.png',
                        email: generateEmail(item.name) || "Not Available", // Fallback if email is empty
                        is_lead_investor: item.is_lead_investor || "No",
                        date: 'Dec 23 2024' || "N/A",
                        actions: "View", // or any other action,
                        status: 'Invited'
                    };
                case "interested":
                    return {
                        name: item.name || "",
                        image: 'https://storage.googleapis.com/backrr-saas-dev-environment/reports/1725975557548-logo.png',
                        email: generateEmail(item.name) || "Not Available",
                        interestedAmount: item.amountCommitted || currency + " 120K",
                        date: 'Dec 23 2024' || "N/A",
                        totalAmount: item.amountAllocated || "N/A",
                        status: 'Interested',
                        actions: "View"
                    };
                case "in_progress":
                    return {
                        name: item.name || "",
                        image: 'https://storage.googleapis.com/backrr-saas-dev-environment/reports/1725975557548-logo.png',
                        email: generateEmail(item.name) || "Not Available",
                        kyc: ["View", "Upload"].at(index % 2),
                        interestedAmount: item.amountCommitted || "N/A",
                        allocationConfirmation: item.amountAllocated || "N/A",
                        statusWithDate: `${"Pending" || "N/A"} - ${"Dec 23 2024"}`,
                        documents: ["Upload", "View"].at(index % 2),
                        status: 'In Progress',
                        actions: "View"
                    };
                case "invested":
                    return {
                        name: item.name || "",
                        image: 'https://storage.googleapis.com/backrr-saas-dev-environment/reports/1725975557548-logo.png',
                        email: generateEmail(item.name) || "Not Available",
                        interestedAmount: item.amountCommitted || "N/A",
                        allocationConfirmation: item.units || "N/A",
                        statusWithDate: item.drawdown || "N/A",
                        status: 'Invested',
                        actions: "View",
                        pricePerShare: '20'
                    };
                default:
                    return item;
            }
        });
    }, [data, value]);


    const [popupOpen, setPopupOpen] = React.useState(false);

    const Actions = () => {
        return <Box display={"flex"} justifyContent={"center"} gap={1}>
            <B400 sx={{ display: 'flex', marginLeft: 'auto', background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem", "& > .MuiButton-icon.MuiButton-startIcon": { marginRight: isMediumScreen ? 0 : '0.5rem', marginLeft: isMediumScreen ? 0 : '-0.25rem' } }} startIcon={<Add sx={{ width: 20, height: 20 }} />} onClick={() => handleInvitePopupOpen()}>{isMediumScreen ? "" : "Invite Investor"}</B400>
            <B400 startIcon={<RoundInvestmentIcon style={{ width: 20, height: 15, fill: 'white' }} />} onClick={() => {
                setPopupOpen(true);
            }} sx={{ display: 'flex', background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem", "& > .MuiButton-icon.MuiButton-startIcon": { marginRight: isMediumScreen ? 0 : '0.5rem', marginLeft: isMediumScreen ? 0 : '-0.25rem' } }}>{isMediumScreen ? "" : "Add Investment"}</B400>
        </Box>
    }

    const RowActions = () => {
        return <>
            <Box display={"flex"} gap={1}>
                {/* <Button sx={{ minWidth: "1rem" }} onClick={() => handleBankDetailsPopupOpen()}>
            
                     <MailOutlineRounded sx={{ color: theme.palette.customColors.indigo700 }} />
                </Button> */}
                <Button sx={{ minWidth: "1rem" }}>
                    {/* <MoreOptionsIcon /> */}
                    <EditOutlined sx={{ color: theme.palette.customColors.indigo700 }} />
                </Button>
                <Button sx={{ minWidth: "1rem" }}>
                    {/* <MoreOptionsIcon /> */}
                    <DeleteOutlineRounded sx={{ color: theme.palette.customColors.indigo700 }} />
                </Button>
                {value !== "invited" && value !== "invested" && <Tooltip title={value == 'interested' ? "Move to In Progress" : "Move to Invested"}>
                    <Button sx={{ minWidth: "1rem" }} onClick={() => handleBankDetailsPopupOpen()}>
                        {/* <MoreOptionsIcon /> */}
                        <UpdateOutlined sx={{ color: theme.palette.customColors.indigo700 }} />
                    </Button>
                </Tooltip>
                }
            </Box>
        </>
    }


    const handleTabChange = (event, newValue) => {
        // console.log("value", newValue)
        setValue(newValue);
    };

    const handleSwitch = () => {
        switch (value) {
            case 'invited':
                // <></>
                return <AllocateAmountPopup currency={currency} handleClose={() => handleBankDetailsPopupClose()} handleSubmit={handleSubmitBankDetails} />
            case 'interested':
                return <AllocateAmountPopup currency={currency} handleClose={() => handleBankDetailsPopupClose()} handleSubmit={handleSubmitBankDetails} />
            case 'in_progress':
                return <AllocateUnits currency={currency} handleClose={() => handleBankDetailsPopupClose()} handleSubmit={handleSubmitBankDetails} />
            default:
                return <></>
        }
    }

    return (
        <>
            <ModalComponent open={openInvitePopup} onClose={handleInvitePopupClose} style={{ maxWidth: 640, width: "100%" }}>
                <InviteInvestorPopup handleClose={handleInvitePopupClose} handleSubmit={handleInviteInvestor} />
            </ModalComponent>
            {/* <Dialog open={openBankDetailsPopup} onClose={() => { }}>
                <BankDetailsPopup handleClose={handleBankDetailsPopupClose} handleSubmit={handleSubmitBankDetails} />
            </Dialog> */}
            <ModalComponent
                style={{ maxWidth: 640, width: "100%" }}
                //  sx={{ "& > .MuiDialog-container > .MuiPaper-root": { marginInline: 0 } }}
                open={openBankDetailsPopup} onClose={() => { handleBankDetailsPopupClose() }}>
                <Paper sx={{ maxHeight: '100%' }}>{handleSwitch()}</Paper>
            </ModalComponent>
            <Dialog sx={{ "& > .MuiDialog-container > .MuiPaper-root": { marginInline: 0 } }} open={popupOpen} onClose={() => { setPopupOpen(false) }}>
                <AddInvestmentPopup currency={currency} handleClose={() => setPopupOpen(false)} handleSubmit={handleSubmitBankDetails} />
            </Dialog>
            {/* <ModalComponent open={openBankDetailsPopup} onClose={handleBankDetailsPopupClose} style={{ maxWidth: 640, width: "100%" }}>
                
            </ModalComponent> */}
            <TabContext value={value}>
                <Grid container xs={12} pt={1} pb={0} spacing={2} display={"flex"} justifyContent={"space-between"} marginLeft={0} >
                    {/* <Grid item xs={10} lg={10}> */}
                    <Grid item xs={12} paddingInline={{ xs: "0.5rem !important", sm: '1rem !important', md: '1rem !important', lg: '2rem !important' }} marginRight={"0.2rem"} >
                        <CustomTabs
                            variant="submenu"
                            titleStyle={{ fontSize: '1rem'}}
                            tabStyle={{ padding: '0.5rem 1rem 0rem' }}
                            data={
                                [
                                    // { value: "", label: 'All Investors', count: 100, read: false },
                                    { value: "invited", label: 'Invited', count: 20, read: true, icon: PersonAddAlt },
                                    { value: "interested", label: 'Interested', count: 50, read: true, icon: ThumbUp },
                                    { value: "in_progress", label: 'In Progress', count: 10, read: false, icon: PublishedWithChanges },
                                    { value: "invested", label: 'Invested', count: 10, read: false, icon: CallReceived },
                                ]
                            } value={value} handleChange={handleTabChange}
                            isSmallScreen={isSmallScreen} className={classes.scrollTabs} key={"Tabs Tabs"} indicatorFullSize={false} />
                    </Grid>
                    {/* <Grid item xs={2} lg={2}> */}
                    {/* {data?.length > 0 && <Grid item xs={4}>
                        <Actions />
                    </Grid>} */}
                </Grid>
            </TabContext>
            <Grid container spacing={2} height={"100%"} paddingInline={"2%"} mt={0}>
                <Grid item md={2.4} xs={12}>
                    {value != "invited" ?

                        <Grid sx={{ gridArea: 'first', maxWidth: { xs: '95%', sm: "100%" }, marginInline: { xs: 'auto', sm: 0 } }} item>
                            <Box display={"flex"} justifyContent={"center"} gap={1} pb={1}>
                                <B400 sx={{ display: 'flex', width: "100%", background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem", "& > .MuiButton-icon.MuiButton-startIcon": { marginRight: isMediumScreen ? 0 : '0.5rem', marginLeft: isMediumScreen ? 0 : '-0.25rem' } }} startIcon={ <RoundInvestmentIcon style={{ width: 20, height: 15, fill: 'white' }} />} onClick={() => { setPopupOpen(true) }}>{isMediumScreen ? "Add" : "Add Investment"}</B400>
                            </Box>
                            <Card sx={{ boxShadow: 'none', borderRadius: "8px", border: `1px solid ${theme.palette.customColors.gray300}`, overflow: 'hidden' }}>

                                <CardHeader title={"Overview"} />

                                <Grid
                                    container
                                    xs={12}
                                    sx={{
                                        overflow: "hidden",
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        display={"flex"}

                                        justifyContent={"space-between"}
                                        sx={{
                                            padding: "12px 16px",
                                            backgroundColor:
                                                theme?.palette?.customColors?.b50,

                                        }}
                                    >
                                        <Typography fontSize="0.8rem">Total Round Size</Typography>
                                        <Typography fontSize="0.8rem">
                                            {currency}500K
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        sx={{
                                            padding: "12px 16px",
                                        }}
                                    >
                                        <Typography fontSize="0.8rem">Interested Amount</Typography>
                                        <Typography fontSize="0.8rem">
                                            {currency}250K
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        sx={{
                                            padding: "12px 16px",
                                            backgroundColor:
                                                theme?.palette?.customColors?.b50,
                                        }}
                                    >
                                        <Typography fontSize="0.8rem">Subscribed</Typography>
                                        <Typography fontSize="0.8rem">
                                            50%
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        sx={{
                                            padding: "12px 16px",

                                        }}
                                    >
                                        <Typography fontSize="0.8rem">Amount Received</Typography>
                                        <Typography fontSize="0.8rem">
                                            {currency}132K
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid> :
                        <>
                            <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} gap={1} pb={1}>
                                <B400 sx={{ display: 'flex', width: "100%", background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem", "& > .MuiButton-icon.MuiButton-startIcon": { marginRight: isMediumScreen ? 0 : '0.5rem', marginLeft: isMediumScreen ? 0 : '-0.25rem' } }} startIcon={<Add sx={{ width: 20, height: 20 }} />} onClick={() => {  handleInvitePopupOpen() }}>{ isMediumScreen ? "Invite" : "Invite Investor"}</B400>
                                <B400 sx={{ display: 'flex', width: "100%", background: theme.palette.customColors.indigo700, padding: "0.625rem 1rem", "& > .MuiButton-icon.MuiButton-startIcon": { marginRight: isMediumScreen ? 0 : '0.5rem', marginLeft: isMediumScreen ? 0 : '-0.25rem' } }} startIcon={<Upload sx={{ width: 20, height: 20 }} />} onClick={() => {  handleInvitePopupOpen() }}>{ isMediumScreen ? "Upload & Import" : "Upload & Import"}</B400>
                            </Box>
                        </>
                    }
                </Grid>
                {
                    <Grid item xs={12} md={9.6}>
                        {/* <Box width={"96%"} paddingInline={"2%"}> */}
                        <CustomTable columns={columns}
                            // data={[]}
                            data={transformedData}
                            // title={"Investors"}
                            // chipText={`${data?.length}${isSmallScreen ? "" : " Investors"}`}
                            actions={Actions} RowActions={RowActions}
                            sx={{ borderRadius: '8px 8px' }} />
                        {/* </Box> */}
                    </Grid>}
            </Grid>
        </>
    );
};

export default RoundInvestors;
