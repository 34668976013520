import { useTheme } from "@emotion/react";
import { Box, Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "assets/close_icon.svg";
import { FormikContext, FormikProvider, useFormik } from "formik";
import CustomInputField from "ui-components/formik/CustomInputField";
import { B300 } from "ui-components/CustomButton";
import * as Yup from "yup";
import { amountInWords, currencyInputProps, interestOptions } from "utils";

const ShowInterestPopup = ({ currentRow, handleClose, handleSubmit, isShowingInterest = false }) => {
    // console.log(currentRow, "currentRow")
    const theme = useTheme();
    const [selectedInterest, setSelectedInterest] = useState(null)

    const validations = Yup.object(
        {
            interested_amount: Yup.number("Please enter valid amount").required("Please enter valid amount").moreThan(0, "Please enter valid amount")
        }
    )

    const formik = useFormik({
        initialValues: {
            interested_amount: 0
        },
        // validateOnMount: true,
        validationSchema: validations,
        onSubmit: (values, { setSubmitting }) => {
            handleSubmit({ row: currentRow, values });
        },
    });

    const currencyIcon = (currentRow && currentRow?.currency_info && currentRow?.currency_info?.code ? currentRow?.currency_info?.code : "₹")
    // console.log(currentRow?.currency_info[0]?.code,"urrentRow?.currency_info[0]?.code")

    return (
        <Paper
            sx={{
                background: theme.palette.customColors.whiteBG,
                borderRadius: "20px",
            }}
        >
            <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
                <CloseIcon width={40} height={40} onClick={handleClose} />
            </IconButton>
            <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                    <Grid display={"grid"} container padding={"1.5rem"} gap={"1.5rem"}>
                        <Grid item xs={12}>
                            <Typography
                                sx={{
                                    fontSize: "1.5rem",
                                    fontWeight: 700,
                                    lineHeight: "28px",
                                    color: theme.palette.customColors.g300,
                                }}
                            >
                                Submit Investment Interest
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: "1rem",
                                    fontWeight: 500,
                                    lineHeight: "24px",
                                    color: theme.palette.customColors.g200,
                                }}
                            >
                                Provide your investment interest amount
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Tooltip title={amountInWords(formik.values.interested_amount, currencyIcon)}>
                                <CustomInputField
                                    name="interested_amount"
                                    label={"Enter Investment Amount"}
                                    value={formik.values.interested_amount}
                                    onChange={(e) => {
                                        formik.setFieldValue("interested_amount", e.target.value);
                                        setSelectedInterest(null)
                                    }}
                                    InputProps={{
                                        // startAdornment: Adornment
                                        ...currencyInputProps,
                                        startAdornment: (
                                            <Box pl={2} mr={-1}>{(currencyIcon)}</Box>
                                        )
                                    }}
                                    sx={{ marginBottom: formik.errors.interested_amount ? 0 : "inherit" }}
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item sx={12} className="grid">
                            {
                                interestOptions((currencyIcon))?.map(item => {
                                    return (
                                        <button type="button" className={`item ${item.label === selectedInterest && "selected-item"}`} onClick={() => { setSelectedInterest(item.label); formik.setFieldValue("interested_amount", item.value); }}>{item.label}</button>
                                    )
                                })
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <B300
                                type="submit"
                                fullWidth
                                disabled={isShowingInterest}
                                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem" }}
                            >
                                Submit
                            </B300>
                        </Grid>
                    </Grid>
                </form>
            </FormikProvider>
        </Paper>
    );
};

export default ShowInterestPopup;
