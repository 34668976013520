import { useTheme } from "@emotion/react";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/close_icon.svg";
import { B300, WHITEBG } from "../../../ui-components/CustomButton";
import { FormikContext, FormikProvider, useFormik } from "formik";
import CustomInputField from "../../../ui-components/CustomInputField";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomMenu from "../../../ui-components/CustomMenu";
import { ReactComponent as UploadDocumentsIcon } from "../../../assets/rounds_icons/upload_documents_icon.svg";
import { styled } from "@mui/styles";
import CustomLabel from "../../../ui-components/CustomLabel";
import { Download, Upload } from "@mui/icons-material";
import sampledata from "../../../assets/sampledata.xlsx"



const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ImportInvestorPopup = ({ handleClose, handleSubmit }) => {
  const theme = useTheme();
  const { id: round_id } = useParams();
//   const startup_id = useSelector((state) => state.startupDetails.data)?._id;
  const [fileName, setFileName] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
    }
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      message: "",
    },
    validateOnMount: true,
    onSubmit: (values, { setSubmitting }) => {
      // console.log(
      //   {
      //     invitations: [
      //       {
      //         startup_id: startup_id,
      //         startup_round_id: round_id,
      //         message: values?.message,
      //       },
      //     ],
      //     email: values?.email,
      //   },
      //   "values"
      // );
      // handleSubmit(values);
    },
  });
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = sampledata;
    link.download = "SampleTemplate.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Paper
      sx={{
        background: theme.palette.customColors.whiteBG,
        borderRadius: "8px",
      }}
    >
      <IconButton sx={{ position: "absolute", top: 20, right: 20, padding: 0 }}>
        <CloseIcon width={40} height={40} onClick={handleClose} />
      </IconButton>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <Grid display={"grid"} container padding={"1.5rem"} gap={"1.5rem"}>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  fontWeight: 700,
                  lineHeight: "28px",
                  color: theme.palette.customColors.g300,
                }}
              >
                Import Investors
              </Typography>
            
            </Grid>
            <Grid item xs={12}>
            <CustomLabel>UPLOAD FILE</CustomLabel>
            <CustomMenu>
                <WHITEBG
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  fullWidth
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    border: `1px solid ${theme.palette.customColors.g75}`,
                    borderRadius: "8px",
                    height: "52px",
                    padding: "10px, 14px, 10px, 14px",
                    color: '#7A7D83',
                    textTransform: 'none'
                  }}
                >
                  {fileName ? fileName : "Upload"}
                <UploadDocumentsIcon
                  style={{
                    width: 15,
                    height: 15,
                    stroke: "#344054",
                    padding: "8px",
                    borderRadius: "8px",
                    border: `1px solid ${theme.palette.customColors.gray300}`,
                  }}
                />
                <VisuallyHiddenInput
                  accept=".pdf,.png,.jpeg,.jpg,.mp4,.mov,.xlsx,.csv,.xls,.doc,.docx"
                  type="file"
                  name="upload_file"
                  id="upload-file"
                  onChange={handleFileChange}
                />
              </WHITEBG>
            </CustomMenu>
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"end"} alignItems={"center"}>
                  <Typography variant="body2" color="blue" alignItems={"center"} display={"flex"} onClick={handleDownload} sx={{cursor:'pointer'}}><Download style={{width:16 , height: 16}}/> Download Sample xlsx Template</Typography>
            </Grid>
            <Grid item xs={12}>
              <B300
                type="submit"
                fullWidth
                sx={{ padding: "0.75rem 1.25rem", fontSize: "1rem" }}
              >
                Import
              </B300>
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Paper>
  );
};

export default ImportInvestorPopup;
